import React from 'react';
import { API_SERVER, PROGRAMS } from '../../../constants';
import axios from 'axios';
import Flatpickr from 'react-flatpickr';
import "./notification-modal.css";
import flatpickr from 'flatpickr';

const initialState = {
  notification : {
    message : null,
    date : new Date(),
    time : new Date(),
  }
}


class NotificationForm extends React.Component {

  currentDate = new Date(new Date().setMinutes(0));

  constructor (props) {
    super(props);

    this.state = {
      notification : {
        message : '',
        programs: null,
        date: null,
        time : null,
      },
      message : '',
      programs: [],
      scheduledDate : {
        year : this.currentDate.getFullYear(),
        month : this.currentDate.getMonth(),
        day : this.currentDate.getDate(),
        hour : this.currentDate.getHours(),
        minute : 0,
      },
      date : this.currentDate,
      time : '',
      error : {
        messageError : null,
        scheduledDateError : null,
      }
    };

    this.datePicker = React.createRef();
    this.timePicker = React.createRef();

    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleTimePicker = this.handleTimePicker.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleDatePicker = (selectedDates, dateStr, instance) => {
    if (selectedDates?.length < 1) return;
    const date = selectedDates[0]
    this.setState({
      ...this.state,
      scheduledDate : {
        year : date.getFullYear(),
        month : date.getMonth(),
        day : date.getDate(),
        hour : this.state.scheduledDate.hour,
        minute : 0,
      },
      error : {
        messageError : this.state.error.messageError,
        scheduledDateError : null,
      }
    })
  }

  handleSelectChange = (event, programName) => {
    const programSelected = PROGRAMS.find(p => p.name === programName);
    if (event.target.checked) {
      if (!this.state.programs.includes(programSelected.value)) {
        this.setState({ ...this.state, programs: this.state.programs.concat(programSelected.value) });
      }
    } else {
      if (this.state.programs.includes(programSelected.value)) {
        this.setState({  ...this.state, programs: this.state.programs.filter(p => p !== programSelected.value) });
      }
    }
  };

  handleTimePicker = (selectedDates, dateStr, instance) => {
    if (selectedDates?.length < 1) return;
    const date = selectedDates[0]
    this.setState({
      ...this.state,
      scheduledDate : {
        year : this.state.scheduledDate.year,
        month : this.state.scheduledDate.month,
        day : this.state.scheduledDate.day,
        hour : date.getHours(),
        minute : 0,
      },
      error : {
        messageError : this.state.error.messageError,
        scheduledDateError : null,
      }
    })
  };

  componentDidMount = () => {
    const { notification, type } = this.props;

    flatpickr(this.datePicker.current, {
      dateFormat: 'd/m/Y',
      onChange: this.handleDatePicker,
      defaultDate: notification ? new Date(notification.scheduled_date_time) : this.currentDate,
    });

    flatpickr(this.timePicker.current, {
      onChange: this.handleTimePicker,
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      minuteIncrement: 60,
      defaultDate: notification ? new Date(notification.scheduled_date_time) : this.currentDate,
      static: true
    });
    document.querySelectorAll('[class="numInput flatpickr-minute"]').forEach(it => it.disabled = true)
    console.log("inside component Did mount ", type)

    if(notification) {
      const date = new Date(notification.scheduled_date_time);
      this.setState({
        ...this.state,
        message: notification.message,
        programs: notification.programs,
        scheduledDate : {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
          hour: date.getHours(),
          minute: date.getMinutes(),
        },
      });
      this.forceUpdate();
      window.$('#modal-patient-notification-create').modal('show');
    } else if (type === 'new') {
      this.setState({
        ...this.state,
        message: '',
        program: []
      });
      this.forceUpdate();
      window.$('#modal-patient-notification-create').modal('show');
    }
  }

  checkNotificationData = (type, data) => {
    const {notification} = this.props;
    if(this.props.type === 'edit') {
      if(data.notification.message === notification.message) {
        this.setState({
          ...this.state,
          error : {
            messageError : 'please update the message',
            scheduledDateError : this.state.error.scheduledDateError,
          }
        })
        return false;
      }
      return true;
    } else {
      if(!data.notification.message) {
        console.log("empty message");
        return false;
      } else if(type === "send_later" && (data.notification.scheduledDate === new Date() || data.notification.scheduledDate < new Date())) {
        console.log("scheduled date", data.notification.scheduledDate);
        console.log("scheduled date error");
        this.setState({
          ...this.state,
          error : {
            messageError : this.state.error.messageError,
            scheduledDateError : 'please select valid time',
          }
        })
        return false;
      }
      return true;
    }
  }

  resetState = () => {
    this.setState({
      message : '',
    })
  }

  onSend = (type) => {
    const {sendNotification, notification} = this.props;
    const scheduledDate = new Date(this.state.scheduledDate.year, this.state.scheduledDate.month, this.state.scheduledDate.day, this.state.scheduledDate.hour, 0);

    let data = {
      type : this.props.type,
      notification : {
        message: this.state.message,
        programs: this.state.programs,
        scheduledDate: type === 'send_later' ? scheduledDate : new Date(),
      },
      id : notification ? notification.id : null,
    };

    if(this.checkNotificationData(type, data)) {
      this.resetState();
      sendNotification(data);
    } else {
      console.log("something went wrong");
    }
  }

  render () {
    const { isInvidiual } = this.props;
    return (
      <div className="modal-body">
        <div className="row justify-content-between mb-4 mt-4 mr-2 ml-2">
          <div className="col-lg-6">
            <h6>Message</h6>
            <textarea
              className={`w-100 ${this.state.message ? 'is-valid' : 'is-invalid'}`}
              value={this.state.message}
              onChange = {(e) => {
                this.setState({
                  ...this.state,
                  message : e.target.value,
                  error : {
                    messageError : null,
                    scheduledDateError : this.state.error.scheduledDateError,
                  }
                });
                }
              }
              data-toggle="autosize"
              placeholder="Enter message ...">
            </textarea>
            <div className="invalid-feedback">
              Please input message.
            </div>
            {
              this.state.error.messageError && <div className="error-text">{this.state.error.messageError}</div>
            }
            <br/>
            { !isInvidiual &&
              <div>
                <h6>(To send to All Programs leave all toggles off)</h6>
                <div className="custom-control custom-switch">
                  <input
                    onChange={(e) => this.handleSelectChange(e, 'Alcohol Use')}
                    type="checkbox"
                    checked={ this.state.programs && this.state.programs.length > 0 && this.state.programs.includes(0) }
                    className="custom-control-input"
                    id="clinic-1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="clinic-1"
                    data-active="Active"
                    data-inactive="Inactive"
                  >Alcohol Use</label>
                </div>
                <div className="custom-control custom-switch">
                  <input
                    onChange={(e) => this.handleSelectChange(e, 'Substance Use')}
                    type="checkbox"
                    checked={ this.state.programs && this.state.programs.length > 0 && this.state.programs.includes(1) }
                    className="custom-control-input"
                    id="clinic-2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="clinic-2"
                    data-active="Active"
                    data-inactive="Inactive"
                  >Substance Use</label>
                </div>
                <div className="custom-control custom-switch">
                  <input
                    onChange={(e) => this.handleSelectChange(e, 'Gambling')}
                    checked={ this.state.programs && this.state.programs.length > 0 && this.state.programs.includes(2) }
                    type="checkbox"
                    className="custom-control-input"
                    id="clinic-3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="clinic-3"
                    data-active="Active"
                    data-inactive="Inactive"
                  >Gambling</label>
                </div>
              </div>
            }
          </div>
          <div className="col-lg-6 mt-4">
            <div>
              <button type="button" onClick={()=> this.onSend("send_now")} className="col-5 btn btn-sm btn-outline-secondary text-dark mb-4 border-dark">Send Now</button>
            </div>
            <div>
              <button type="button" onClick={()=> this.onSend("send_later")} className="col-5 btn btn-sm btn-outline-secondary text-dark mb-4 border-dark">Schedule later</button>
            </div>
            <div className="row align-items-center justify-content-between">
              <div className="col-6">
                <h6>Scheduled Date</h6>
                <input
                  type="text"
                  name="date"
                  ref={this.datePicker}
                  className="form-control"
                  placeholder="Appointment Date" />
              </div>
              <div className="col-6">
                <h6>Scheduled Time</h6>
                <input
                  type="text"
                  name="start"
                  ref={this.timePicker}
                  className="form-control"
                  placeholder="hh:mm" />
              </div>
            </div>
            {
              this.state.error.scheduledDateError && <div className="error-text">{this.state.error.scheduledDateError}</div>
            }
          </div>
        </div>
      </div>
    )
  }

}

export default NotificationForm;
