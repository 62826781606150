import React from 'react';

const VideoRow = (props) => {
  const { upload_date, upload_time, title, uploaded_by, presenter_name, handleDelete, handleEdit} = props;

  return (
    <>
      <td>{ upload_date }</td>
      <td>{ upload_time }</td>
      <td>{ title }</td>
      <td>{ presenter_name }</td>
      <td>{ uploaded_by }</td>
      <td className="text-right">
        <div className="actions ml-3">
          <a href="#modal-support-service-create" onClick={handleEdit} className="action-item mr-2" data-toggle="modal">
            <i className="fas fa-pencil-alt"></i>
          </a>
          <a href="#confirm-modal" onClick={handleDelete} className="action-item text-danger mr-2" data-toggle="modal" title="Move to trash">
            <i className="fas fa-trash"></i>
          </a>
        </div>
      </td>
    </>
  )
}

export default VideoRow;
