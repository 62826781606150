import React from "react";
import select2 from "select2";
import axios from "axios";
import {
  API_SERVER,
  VALID_EMAIL,
  ACTIVE_STATUSES,
  DISCIPLINE_TYPES,
  INVALID_DATE
} from "../../constants";

const initialState = {
  isLoading: false,
  formErrors: "",
  id: "",
  clinic_id: "",
  clinic_name: "",
  name: "",
  surname: "",
  title: "",
  date_of_birth: "",
  dd: "",
  mm: "",
  yyyy: "",
  discipline: "",
  active: true,
  email: "",
  password: "",
  has_access_to_medications: true,
  is_admin: false
};

class AdminsClinicianForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.selectClinicPicker = React.createRef();

    this.state = initialState;
  }

  static getDerivedStateFromProps(props, state) {
    const clinician = props.clinician || { id: "", clinic: {} };

    if (clinician.id !== state.id) {
      const clinicianObject = {
        id: clinician.id,
        name: clinician.name,
        surname: clinician.surname,
        title: clinician.title,
        clinic_id: clinician.clinic_id,
        clinic_name: clinician.clinic.name || "",
        date_of_birth: clinician.date_of_birth || "",
        dd: "", mm: "", yyyy: "",
        discipline: clinician.discipline || "",
        email: clinician.email,
        active: clinician.active,
        has_access_to_medications: clinician.has_access_to_medications,
        is_admin: clinician.is_admin
      };

      const date_of_birth = clinician.date_of_birth;

      if (!INVALID_DATE(date_of_birth)) {
        const dates = date_of_birth.split('-');

        clinicianObject.dd = dates[2];
        clinicianObject.mm = dates[1];
        clinicianObject.yyyy = dates[0];
      }

      return clinicianObject;
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSelectChange = (event) => {
    console.log('Select Change');
  };

  handleFileChange = (event) => this.setState({ logo: event.target.files[0] });

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true, formErrors: "" });

    const {
      id,
      name,
      surname,
      title,
      discipline,
      email,
      active,
      clinic_id,
      has_access_to_medications,
      is_admin,
      avatar,
      password,
      dd, mm, yyyy
    } = this.state;

    const url =
      id != ""
        ? API_SERVER + "/admins/clinicians/" + id + ".json"
        : API_SERVER + "/admins/clinicians.json";
    const method = id ? "PUT" : "POST";

    const formData = new FormData();

    let cancel;
    let headers = JSON.parse(localStorage.getItem("admin_auth"));

    formData.append("clinician[name]", name);
    formData.append("clinician[surname]", surname);
    formData.append("clinician[title]", title);

    const date_of_birth = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date_of_birth)) {
      formData.append('clinician[date_of_birth]', date_of_birth);
    } else {
      formData.append('clinician[date_of_birth]', '');
    }

    formData.append("clinician[email]", email);
    formData.append("clinician[discipline]", discipline);
    formData.append("clinician[active]", active);
    formData.append("clinician[clinic_id]", clinic_id);
    formData.append("clinician[has_access_to_medications]", has_access_to_medications);
    formData.append("clinician[is_admin]", is_admin);

    headers["content-type"] = "multipart/form-data";

    if (avatar) {
      formData.append("clinician[avatar]", avatar);
    }

    if (password != "") {
      formData.append("clinician[password]", password);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((e) => window.location.reload())
      .catch((e) => window.location.reload())
  };

  handleDelete = (clinicianId) => {
    axios({
      method: "DELETE",
      url: API_SERVER + "/admins/clinicians/" + clinicianId + ".json",
      headers: JSON.parse(localStorage.getItem("admin_auth")),
    })
      .then((e) => {
        window.location.reload();
      })
      .catch((e) => {
        window.location.reload();
      });
  };

  componentDidMount = () => {
    const modal = window.$('#modal-clinician-create');

    modal.on('shown.bs.modal', () => {
      window.$(this.selectClinicPicker.current)
        .select2({
          ajax: {
            url: API_SERVER + '/admins/clinics.json',
            type: 'GET',
            headers: JSON.parse(localStorage.getItem('admin_auth')),
            data: (params) => {
              return {
                'q[name_cont]': params.term,
                p: params.page
              }
            },
            processResults: function (data, params) {
              params.page = params.page || 1;

              return {
                results: data.map((row) => { return { id: row.id, text: row.name } }),
                pagination: {more: params.page === 12}
              };
            }
          },
          allow_clear: true,
          placeholder: "Select clinic"
        })
        .on("select2:select", (e) => {
          this.setState({ clinic_id: e.params.data.id });
        });
    })
  };

  render() {
    const isLoading = this.state.isLoading,
      id = this.state.id,
      name = this.state.name,
      active = this.state.active.toString(),
      clinic_id = this.state.clinic_id,
      clinic_name = this.state.clinic_name,
      dd = this.state.dd,
      mm = this.state.mm,
      yyyy = this.state.yyyy,
      surname = this.state.surname,
      title = this.state.title,
      discipline = this.state.discipline,
      has_access_to_medications = this.state.has_access_to_medications,
      is_admin = this.state.is_admin,
      email = this.state.email,
      nameInvalid = name.length === 0,
      emailInvalid = !VALID_EMAIL.test(email),
      nameClassName = nameInvalid ? "form-control is-invalid" : "form-control",
      emailClassName = emailInvalid
        ? "form-control is-invalid"
        : "form-control",
      formValid = !nameInvalid && !emailInvalid,
      iconClassName = isLoading
        ? "fa fa-spinner fa-spin"
        : "fas fa-long-arrow-alt-right";
    
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="form-group">
            <div className="form-group">
              <label className="form-control-label">Clinic</label>
              <select
                name="clinic_id"
                className="form-control"
                defaultValue={clinic_id}
                ref={this.selectClinicPicker}
              >
                <option>{clinic_name}</option>
              </select>
            </div>
          </div>
          <hr />

          <div className="form-group">
            <label className="form-control-label">Clinician Status</label>
            <select
              name="active"
              value={active}
              onChange={this.handleChange}
              className="form-control"
            >
              {ACTIVE_STATUSES.map((el) => {
                return <option key={el.value} value={el.value}>{el.name}</option>;
              })}
            </select>
          </div>
          <hr />
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Discipline</label>
                <select
                  name="discipline"
                  value={discipline}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option>Select discipline</option>
                  {DISCIPLINE_TYPES.map((el, i) => {
                    return <option key={i} value={el.value}>{el.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Dr."
                  name="title"
                  onChange={this.handleChange}
                  value={title}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                  className={nameClassName}
                />
                <div className="invalid-feedback">
                  Please provide a valid clinician name.
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Surname</label>
                <input
                  type="text"
                  className="form-control"
                  name="surname"
                  onChange={this.handleChange}
                  value={surname}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Email</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    className={emailClassName}
                    placeholder="name@domain.com"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Password</label>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={this.handleChange}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      data-toggle="tooltip"
                      title="Click here to renew password"
                    >
                      <i className="fas fa-sync"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-control-label">Birthday</label>
            <div className="form-row">
              <div className="col">
                <input
                  className="form-control"
                  placeholder="DD"
                  type="number"
                  onChange={this.handleChange}
                  name="dd"
                  value={dd}
                  min="1"
                  max="31" />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  placeholder="MM"
                  type="number"
                  onChange={this.handleChange}
                  name="mm"
                  value={mm}
                  min="1"
                  max="12" />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  placeholder="YYYY"
                  type="number"
                  onChange={this.handleChange}
                  name="yyyy"
                  value={yyyy}
                  min="1920"
                  max={new Date().getFullYear()} />
              </div>
            </div>

          </div>
          <hr />

          <div className="form-group">
            <label className="form-control-label">
              Has Access to Medications in Worker Portal
            </label>
            <div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="hasAccessed_1"
                  name="has_access_to_medications"
                  value="true"
                  checked={has_access_to_medications.toString() === 'true' }
                  onChange={this.handleChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="hasAccessed_1">Yes</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="hasAccessed_2"
                  name="has_access_to_medications"
                  value="false"
                  checked={has_access_to_medications.toString() === 'false'}
                  onChange={this.handleChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="hasAccessed_2">No</label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-control-label">
              Admin Privileges
            </label>
            <div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="isAdmin_1"
                  name="is_admin"
                  value="true"
                  checked={is_admin.toString() === 'true' }
                  onChange={this.handleChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="isAdmin_1">Yes</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="isAdmin_2"
                  name="is_admin"
                  value="false"
                  checked={is_admin.toString() === 'false'}
                  onChange={this.handleChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="isAdmin_2">No</label>
              </div>
            </div>
          </div>
          <p>
            The worker you are adding will be sent an automated email from HARP+ with their temporary password &amp; login information.
          </p>
        </div>
        <div className="modal-footer">
          {id && (
            <button
              type="button"
              onClick={() => this.handleDelete(id)}
              className="btn btn-sm btn-link text-danger px-2 mr-auto"
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="btn btn-sm btn-secondary rounded-pill"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-primary btn-overprimary rounded-pill"
            disabled={!formValid}
          >
            <span className="btn-inner--text">Save&nbsp;</span>
            <span className="btn-inner--icon">
              <i className={iconClassName} />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default AdminsClinicianForm;
