import React from 'react';
import SupportServiceForm from './form';

const SupportServiceModal = (props) => {
  return (
    <div className="modal fade" id="modal-support-service-create" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <SupportServiceForm supportService={props.supportService} />
        </div>
      </div>
    </div>
  );
}

export default SupportServiceModal;
