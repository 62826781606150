import React from 'react';
import './overview.scss';

class OverviewSafetyPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user_safety_plan: props.user_safety_plan
    }
  }

  render () {
    const {
      user_safety_plan
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Safety Plan</h6>
            </div>
              <span className="btn btn-sm btn-icon-only rounded-circle circle-around">
                <i className="fa-regular fa-life-ring purple fa-lg"></i>
              </span>
          </div>
          <p className="mb-0">{ user_safety_plan.commenced_date ? `Started on - ${user_safety_plan.commenced_date}` : ''}</p>
          <p>{ user_safety_plan.last_edit_date ? `Last edited on - ${user_safety_plan.last_edit_date}` : ''}</p>
        </div>
      </div>
    )
  }
}

export default OverviewSafetyPlan;
