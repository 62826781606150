import React from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { API_SERVER, FORMAT_DATE } from '../../../constants';

class OverviewMoodChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: props.ydata || [],
      xData: props.xdata || [],
    } 

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (tab) => this.fetchData(tab);

  fetchData = (tab) => {
    let params = {'q[patient_id_eq]': this.props.patient.id};

    if (tab == 'month') {
      const today = new Date(),
        start = new Date().setDate(today.getDate() - 30),
        startDate = new Date(start),
        stop = new Date().setDate(today.getDate() + 1),
        stopDate = new Date(stop);
      
      startDate.setHours(0,0,0,0);
      stopDate.setHours(0,0,0,0);

      params['q[date_gt]'] = startDate;
      params['q[date_lteq]'] = stopDate;
    }

    if (tab == 'year') {
      const today = new Date(),
        start = new Date().setDate(today.getDate() - 365),
        startDate = new Date(start),
        stop = new Date().setDate(today.getDate() + 1),
        stopDate = new Date(stop);
      
      startDate.setHours(0,0,0,0);
      stopDate.setHours(0,0,0,0);

      params['q[date_gt]'] = startDate;
      params['q[date_lteq]'] = stopDate;
      params['tab'] = 'year';
    }

    this.setState({isLoading: true});

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/moods.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => {
      const data = result.data;

      this.setState({
        isLoading: false,
        data: data.map((el) => el.score),
        xData: data.map((el) => {
          const dateRow = FORMAT_DATE(el.date_chart).split('/');
          const groupedBy = [dateRow[0], dateRow[1]].join('/');

          return el.date_chart;;
        })
      })
    })
    .catch((result) => console.log(result))
  }

  render () {
    const options = {
      chart: {
        toolbar: { show: false }
      },
      yaxis: {
        min: 0,
        max: 10,
        tickAmount: 5
      },
      xaxis: {
        labels: {
          rotate: 0,
          style: {
            fontSize: '10px'
          }
        },
        categories: this.state.xData
      }
    }

    const series = [{name: 'Score', data: this.state.data}],
      renderChart = this.state.isLoading ? <span className="btn-inner--icon"><i className="fa fa-spinner fa-spin" /></span> : <Chart options={options} height={280} series={series} type="line" />;

    return (
      <div className="card card-fluid">
        <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs align-items-center mt-n4">
              <li className="nav-item mr-auto">
                <h6 className="mb-0">Mood Tracking</h6>
              </li>
              <li className="nav-item mr-2">
                <a href="#" onClick={() => this.handleClick('month')} className="nav-link text-center active" data-toggle="tab">
                  <small className="h6">Recent</small>
                </a>
              </li>
              <li className="nav-item ml-2">
                <a href="#" onClick={() => this.handleClick('year')} className="nav-link text-center" data-toggle="tab">
                  <small className="h6">365 Days</small>
                </a>
              </li>
            </ul>
        </div>
        <div className="card-body">
          {renderChart}
        </div>
      </div>
    );
  }
}

export default OverviewMoodChart;