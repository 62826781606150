import React from 'react';
import './overview.scss';

class OverviewAssessments extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      last_submited_assessment: props.last_submited_assessment,
      next_schedule_assessment: props.next_schedule_assessment
    }
  }

  render () {
    const {
      last_submited_assessment,
      next_schedule_assessment
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Assessments</h6>
            </div>
              <span className="btn btn-sm btn-icon-only rounded-circle circle-around">
                <i className="fa-regular fa-chart-line-up purple fa-lg"></i>
              </span>
          </div>
          {
            (last_submited_assessment && last_submited_assessment.template_name && last_submited_assessment.done_at) && (
              <p className="mb-0 d-flex align-items-center"><span className="dot green mr-1"></span><span>{`Last assessment submitted - ${last_submited_assessment.template_name} - ${last_submited_assessment.done_at}`}</span></p>
            )
          }
          {
            next_schedule_assessment && next_schedule_assessment.template_name && next_schedule_assessment.next_request_at && (
              <p className="d-flex align-items-center"><span className="dot orange mr-1"></span><span>{`Next assessment scheduled - ${ next_schedule_assessment.template_name } - ${ next_schedule_assessment.next_request_at }`}</span></p>
            )
          }
        </div>
      </div>
    )
  }
}

export default OverviewAssessments;
