import React, { useState } from 'react';
import RequestAssessmentModal from './request-assessment-modal';
import AssessmentListCard from './assessment-list-card';
import { ASSESSMENT_TITLE_TEMPLATES } from '../../../constants';
import AssessmentResultModal from './assessment-result-modal';
import AssessmentDetailModal from './assessment-detail-modal';

const AssessmentsListPage = (props) => {
  const {patient} = props;
  const [assessmentTemplateId, setAssessmentTemplateId] = useState();
  const [assessmentTemplate, setAssessmentTemplate] = useState({});
  const [assessment, setAssessment] = useState({});

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Assessments</h5>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a href="#modal-assessment-request" className="btn btn-white btn-sm rounded-pill" data-toggle="modal">Request Assessment</a>
            <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
              <i className="fas fa-bars"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="card-columns card-columns-2 mt-4">
        {
        ASSESSMENT_TITLE_TEMPLATES.map((item, index) => (
          <AssessmentListCard
            key={index}
            assessmentTemplateId={item.value}
            assessmentTitle={item.name}
            patient={patient}
            onRequestAgain={() => setAssessmentTemplateId(item.value)}
            onShowChart={() => setAssessmentTemplate(item)}
            onShowDetailAssessment={setAssessment}
            minScore={item.min_score}
            maxScore={item.max_score}
            />
        ))
        }
      </div>
      <RequestAssessmentModal patient={patient} assessmentTemplate={assessmentTemplateId} />
      <AssessmentResultModal patient={patient} assessmentTemplate={assessmentTemplate} />
      <AssessmentDetailModal assessment={assessment}/>
    </>
  )
}

export default AssessmentsListPage;
