import React from 'react';

class CliniciansPatientsAllergyRow extends React.Component {
  handleClick = (allergy) => this.props.clickedRow(allergy);

  render () {
    return (
      <React.Fragment>
        {this.props.allergies.map((allergy) => {
          return (
            <tr key={allergy.id}>
              <th scope="row">
                <span className="name h6 mb-0 text-sm">{allergy.name}</span>
              </th>
              <td className="text-wrap">
                <p className="mb-0">{allergy.reaction}</p>
              </td>
              <td>
                {(new Date(allergy.created_at)).toLocaleString()}
              </td>
              <td className="text-right">
                <div className="actions ml-3">
                  <a onClick={() => this.handleClick(allergy)} href="#" className="action-item mr-2" data-toggle="modal" data-target="#modal-allergy">
                    <i className="fas fa-pencil-alt" />
                  </a>
                </div>
              </td>
            </tr>
          )
        })}
      </React.Fragment>
    )
  }
}

export default CliniciansPatientsAllergyRow;