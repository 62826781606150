import React from 'react';
import ClinicianPatientForm from './form';

class ClinicianPatientModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div className="modal fade" id="modal-patient-create" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ClinicianPatientForm store={this.props.store} isAdminClinician={this.props.isAdminClinician} />
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianPatientModal;