import React from 'react';
import axios from 'axios';
import { COUNTRY_CODES } from '../../country-codes';
import {
  API_SERVER,
  VALID_EMAIL
} from '../../constants';

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errors: {
        clinic: [],
        name: [],
        surname: [],
        email: [],
        password: []
      },
      clinic: '',
      location: 'AU',
      name: '',
      surname: '',
      email: '',
      password: '',
      passwordHidden: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount = () => localStorage.removeItem('clinician_auth');

  handleSubmit = event => {
    event.preventDefault();

    this.setState({isLoading: true})

    const {
      clinic,
      location,
      name,
      surname,
      email,
      password
    } = this.state;

    const formData = new FormData();

    formData.append('clinic', clinic);
    formData.append('location', location);
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('password_confirmation', password);
    formData.append('tos', true);

    axios({
      method: 'POST',
      url: API_SERVER + '/clinicians/auth.json',
      data: formData
    })
    .then(response => {
      const token = response.headers['access-token'],
            client = response.headers['client'],
            uid = response.headers['uid'],
            queryString = require('query-string'),
            query = {token: token, client: client, uid: uid},
            location = '/clinicians/auth/sign_in?' + queryString.stringify(query),
            clinician = response.data.data;

      localStorage.setItem('clinician_auth', JSON.stringify({
        'access-token': token,
        'client': client,
        'uid': uid,
        'has_access_to_medications': clinician.has_access_to_medications
      }));
      
      window.location = location;
    })
    .catch(error => {
      if (error.response) {
        const errors = error.response.data.errors;
        const errorsData = {
          clinic: errors.clinic || [],
          name: errors.name || [],
          surname: errors.surname || [],
          email: errors.email || [],
          password: errors.password || []
        };

        this.setState({errors: errorsData})
      }

      this.setState({isLoading: false});
    });
  }

  handleChange = event => {
    const name = event.target.name,
          value = event.target.value;

    let errors = this.state.errors;
    errors[name] = []

    this.setState({[name]: value, errors: errors});
  };

  togglePassword = () => this.setState({ passwordHidden: !this.state.passwordHidden });

  render () {
    const {
      isLoading,
      clinic,
      location,
      name,
      surname,
      email,
      password,
      errors
    } = this.state,
      emailValid = VALID_EMAIL.test(email) || email.length === 0,
      passwordValid = password.length >= 0,
      emailClassName = emailValid && errors.email.length === 0 ? 'form-control' : 'form-control is-invalid',
      passwordClassName = passwordValid && errors.password.length === 0 ? 'form-control' : 'form-control is-invalid',
      formInvalid = !(emailValid && passwordValid),
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="form-control-label">Account Name <abbr title="notwendig">*</abbr></label>
                <input
                  type="text"
                  autoFocus="autofocus"
                  className={errors.clinic.length > 0 ? 'form-control is-invalid' : 'form-control'}
                  placeholder="Type here your clinic, service, hospital, or organisation name as you'd like it to appear in the patient-facing app. (This can also be easily changed later if you need!)"
                  name="clinic"
                  onChange={this.handleChange}
                  value={clinic}
                  required={true} />
                  <div className="invalid-feedback">{errors.clinic}</div>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label className="form-control-label">Location <abbr title="notwendig">*</abbr></label>
                <select
                  className="form-control"
                  name="location"
                  value={location}
                  onChange={this.handleChange}>
                  {COUNTRY_CODES?.map((c, i) => {return <option key={i} value={c.code}>{c.name}</option>})}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="form-control-label">First Name <abbr title="notwendig">*</abbr></label>
                <input
                  type="text"
                  className={errors.name.length > 0 ? 'form-control is-invalid' : 'form-control'}
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                  className="form-control"
                  required={true} />
                <div className="invalid-feedback">{errors.name}</div>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label className="form-control-label">Last Name <abbr title="notwendig">*</abbr></label>
                <input
                  type="text"
                  className={errors?.surname ? 'form-control is-invalid' : 'form-control'}
                  name="surname"
                  onChange={this.handleChange}
                  value={surname}
                  className="form-control"
                  required={true} />
                <div className="invalid-feedback">{errors.surname}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label className="form-control-label">Email <abbr title="notwendig">*</abbr></label>
                <input
                  onChange={this.handleChange}
                  className={emailClassName}
                  type="email"
                  name="email"
                  value={email}
                  autoComplete="off"
                  required={true} />
                <div className="invalid-feedback">{errors.email}</div>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group mb-4">
                <label className="form-control-label">Password <abbr title="notwendig">*</abbr></label>
                <div className="input-group input-group-merge">
                  <input
                    onChange={this.handleChange}
                    className={passwordClassName}
                    type={this.state.passwordHidden ? "password" : "text"}
                    name="password"
                    value={password}
                    autoComplete="off"
                    required={true} />
                  {
                    (!passwordValid || formInvalid) &&
                    <div className="invalid-feedback">
                      Password can't be blank
                    </div>
                  }
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-eye eye-overprimary" onClick={this.togglePassword} style={{cursor: 'pointer'}} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mt-4 mb-0">
            <div className="d-flex align-items-center justify-content-center">
              <button disabled={formInvalid} type="submit" className="btn btn-sm btn-primary btn-overprimary btn-icon rounded-pill">
                <span className="btn-inner--text">Sign Up</span>
                <span className="btn-inner--icon"><i className={iconClassName} /></span>
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default SignupForm
