import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../constants';

class OverviewCurrentAllergy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      data: []
    }
  }

  componentDidMount = () => {
    this.setState({isLoading: true})

    let params = {'q[patient_id_eq]': this.props.patient.id};

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/allergies.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: false, data: result.data}))
    .catch((result) => console.log(result))
  }

  render () {
    const {
      data
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-allergry mb-0">
          <div className="row">
            <div className="col">
              <h6 className="h6 card-allergry-title">MEDICATION ALLERGIES</h6>
              {data.map((m, i) => {
                return (<span key={i}>{m.name}</span>)
              })}
            </div>
            <div className="col-auto">
              <span className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle">
                <img alt="icon exclamation solid" src="/themes/wellifiy/img/icons/cards/exclamation-solid-red.svg" className="icon-exclamation-red" />
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OverviewCurrentAllergy;