import React from 'react';

const RightNavbar = () => {
  return (
    <>
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="media">
              <i className="fas fa-info-circle"></i>
              <div className="media-body ml-3">
                <a href="/clinicians/infos" className="stretched-link h6 mb-1">Client Info</a>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <i className="fas fa-headset"></i>
              <div className="media-body ml-3">
                <a href="/clinicians/services" className="stretched-link h6 mb-1">Support Services</a>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="media">
              <i className="fas fa-life-ring"></i>
              <div className="media-body ml-3">
                <a href="/clinicians/crisis_services" className="stretched-link h6 mb-1">Crisis Support Services</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightNavbar;
