import React, { useEffect, useRef, useState } from 'react';
import select2 from 'select2';
import { ASSESSMENT_TITLE_TEMPLATES } from '../../../constants';

const AssessmentTitleSelect = (props) => {
  const {onChange, defaultSelect} = props;
  const selectAssessmentTitle = useRef();

  useEffect(() => {
    $(selectAssessmentTitle.current)
      .val(defaultSelect)
      .trigger('change');
  },[props.defaultSelect]);

  useEffect(() => {
    $(selectAssessmentTitle.current)
    .select2({
      data: ASSESSMENT_TITLE_TEMPLATES.map((el) => {
        return { id: el.value, text: el.name };
      }),
    })
    .on("select2:select", (e) => {
      onChange(e.params.data.id);
    })
    .val(defaultSelect)
    .trigger('change');
  }, []);

  return (
    <select
      ref={selectAssessmentTitle}
      className="form-control"
      data-toggle="select">
    </select>
  )
}

export default AssessmentTitleSelect;
