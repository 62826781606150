import React from 'react';
import axios from 'axios';
import CliniciansPatientsScheduleRow from '../rows/schedule';
import { API_SERVER } from '../../../../constants';

const initialSchedule = {
  id: undefined,
  amount: 1,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  time_at: '10:00'
}

class ClinicianMedicationModal extends React.Component {
  state = {
    isLoading: false,
    id: undefined,
    clinic_id: this.props.medication.clinic_id,
    patient_id: this.props.medication.patient_id,
    name: '',
    dosage: '',
    schedules: []
  };

  handleAddSchedule = () => {
    const schedules = this.state.schedules || [];
    this.setState({schedules: schedules.concat(initialSchedule)})
  };

  handleScheduleState = (position, schedule) => this.state.schedules[position] = schedule;

  handleClickedSchedule = (position) => {
    let schedules = this.state.schedules;
    schedules.splice(position, 1)

    this.setState({schedules: schedules})
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleDelete = (medId) => {
    const medicationModal = window.$('#modal-medication');
    const confirm = window.confirm("Are you sure want to delete this medication?");

    if (confirm) {
      this.setState({isLoading: true})
      axios({
        method: 'DELETE',
        url: API_SERVER + '/clinicians/medications/' + medId + '.json',
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then((response) => {
        this.props.result(response.data, 'delete')
        medicationModal.modal('hide');
        this.setState({isLoading: false});
      })
      .catch(() => {
        medicationModal.modal('hide');
        this.setState({isLoading: false});
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      patient_id,
      clinic_id,
      name,
      dosage,
      schedules
    } = this.state;

    const medicationModal = window.$('#modal-medication');

    const method = id ? 'PUT' : 'POST';
    const url = id ? (API_SERVER + '/clinicians/medications/' + id + '.json') : (API_SERVER + '/clinicians/medications.json');
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('medication[patient_id]', patient_id);
    formData.append('medication[name]', name);
    formData.append('medication[dosage]', dosage);

    for (let i = 0; i < schedules.length; i++) {
      let id = schedules[i]['id'];

      if (id) {
        formData.append('medication[schedules_attributes]['+i+'][id]', id);
      }
      formData.append('medication[schedules_attributes]['+i+'][patient_id]', patient_id);
      formData.append('medication[schedules_attributes]['+i+'][clinic_id]', clinic_id);
      formData.append('medication[schedules_attributes]['+i+'][amount]', schedules[i]['amount']);
      formData.append('medication[schedules_attributes]['+i+'][monday]', schedules[i]['monday']);
      formData.append('medication[schedules_attributes]['+i+'][tuesday]', schedules[i]['tuesday']);
      formData.append('medication[schedules_attributes]['+i+'][wednesday]', schedules[i]['wednesday']);
      formData.append('medication[schedules_attributes]['+i+'][thursday]', schedules[i]['thursday']);
      formData.append('medication[schedules_attributes]['+i+'][friday]', schedules[i]['friday']);
      formData.append('medication[schedules_attributes]['+i+'][saturday]', schedules[i]['saturday']);
      formData.append('medication[schedules_attributes]['+i+'][sunday]', schedules[i]['sunday']);
      formData.append('medication[schedules_attributes]['+i+'][time_at]', schedules[i]['time_at']);
    }

    axios({
      method: method,
      url: url,
      headers: headers,
      data: formData
    })
    .then((response) => {
      const action = id ? 'update' : 'add';

      this.props.result(response.data, action)
      medicationModal.modal('hide');
      this.setState({isLoading: false});
    })
    .catch(() => {
      medicationModal.modal('hide');
      this.setState({isLoading: false});
    })
  }

  componentDidMount = () => {
    const medicationModal = window.$('#modal-medication');

    medicationModal.on('shown.bs.modal', () => {
      this.setState({
        id: this.props.medication.id,
        clinic_id: this.props.medication.clinic_id,
        name: this.props.medication.name,
        dosage: this.props.medication.dosage,
        schedules: this.props.medication.schedules || [initialSchedule]
      })
    })

    medicationModal.on('hidden.bs.modal', () => this.setState({
      isLoading: false,
      id: null,
      name: '',
      dosage: '',
      schedules: []
    }))
  };

  render () {
    const {
      isLoading,
      id,
      name,
      dosage,
      schedules
    } = this.state,
      nameInvalid = name.length === 0,
      dosageInvalid = dosage.length === 0,
      formInvalid = nameInvalid || dosageInvalid,
      nameClassName = nameInvalid ? 'form-control is-invalid' : 'form-control',
      dosageClassName = dosageInvalid ? 'form-control is-invalid' : 'form-control',
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <div className="modal fade" id="modal-medication" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label">Medication name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    value={name}
                    className={nameClassName} />
                  <div className="invalid-feedback">Please provide medication name</div>
                </div>
                <div className="form-group">
                  <label className="form-control-label">Dosage (in mg)</label>
                  <input
                    type="text"
                    name="dosage"
                    onChange={this.handleChange}
                    value={dosage}
                    className={dosageClassName} />
                  <div className="invalid-feedback">Please provide dosage</div>
                </div>
                {(schedules || []).map((schedule, index) => {
                  return (
                    <CliniciansPatientsScheduleRow
                      handleScheduleState={this.handleScheduleState}
                      clickedSchedule={this.handleClickedSchedule}
                      schedule={schedule}
                      position={index}
                      key={index} />)
                })}
                <a onClick={this.handleAddSchedule} href="#">Add schedule</a>
              </div>
              <div className="modal-footer">
                {id && <button type="button" onClick={() => this.handleDelete(id)} className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete</button>}
                <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  {id ? "Update Medication" : "Create Medication"}&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    )
  }
}

export default ClinicianMedicationModal;
