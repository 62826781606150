import React from 'react';
import CrisisServiceForm from './form';

const CrisisServiceModal = (props) => {

  return (
    <div className="modal fade" id="modal-crisis-support-service-create" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <CrisisServiceForm crisisService={props.crisisService}/>
        </div>
      </div>
    </div>
  )
}

export default CrisisServiceModal;
