import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

class ClinicianPatientProfilePictureForm extends React.Component {
  state = {
    isLoading: false,
    id: this.props.id,
    avatar_url: this.props.avatar_url
  }

  onFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      this.setState({isLoading: true})

      const { id } = this.state;
      const formData = new FormData();

      formData.append('patient[avatar]', file);

      axios({
        method: 'PUT',
        url: API_SERVER + '/clinicians/patients/'+ id +'.json',
        data: formData,
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then(() => window.location.reload())
      .catch((error) => console.log(error))
    }
  }

  render () {
    const {
      avatar_url
    } = this.state;

    return (
      <form className="text-center">
        <input
          onChange={this.onFileChange}
          type="file"
          name="avatar"
          id="file-1"
          className="custom-input-file custom-input-file-link" />
        <label htmlFor="file-1" className="avatar rounded-circle avatar-xl">
          <img src={avatar_url} />
          <small className="d-block avatar-edit-note">
            <i className="fas fa-edit" />&nbsp;Edit
          </small>
        </label>
      </form>
    )
  }
}

export default ClinicianPatientProfilePictureForm;