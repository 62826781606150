import React from 'react';
import axios from 'axios';
import { API_SERVER, FORMAT_DATE, FORMAT_TIME } from '../../../constants';
import './overview.scss';
import NotificationRuleSettingModal from './notification-rule-setting-modal'

class OverviewNotificationRule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      patient: props.patient,
      current_clinician: props.current_clinician,
      patient_notification: props.patient_notification
    },
    this.handleResult = this.handleResult.bind(this)
  }

  handleResult = (patient_notification) => {
    this.setState({patient_notification: JSON.parse(patient_notification)})
  }

  render () {
    const {
      patient,
      current_clinician,
      patient_notification
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-rule mb-0">
          <div className="row">
            <div className="col">
              <h6 className="h6 card-notification-rule-title">NOTIFICATION RULE</h6>
              <div className="row card-wrap-description">
                <div className="col-md-10">
                  <p className="notification-rule-description">
                    {
                      patient_notification && (patient_notification.status == "active" && (
                        `Send a notification if ${patient.name} records a mood score of ${patient_notification && patient_notification.mood_score_max} or less, in ${patient_notification && patient_notification.consecutive_posts} or more consecutive entries. `
                      ) || (patient_notification.status == "inactive" && ("There is no mood notification rule currently set.")))
                    }
                    {
                      patient_notification == undefined && ("There is no mood notification rule currently set.")
                    }
                  </p>
                  {
                    patient_notification && patient_notification.set_notification_rule_at && (
                      <p className="notification-rule-note">
                        {
                          (patient_notification.status == "active" && (`Current notification rule set by `)) || (patient_notification.status == "inactive" && ("Notification rule disabled by "))
                        }
                        {current_clinician.name} {current_clinician.surname} on &nbsp;
                        {patient_notification.set_notification_rule_at && FORMAT_DATE(patient_notification.set_notification_rule_at)} at &nbsp;
                        {patient_notification.set_notification_rule_at && FORMAT_TIME(patient_notification.set_notification_rule_at)}
                      </p>
                    )
                  }
                </div>
                <span data-target="#modal-notification-rule-setting" data-toggle="modal" className="edit-rule-button">Edit Rule</span>
              </div>
            </div>
          </div>
        </div>
        <NotificationRuleSettingModal resultCallback={this.handleResult} patient_notification={patient_notification} patient={patient} />
      </div>
    )
  }
}

export default OverviewNotificationRule;
