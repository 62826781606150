import React from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import ClinicianAppointmentModal from '../../appointments/appointment-modal';
import { API_SERVER } from '../../../constants';

const initialCalendar = {
  isLoading: false,
  events: [],
  selectedEvent: null
}

class CliniciansPatientAppointmentCalendar extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = initialCalendar;
  }

  handleClick = (clickedInfo) => {
    this.setState({selectedEvent: clickedInfo.event});
    window.$('#new-event').modal('show');
  }

  handleContent = (eventContent) => {
    const timeText = eventContent.timeText.toUpperCase(),
          title = eventContent.event.title;

    return (
      <div>
        <b>{timeText}</b>&nbsp;<i>{title}</i>
      </div>
    )
  }

  handleResult = (event) => {
    let events = [];

    if (event.create) {
      events = this.state.events.concat(event.data)
    }

    if (event.update) {
      events = this.state.events.filter(el => el.id != event.data.id);
      events = events.concat(event.data);
    }

    if (event.delete) {
      events = this.state.events.filter(el => el.id != event.data.id);
    }

    this.setState({events: events})
  }

  handleSelect = (selectInfo) => {
    selectInfo['patient_id'] = this.props.patient.id;
    selectInfo['patient_surname'] = this.props.patient.surname;
    selectInfo['patient_full_name'] = this.props.patient.full_name;
    selectInfo['clinician_id'] = this.props.patient.current_clinician_id;
    selectInfo['clinician_full_name'] = this.props.patient.current_clinician_full_name;
    selectInfo['title'] = '';
    selectInfo['location'] = '';

    this.setState({selectedEvent: selectInfo});
    window.$('#new-event').modal('show');
  }

  componentDidMount = () => {
    this.setState({isLoading: true});

    const params = {'q[patient_id_eq]': this.props.patient.id};
    const url = API_SERVER + '/clinicians/appointments.json';
    const method = 'GET';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: method,
      url: url,
      headers: headers,
      params: params
    })
    .then((response) => {
      this.setState({events: this.state.events.concat(response.data)})
    })
    .catch((response) => console.log(response))
  }

  render () {
    const {
      events,
      selectedEvent
    } = this.state;

    return (
      <React.Fragment>
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ]}
          headerToolbar={{
            left: 'title',
            right: 'prev,next, dayGridMonth,timeGridWeek,timeGridDay'
          }}
          editable={true}
          events={events}
          eventClick={this.handleClick}
          eventContent={this.handleContent}
          initialView='dayGridMonth'
          selectable={true}
          select={this.handleSelect}
          themeSystem='bootstrap'
        />
        <ClinicianAppointmentModal selectedInfo={selectedEvent} resultCallback={this.handleResult} />
      </React.Fragment>
    )
  }
}

export default CliniciansPatientAppointmentCalendar;