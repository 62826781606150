import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../../constants';

class ClinicianAllergyModal extends React.Component {
  state = {
    isLoading: false,
    id: null,
    patient_id: this.props.allergy.patient_id,
    name: '',
    reaction: ''
  };

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleDelete = (allergyId) => {
    const allergyModal = window.$('#modal-allergy');
    const confirm = window.confirm("Are you sure want to delete this allergy?");

    if (confirm) {
      this.setState({isLoading: true})
      axios({
        method: 'DELETE',
        url: API_SERVER + '/clinicians/allergies/' + allergyId + '.json',
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then((response) => {
        this.props.result(response.data, 'delete')
        allergyModal.modal('hide');
        this.setState({isLoading: false});
      })
      .catch(() => {
        allergyModal.modal('hide');
        this.setState({isLoading: false});
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      patient_id,
      name,
      reaction 
    } = this.state;

    const allergyModal = window.$('#modal-allergy');

    const method = id ? 'PUT' : 'POST';
    const url = id ? (API_SERVER + '/clinicians/allergies/' + id + '.json') : (API_SERVER + '/clinicians/allergies.json');
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('allergy[patient_id]', patient_id);
    formData.append('allergy[name]', name);
    formData.append('allergy[reaction]', reaction);

    axios({
      method: method,
      url: url,
      headers: headers,
      data: formData
    })
    .then((response) => {
      const action = id ? 'update' : 'add';

      this.props.result(response.data, action)
      allergyModal.modal('hide');
      this.setState({isLoading: false});
    })
    .catch(() => {
      allergyModal.modal('hide');
      this.setState({isLoading: false});
    })
  }

  componentDidMount = () => {
    const allergyModal = window.$('#modal-allergy');

    allergyModal.on('shown.bs.modal', () => this.setState({
      id: this.props.allergy.id,
      name: this.props.allergy.name,
      reaction: this.props.allergy.reaction
    }))

    allergyModal.on('hidden.bs.modal', () => this.setState({
      id: null,
      name: '',
      reaction: ''
    }))
  }; 

  render () {
    const {
      isLoading,
      id,
      name,
      reaction
    } = this.state,
      nameInvalid = name.length === 0,
      reactionInvalid = reaction.length === 0,
      formInvalid = nameInvalid || reactionInvalid,
      nameClassName = nameInvalid ? 'form-control is-invalid' : 'form-control',
      reactionClassName = reactionInvalid ? 'form-control is-invalid' : 'form-control',
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <div className="modal fade" id="modal-allergy" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label">Allergy Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    className={nameClassName} />
                  <div className="invalid-feedback">Please provide allergy name</div>
                </div>
                <div className="form-group">          
                  <label className="form-control-label">Allergic Reaction</label>
                  <textarea
                    name="reaction"
                    value={reaction}
                    onChange={this.handleChange}
                    className={reactionClassName}
                    rows={3}
                    data-toggle="autosize" />
                  <div className="invalid-feedback">Please provide reaction</div>
                </div>
              </div>
              <div className="modal-footer">
                {id && <button onClick={() => this.handleDelete(id)} type="button" className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete</button>}
                <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  {id ? "Update Allergy" : "Create Allergy"}&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianAllergyModal;
