import React from 'react';
import { connect } from 'react-redux';
import ClinicianPatientChart from './patient-chart';
import { FORMAT_DATE } from '../../constants';
import './patient-card.scss';

class ClinicianPatientCard extends React.Component {
  handleEditClick = (selectedPatient) => {
    const patient = {
      id: selectedPatient.id,
      mrn: selectedPatient.mrn,
      name: selectedPatient.name,
      surname: selectedPatient.surname,
      date_of_birth: selectedPatient.date_of_birth || '',
      program: selectedPatient.program_value || 0,
      country_code: selectedPatient.country_code || 'AU',
      mobile_phone: selectedPatient.mobile_phone || '',
      email: selectedPatient.email || '',
      avatar: selectedPatient.avatar || undefined,
      active: selectedPatient.active
    }
    this.props.dispatch({type: 'EDIT_PATIENT', selectedPatient: patient});
  }

  render() {
    const patient = this.props.patient;

    return (
      <div className="card hover-shadow-lg">
        <div className="card-body">
          <div className="avatar-parent-child avatar-patient text-center mb-2">
            { patient.avatar && <img alt="Patient Avatar" src={patient.avatar} className="avatar rounded-circle avatar-lg" /> }
          </div>
          <h5 className="h6 mb-2 text-center">{patient.name} {patient.surname}</h5>
          <p className="text-sm mb-0">Participant ID: {patient.mrn}</p>
          <div className="row">
            <div className="col-md-6">
              <p className="text-sm text-muted mb-0">DOB: {patient.date_of_birth && FORMAT_DATE(patient.date_of_birth)}</p>
            </div>
            <div className="col-md-6">
              <p className="text-sm text-muted mb-0">Gender: {patient.gender_capitalize}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <p className="text-sm text-muted mb-0">Tel : {patient.mobile_phone}</p>
            </div>
            <div className="col-md-6">
            <p className="text-sm text-muted mb-0">Status : {patient.active ? 'Active' : 'Inactive'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="text-sm text-muted mb-0">Program : {patient.program}</p>
            </div>
          </div>
          <hr />
          <div className="btn-dashboard-wrap">
            <a href={'/clinicians/patients/' + patient.id} className="btn-primary btn-overprimary">Dashboard</a>
          </div>
          <hr />
          <div className="service-wrap d-flex flex-column">
            <p>Shortcuts</p>
            <div className="row mb-2">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/assessments"} className="btn-link">Assessments</a>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/messages"} className="btn-link">Message Centre</a>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/health_wellbeing"} className="btn-link">Education</a>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/rewards"} className="btn-link">Goals</a>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/progress_monitoring"} className="btn-link">Progress Monitoring</a>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <a href={"/clinicians/patients/" + patient.id + "/correspondences"} className="btn-link">Documents</a>
              </div>
            </div>
          </div>

          <a href="#" onClick={() => this.handleEditClick(patient)} className="edit-link action-item" data-toggle="modal" data-target="#modal-patient-create">
            Edit Profile
          </a>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  selectedPatient: state.selectedPatient
})

export default connect(mapStateToProps)(ClinicianPatientCard);