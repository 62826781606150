import React from 'react';
import axios from 'axios';
import $ from 'jquery';

import {
  API_SERVER
} from '../../constants';
import { Scrollbars } from 'react-custom-scrollbars';

class ClinicianPatientCommentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      comment: "",
      clinicianComments: [
        'Well done!',
        'Great job :)',
        'You are incredible! Keep doing all the amazing things you are doing!',
        "That's fantastic. I am so happy for you!",
        "Great effort. I can see you are trying really hard! Keep it up!",
        "Thanks for sharing with me. Let's discuss this further at our next appointment.",
        "Sounds like there were some challenges today. Let's chat about this at our next meeting.",
        "That sounds tough. Let's talk more about it when we meet next."
      ]
    }

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange = (event) => this.setState({
    [event.target.name]: event.target.value,
  });

  handleSubmit = event => {
    event.preventDefault();
    const {comment, isLoading} = this.state

    if(isLoading)
    {
      return
    }

    this.setState({isLoading: true});

    const { selectedMoodId } = this.props;

    const url = (API_SERVER + '/clinicians/moods/' + selectedMoodId + '/add_comment.json')
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('comment', comment);

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: headers
    })
    .then((res) => {
      $('[data-dismiss]').click()
      this.setState({isLoading: false, comment: ''})
      this.props.reload()
    })
    .catch(error => {
      console.log(error)
    })
  }

  handleAddComment = comment => {
    const { isLoading } = this.state;

    if(isLoading)
    {
      return
    }

    this.setState({isLoading: true});

    const { selectedMoodId } = this.props;

    const url = (API_SERVER + '/clinicians/moods/' + selectedMoodId + '/add_comment.json')
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('comment', comment);

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: headers
    })
    .then((res) => {
      $('[data-dismiss]').click()
      this.setState({isLoading: false, comment: ''})
      this.props.reload()
    })
    .catch(error => {
      console.log(error)
    })
  }

  render () {
    const { patientComment } = this.props
    const { comment, isLoading, clinicianComments } = this.state
    const iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    const saveDisabled = (comment === "")

    return (
      <div className="modal fade" id="modal-patient-comment" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal"></button>
            <form onSubmit={this.handleSubmit}>
              <div className="container-fluid">
                <div className="row p-3">
                  <h3>Participant Entry</h3>
                </div>
                <div className="row px-3 pb-3">
                  <textarea name="patient-comment" value={patientComment} disabled className="col rounded"></textarea>
                </div>
                <div className="row p-3">
                  <h3>Post Comment</h3>
                </div>
                <div className="row px-3 pb-3">
                  <textarea name="comment" value={comment} onChange={this.handleChange} className="col rounded"></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-sm btn-primary btn-overprimary" disabled={saveDisabled}>
                  <span className="btn-inner--text">Save&nbsp;</span>
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
              <div className="container-fluid">
                <Scrollbars
                    autoHeight
                    autoHeightMax={385}
                    // style={{ maxHeight: 385}}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                  >
                    <div className={'row border-top pr-3'} >
                      {
                        clinicianComments?.map((cm, index)=>
                        <div key={index} className="border-bottom w-100 d-flex flex-row justify-content-between align-items-center px-3 mt-2 pb-2">
                          <p className="mb-0">{cm}</p>
                          <button className="btn btn-sm btn-primary btn-overprimary"
                            onClick={(e) => {
                              e.preventDefault()
                              this.handleAddComment(cm)
                            }
                            }>Add</button>
                        </div>)
                      }
                    </div>
                  </Scrollbars>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianPatientCommentModal;