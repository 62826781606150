import React from 'react';
import './health-wellbeing.scss';
import { API_SERVER } from "../../../constants";
import axios from 'axios';

class HealthWellbeing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabledCourses : [],
      isLoading : false,
      patient_id : this.props.patient ? this.props.patient.id : 0,
      clinician_id : this.props.patient.clinician_id || 0,
      userWellbeingCourseId : 0,
      wellbeingCourses : [],
      healthWellbeings: []
    }

    this.toggleList = this.toggleList.bind(this);
    this.toggleListAll = this.toggleListAll.bind(this);
    this.organisationCheck = this.organisationCheck.bind(this);
    this.onChangeCheckBoxOrganisation = this.onChangeCheckBoxOrganisation.bind(this);
    this.changeOrganizationName = this.changeOrganizationName.bind(this)
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchHealthWellbeings = () => {
    axios({
      method : 'GET',
      url : API_SERVER + '/health_wellbeings/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      console.log(res.data)
      this.setState({
        ...this.state,
        healthWellbeings : res.data,
      });
    }).catch((error) => {
      console.log("Health Wellbeing fetch error", error);
    });
  }

  fetchUserWellbeingCourses = () => {
    const params = {'q[patient_id_eq]': this.state.patient_id};
    axios({
      method : 'GET',
      url : API_SERVER + '/clinicians/user_wellbeing_courses/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
      params : params
    }).then((res) => {
      console.log("response user wellbeing courses", res.data);
      if (res.data[0] && res.data[0].health_wellbeings && JSON.parse(res.data[0].health_wellbeings)) {
        this.setState({
          ...this.state,
          enabledCourses : JSON.parse(res.data[0].health_wellbeings),
          userWellbeingCourseId : res.data[0].id,
        });
      }
    }).catch((error) => {
      console.log("user wellbeing courses fetch error", error);
    });
  }

  fetchData = async () => {
    await this.fetchHealthWellbeings();
    await this.fetchUserWellbeingCourses();
  }

  setEnabledCourses = (courses) => {
    this.setState({
      ...this.state,
      enabledCourses : courses,
    });
  }

  onChangeCheckBox = (e, resourceId) => {
    const isChecked = e.target.checked;
    let tempEnabledCourses = [...this.state.enabledCourses];
    const target = tempEnabledCourses.includes(resourceId);

    if(isChecked && !target) {
      this.setEnabledCourses([...tempEnabledCourses, resourceId]);
    } else if(!isChecked && target) {
      tempEnabledCourses = tempEnabledCourses.filter(x => x !== resourceId);
      this.setEnabledCourses(tempEnabledCourses);
    }
  }

  onChangeCheckBoxOrganisation = (e, organisationMobileDataId) => {
    const organisation = this.state.healthWellbeings.find(item => item.mobile_data_id === organisationMobileDataId)
    if (!organisation) return
    let tempEnabledCourses = [...this.state.enabledCourses];
    const resourceMobileDataIds = organisation.resources.map(item => item.mobile_data_id)
    if(e.target.checked) {
      tempEnabledCourses = [...new Set(tempEnabledCourses.concat(resourceMobileDataIds))]
    } else {
      tempEnabledCourses = tempEnabledCourses.filter(x => !resourceMobileDataIds.includes(x));
    }
    this.setEnabledCourses(tempEnabledCourses)
  }

  toggleList = (index) => {
    this.state.healthWellbeings[index].is_open_list = !this.state.healthWellbeings[index].is_open_list
    this.setState({...this.state, healthWellbeings : this.state.healthWellbeings});
    if(this.state.healthWellbeings.every(item => item.is_open_list === false)) {
      this.setState({ ...this.state, isExpandAll: true })
    }
  }

  toggleListAll = () => {
    this.state.healthWellbeings.map((item) => {
      item.is_open_list = true
    })
    this.setState({...this.state, healthWellbeings : this.state.healthWellbeings});
  }

  organisationCheck = (organisation_mobile_data_id) => {
    const organisation = this.state.healthWellbeings.find(item => item.mobile_data_id === organisation_mobile_data_id)
    const resourceMobileDataIDs = organisation.resources.map(item => item.mobile_data_id)
    return resourceMobileDataIDs.some(id => this.state.enabledCourses.includes(id))
  }

  handleSaveCourses = () => {
    this.setState({...this.state, isLoading : true});

    const request = {
      "id": this.state.userWellbeingCourseId,
      "clinician_id": this.state.clinician_id,
      "patient_id": this.state.patient_id,
      "health_wellbeings": JSON.stringify(this.state.enabledCourses),
    }
    axios({
      method : 'POST',
      url : API_SERVER + '/clinicians/user_wellbeing_courses/',
      data : request,
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      this.setState({...this.state, enabledCourses: JSON.parse(res.data.health_wellbeings), isLoading: false});
    }).catch((error) => {
      console.log("user well being courses update error", error);
      this.setState({...this.state, isLoading: false});
    });

  }

  changeOrganizationName = (organisation_name) => {
    if (organisation_name === 'IBD Information') {
      return 'Crohn’s & Colitis Australia'
    } else if (organisation_name === 'Diabetes Information') {
      return 'Baker Heart & Diabetes Institute'
    } else if (organisation_name === 'Wellbeing Information') {
      return 'Wellifiy Wellbeing'
    } else if (organisation_name === 'Perinatal Mental Health Information') {
      return 'PANDA (Perinatal Mental Health Info)'
    } else if (organisation_name === 'Eating Disorder Information') {
      return 'InsideOut (Eating Disorders Info)'
    } else {
      return organisation_name
    }
  }

  render () {
    const { healthWellbeings, enabledCourses, isLoading } = this.state;
    const loadingClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    return (
      <>
        <div className="card card-container mt-6">
          <div className="date-container col text-dark font-weight-600 font-size-md mt-4 mb-1">
            <div className="col-md-9">
            </div>
            <div className="col-md-3 text-center flex justify-end">
              <button className="btn btn-sm btn-primary btn-overprimary" onClick={() => this.toggleListAll()} >Expand All</button>
            </div>
          </div>
          <p className="organisationName" >Organisation Name</p>
          <div className="organisations">
            {
              healthWellbeings.map((organisation, i) => {
                return (
                  <div className="organisation" key={i}>
                    <div className="organisation-header">
                      <span className="organisation-header-name">{this.changeOrganizationName(organisation.name)}</span>
                      <img alt={this.changeOrganizationName(organisation.name)} src={organisation.image_url} className="organisation-header-image" />
                      <div className="organisation-header-action">
                        <input type="checkbox" className="organisation-header-action-checkbox"
                          checked={ enabledCourses && this.organisationCheck(organisation.mobile_data_id) }
                          value={organisation.mobile_data_id}
                          onChange={(e) => this.onChangeCheckBoxOrganisation(e, organisation.mobile_data_id)}
                        />
                        { organisation.is_open_list ? <i className="fas red fa-minus" onClick={() => this.toggleList(i)}></i> : <i className="fas green fa-plus" onClick={() => this.toggleList(i)}></i> }
                      </div>
                    </div>
                    { organisation.is_open_list && <div className="text-dark resource">
                        {
                          organisation.resources.map((item, index) => {
                            return (
                              <div className="flex" key={index}>
                                <div className="col-md-8 respond-container font-size-sm">
                                  <span>{item.name}</span>
                                  { item.link && (
                                    <a href={item.link} target="_blank">
                                      <img alt="icon pill purple" src="/themes/wellifiy/img/icons/cards/arrow-up-right-from-square-solid.svg" className="arrow-up-right-square ml-1" />
                                    </a>)
                                  }
                                </div>
                                <div className="col-md-4 flex align-items-center resource-value" >
                                  <input type="checkbox"
                                    checked={ enabledCourses && enabledCourses.includes(item.mobile_data_id) ? true : false }
                                    value={item.mobile_data_id}
                                    onChange={(e) => this.onChangeCheckBox(e, item.mobile_data_id)}
                                  />
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>

          <div className="mt-4 mr-4 flex justify-end">
            <button onClick={this.handleSaveCourses} className="btn btn-sm btn-primary btn-overprimary">Save&nbsp;
              <span className="btn-inner--icon"><i className={loadingClassName} /></span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default HealthWellbeing;
