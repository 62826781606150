import axios from 'axios';
import { API_SERVER } from '../../constants';

export default function (id, data) {
  return axios({
    method: 'PUT',
    url: `${API_SERVER}/admins/clinics/${id}/appointment_nav`,
    headers: JSON.parse(localStorage.getItem('admin_auth')),
    data: data
  });
}
