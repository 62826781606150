import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../constants';
import './overview.scss';

class OverviewCurrentRewards extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      patient_id: props.patient.id,
      point_tree: props.patient.point_tree,
      point: props.patient.point,
      extra_point: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      extra_point,
      patient_id
    } = this.state;

    const url = API_SERVER + '/clinicians/patients/' + patient_id + '/update_extra_point'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: {
        extra_point: extra_point
      }
    })
    .then((response) => {
      this.setState({ point_tree: response.data.reward.point_tree });
      this.setState({ point: response.data.reward.point });
    })
    .catch(() => {
    })
  }

  render () {
    const { point_tree, point, extra_point } = this.state;

    return (
      <React.Fragment>
        <div class="page-title">
          <div class="row justify-content-between align-items-center">
            <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div class="d-inline-block">
                <h5 class="h4 d-inline-block font-weight-400 mb-0 text-white">Rewards</h5>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <button className="btn btn-sm btn-white rounded-pill ml-4" type="button">
                <span className="btn-inner--icon mr-2"><i className="fas fa-plus" /></span>
                New Goal
              </button>
              <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                <i className="fas fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <div className="reward-container">
              <p className="point-tree" >Number of trees planted: { point_tree }</p>
              <p className="point">Current points: { point }</p>
              <div className="extra-point-container">
                <label className="extra-point-label" >Allocate extra points</label>
                <input
                  className="extra-point-input"
                  value={extra_point}
                  name="extra_point"
                  type="number"
                  min="0"
                  onChange={this.handleChange}
                />
              </div>
              <p className="sumit-container" >
                <button className="btn btn-sm btn-primary btn-overprimary submit-button" onClick={this.handleSubmit} >Save</button>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default OverviewCurrentRewards;
