import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

export default function ClinciansSearchPatients(query, pageNumber, programFilter) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [patients, setPatients] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setPatients([]);
  }, [query, programFilter])

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    const params = {
      'q[mrn_or_name_or_surname_cont]': query,
      'q[program_eq]': programFilter,
      'p': pageNumber
    }

    axios({
      method: 'GET',
      url: [API_SERVER, 'clinicians/patients.json'].join('/'),
      params: params,
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(result => {
      setPatients(prevPatients => {
        return [...new Set([...prevPatients, ...result.data])]
      });

      setHasMore(result.data.length > 0);

      setLoading(false);
    }).catch(e => {
      if (e.response?.status === 401) window.location = '/clinicians/auth/sign_out';

      if (axios.isCancel(e)) return;
      setError(true);
    })
    return () => cancel();
  }, [query, pageNumber, programFilter])

  return { loading, error, patients, hasMore, setPatients}
}
