import React from 'react';
import './overview.scss';

class OverviewEducationalResources extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      organisation_names: props.organisation_names
    }
  }

  render () {
    const {
      organisation_names
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Educational Resources</h6>
            </div>
              <span className="btn btn-sm btn-icon-only rounded-circle circle-around">
              <i className="fa-regular fa-graduation-cap purple fa-lg"></i>
              </span>
          </div>
          {
            organisation_names.map((organisation_name, index) => {
              return <p key={index} className={ index != (organisation_names.length - 1) ? 'mb-0 d-flex align-items-center' : 'd-flex align-items-center'}><span className="dot green mr-1"></span><span>{organisation_name}</span></p>
            })
          }
        </div>
      </div>
    )
  }
}

export default OverviewEducationalResources;