import React, { useEffect, useRef, useState, useCallback } from 'react'
import axios from 'axios'
import {
  API_SERVER
} from '../../constants'
import { Scrollbars } from 'react-custom-scrollbars'
import ClinciansSearchPatients from '../patients/search-patients'

const NotificationSettingsModal = (props) => {
  const [query, setQuery] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const {
    patients,
    setPatients,
    hasMore,
    loading,
    error
  } = ClinciansSearchPatients(query, pageNumber)
  const observer = useRef()

  const lastPatientElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  const handleSearch = (e) => {
    setQuery(e.target.value)
    setPageNumber(1)
  }

  const handelCheckPatientNotifications = (patient, value) => {
    const {
      id,
      patient_notification_clinician_ids
    } = patient
    let cancel
    const method = 'POST'
    const url = API_SERVER + '/clinicians/patients/' + id + '/toogle_notification'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'))

    const formData = new FormData()
    let new_patient_notification_clinician_ids

    const index = patient_notification_clinician_ids? patient_notification_clinician_ids.indexOf(props.current_clinician.id) : -1
    if(index === -1)
    {
      new_patient_notification_clinician_ids = [...patient_notification_clinician_ids, props.current_clinician.id]
    }
    else
    {
      new_patient_notification_clinician_ids = [...patient_notification_clinician_ids.slice(0,index), ...patient_notification_clinician_ids.slice(index + 1)]
    }

    formData.append('patient[patient_notification_ids]', new_patient_notification_clinician_ids)

    axios({
      method: method,
      url: url,
      data: {
        toogle_notification_flag: value,
        toogle_all: true
      },
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then((res) => {
      const patientIndex = patients.indexOf(patient)
      setPatients([...patients.slice(0, patientIndex),
                  {...patients[patientIndex], patient_notification_clinician_ids: new_patient_notification_clinician_ids},
                  ...patients.slice(patientIndex + 1)
                ])
    })
    .catch((e) => {
      if (axios.isCancel(e)) return
    })

    return () => cancel()
  }

  return (
    <div>
      <div className="modal fade" id="modal-notification-settings" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="">
              <h4 className="mt-3">Notification Settings</h4>
              <p>Select the patients you'd like to receive notifications for.</p>
              <div className="d-flex justify-content-center mb-3">
                <div className="input-group input-group-sm input-group-merge input-group-flush w-75 border border-primary rounded-pill">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-transparent"><i className="fas fa-filter" /></span>
                  </div>
                  <input type="text" value={query} onChange={handleSearch} className="form-control form-control-flush" placeholder="Search" />
                </div>
              </div>
              {patients.length == 0 && !loading && <p className="text-muted">Empty participants</p>}
              <div className="row">
                <p className="col-6 mb-0 col-6 mb-0 text-center strong"><b>Participant</b></p>
                <p className='col-6 mb-0 text-center strong'><b>Receive notifications</b></p>
              </div>

              <Scrollbars
                  autoHeight
                  autoHeightMax={385}
                  autoHeightMin={385}
                  renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                >
                {patients.map((patient, index) => {
                  if (patients.length === index + 1) {
                    return (
                      <div className="row mb-3" key={patient.id} ref={lastPatientElementRef}>
                        <p className="col-6 mb-0">{patient.name} {patient.surname}</p>
                        <p className="col-6 mb-0 text-center">
                          <input type="checkbox"
                            checked={patient.patient_notification_clinician_ids?.includes(props.current_clinician.id)}
                            onChange={(e) => handelCheckPatientNotifications(patient, e.target.checked)}
                          />
                        </p>
                      </div>
                    )
                  } else {
                    return (
                      <div className="row mb-3" key={patient.id}>
                        <p className="col-6 mb-0">{patient.name} {patient.surname}</p>
                        <p className="col-6 mb-0 text-center">
                          <input type="checkbox"
                            checked={patient.patient_notification_clinician_ids?.includes(props.current_clinician.id)}
                            onChange={(e) => handelCheckPatientNotifications(patient, e.target.checked)}
                          />
                        </p>
                      </div>
                    )
                  }
                })}
              </Scrollbars>
              <div>{error && 'Error'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationSettingsModal