import React from 'react';
import { connect } from 'react-redux';
import select2 from 'select2';
import axios from 'axios';
import {
  API_SERVER,
  INVALID_DATE,
  VALID_EMAIL,
  VALID_PASSWORD,
  VALID_PHONE,
  ACTIVE_STATUSES,
  PROGRAMS
} from '../../constants';
import { COUNTRY_CODES } from '../../country-codes';

const initialPatient = {
  isLoading: false,
  formError: {},
  id: undefined,
  mrn: '',
  name: '',
  surname: '',
  date_of_birth: '',
  dd: '',
  mm: '',
  yyyy: '',
  program: 0,
  country_code: '+61',
  mobile_phone: '',
  email: '',
  password: '',
  avatar: '',
  active: true,
  gender: 'male',
}

class ClinicianPatientForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this);
    this.handleChooseGender = this.handleChooseGender.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.selectPicker = React.createRef();

    this.state = initialPatient;
  }

  handleChange = (event) => this.setState({
    [event.target.name]: event.target.value,
    formError: { [event.target.name]: undefined }
  });

  handleFileChange = (event) => this.setState({avatar: event.target.files[0]});

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      mrn,
      name,
      surname,
      dd,
      mm,
      yyyy,
      country_code,
      program,
      mobile_phone,
      email,
      password,
      avatar,
      active,
      gender,
    } = this.state;

    const method = id ? 'PUT' : 'POST';
    const url = id ? (API_SERVER + '/clinicians/patients/' + id + '.json') : (API_SERVER + '/clinicians/patients.json');
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('patient[mrn]', mrn);
    formData.append('patient[name]', name);
    formData.append('patient[surname]', surname);
    formData.append('patient[active]', active);
    formData.append('patient[gender]', gender);

    const date_of_birth = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date_of_birth)) {
      formData.append('patient[date_of_birth]', date_of_birth);
    } else {
      formData.append('patient[date_of_birth]', '');
    }

    formData.append('patient[country_code]', country_code);
    formData.append('patient[program]', program);
    formData.append('patient[mobile_phone]', mobile_phone);
    formData.append('patient[email]', email);

    if (password.length > 0) {
      formData.append('patient[password]', password);
    }

    if (avatar) {
      formData.append('patient[avatar]', avatar)
      headers['content-type'] = 'multipart/form-data';
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers
    })
    .then(() => window.location.reload())
    .catch(error => {
      const response = error.response;

      if (response) {
        this.setState({isLoading: false, formError: response.data})
      }
    })
  }

  handleDelete = (patientId) => {
    const confirm = window.confirm("Are you sure want to delete this patient?");

    if (confirm) {
      axios({
        method: 'DELETE',
        url: API_SERVER + '/clinicians/patients/' + patientId + '.json',
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then(() => window.location.reload())
      .catch(() => window.location.reload())
    }
  }

  handleChooseGender = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount = () => {
    this.props.store.subscribe(() => {
      const selectedPatient = this.props.store.getState().selectedPatient || initialPatient;
      const date_of_birth = selectedPatient.date_of_birth;

      if (!INVALID_DATE(date_of_birth)) {
        const dates = date_of_birth.split('-');

        selectedPatient.dd = dates[2];
        selectedPatient.mm = dates[1];
        selectedPatient.yyyy = dates[0];
      }

      this.setState(selectedPatient);
      this.setState({avatar: ''});
    })

    $(this.selectPicker.current).select2({
      data: COUNTRY_CODES.map((el) => {
        return {id: el.dial_code, text: el.name}
      })
    }).on('select2:select', (e) => {
      this.setState({ country_code: e.params.data.id });
    })
    .val(this.state.country_code)
    .trigger('change');
  }

  render () {
    const {
      isLoading,
      formError,
      id,
      mrn,
      name,
      surname,
      mobile_phone,
      program,
      email,
      password,
      dd,
      mm,
      yyyy,
      active,
      gender
    } = this.state,
      mrnInvalid = formError.mrn !== undefined,
      nameInvalid = formError.name !== undefined,
      surnameInvalid = formError.surname !== undefined,
      country_codeInvalid = formError.country_code !== undefined,
      phoneInvalid = (!VALID_PHONE.test(mobile_phone) && mobile_phone.length > 0) || (formError.mobile_phone !== undefined),
      emailInvalid = (!VALID_EMAIL.test(email) && email.length > 0) || formError.email !== undefined,
      passwordInvalid = (!VALID_PASSWORD.test(password) && password) || formError.password !== undefined,
      mrnClassName = mrnInvalid ? 'form-control is-invalid' : 'form-control',
      nameClassName = nameInvalid ? 'form-control is-invalid' : 'form-control',
      surnameClassName = surnameInvalid ? 'form-control is-invalid' : 'form-control',
      country_codeClassName = country_codeInvalid ? 'form-control is-invalid' : 'form-control',
      phoneClassName = phoneInvalid ? 'form-control is-invalid' : 'form-control',
      emailClassName = emailInvalid ? 'form-control is-invalid' : 'form-control',
      passwordClassName = passwordInvalid ? 'form-control is-invalid' : 'form-control',
      formValid = !mrnInvalid && !nameInvalid && !surnameInvalid && !country_codeInvalid && !phoneInvalid && !emailInvalid && !passwordInvalid,
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-control-label">MRN No. (Participant ID)</label>
            <input
              type="text"
              name="mrn"
              onChange={this.handleChange}
              value={mrn}
              className={mrnClassName} />
            <div className="invalid-feedback">{formError.mrn || 'Please provide a valid participant ID.'}</div>
          </div>
          <div className="form-group">
            <label className="form-control-label">Status</label>
            <select
              value={active}
              className="form-control"
              name="active"
              onChange={this.handleChange}>
                {ACTIVE_STATUSES.map((el) => {
                  return <option key={el.value} value={el.value}>{el.name}</option>;
                })}
            </select>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                  className={nameClassName} />
                <div className="invalid-feedback">{formError.name || 'Please provide a valid name.'}</div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Surname</label>
                <input
                  type="text"
                  name="surname"
                  onChange={this.handleChange}
                  value={surname}
                  className={surnameClassName} />
                <div className="invalid-feedback">{formError.surname || 'Please provide a valid surname.'}</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-control-label">Date of birth</label>
            <div className="form-row">
              <div className="col">
                <input
                  className="form-control"
                  placeholder="DD"
                  type="number"
                  onChange={this.handleChange}
                  name="dd"
                  value={dd}
                  min="1"
                  max="31" />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  placeholder="MM"
                  type="number"
                  onChange={this.handleChange}
                  name="mm"
                  value={mm}
                  min="1"
                  max="12" />
              </div>
              <div className="col">
                <input
                  className="form-control"
                  placeholder="YYYY"
                  type="number"
                  onChange={this.handleChange}
                  name="yyyy"
                  value={yyyy}
                  min="1920"
                  max={new Date().getFullYear()} />
              </div>
            </div>
            <div className="invalid-feedback">{formError.date_of_birth || 'Please provide a valid date of birth'}</div>
          </div>
          <div className="form-group">
            <label className="form-control-label">
              Gender
            </label>
            <div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="gender_male"
                  name="gender"
                  value="male"
                  checked={gender.toString() === 'male' }
                  onChange={this.handleChooseGender}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="gender_male">Male</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="gender_female"
                  name="gender"
                  value="female"
                  checked={gender.toString() === 'female'}
                  onChange={this.handleChooseGender}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="gender_female">Female</label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="gender_unidentified"
                  name="gender"
                  value="unidentified"
                  checked={gender.toString() === 'unidentified'}
                  onChange={this.handleChooseGender}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="gender_unidentified">Unidentified</label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-control-label">
              Program
            </label>
            <select
              value={program}
              className="form-control"
              name="program"
              onChange={this.handleChange}>
                {PROGRAMS.map((el) => {
                  return <option key={el.value} value={el.value}>{el.name}</option>;
                })}
            </select>
          </div>
          <div className="form-group">
            <label className="form-control-label">Country</label>
            <select
              ref={this.selectPicker}
              name="country_code"
              onChange={this.handleChange}
              className={country_codeClassName}>
            </select>
          </div>
          <div className="form-group">
            <label className="form-control-label">Mobile phone</label>
            <input
              type="tel"
              name="mobile_phone"
              onChange={this.handleChange}
              value={mobile_phone}
              className={phoneClassName} />
            <div className="invalid-feedback">{formError.mobile_phone || 'Please provide a valid mobile phone'}</div>
          </div>
          <div className="form-group">
            <label className="form-control-label">Email</label>
            <input
              type="email"
              name="email"
              onChange={this.handleChange}
              value={email}
              className={emailClassName} />
            <div className="invalid-feedback">{formError.email || 'Please provide a valid email.'}</div>
          </div>
          <div className="form-group">
            <label className="form-control-label">Temporary Password</label>
            <p style={{fontSize: '12px'}}>We will SMS the Patient this temporary password with the app download link.</p>
            <input
              type="password"
              name="password"
              onChange={this.handleChange}
              value={password}
              className={passwordClassName} />
            <div className="invalid-feedback">{formError.password || 'Password must be at least 6 characters.'}</div>
          </div>
          <div className="form-group">
            <label className="form-control-label">Profile's Picture</label>
            <input
              type="file"
              name="avatar"
              onChange={this.handleFileChange}
              className="form-control" />
          </div>
        </div>
        <div className="modal-footer">
          {id && this.props.isAdminClinician && <button type="button" onClick={() => this.handleDelete(id)} className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete</button>}
          <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
          <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={!formValid}>
            <span className="btn-inner--text">Save&nbsp;</span>
            <span className="btn-inner--icon"><i className={iconClassName} /></span>
          </button>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPatient: state.selectedPatient
})

export default connect(mapStateToProps)(ClinicianPatientForm);
