import React, { useState, useEffect, useRef, useCallback } from 'react';
import RightNavbar from '../../shared/right-navbar';
import ConfirmModal from '../../shared/confirm-modal';
import SupportServiceModal from './support-service-modal';
import SupportServiceRow from './support-service-row';

import GetListSupportServices from './get-list-support-services';
import DeleteSupportService from './delete-support-service';

const ListSupportServices = () => {
  const [supportServices, setSupportServices] = useState([]);
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [editingRecord, setEditingRecord] = useState();

  const loadData = async () => {
    const {
      hasMore,
      loading,
      error,
      ...rest
    } = await GetListSupportServices(query, pageNumber);
    setSupportServices([...supportServices, ...rest.supportServices]);
    setHasMore(hasMore);
    setLoading(loading);
    setError(error);
  };

  // Did mount
  useEffect(() => {
    loadData();
  }, []);

  // Did update
  useEffect(() => {
    loadData();
  }, [query, pageNumber]);

  // load more on scroll page
  const observer = useRef()
  const lastSupportServiceElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // open modal create
  const openModalCreate = () => {
    setEditingRecord('');
  }

  // open modal edit
  const openModalEdit = (supportService) => {
    setEditingRecord(supportService);
  }

  // open modal confirm delete
  const openModalConfirm = (id) => {
    setDeletingId(id);
  }

  // handle delete support service
  const handleDeleteSupportService = (id) => {
    DeleteSupportService(id);
    setSupportServices(supportServices.filter((item) => item.id !== id));
  }

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Info & Services</h5>
            </div>
          </div>
          <div className="col-auto col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 order-lg-2">
          <RightNavbar />
        </div>
        <div className="col-lg-8 order-lg-1">
          <div className="card mh-615">
            <div className="card-header actions-toolbar border-0">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-10">
                  <h6 className="d-inline-block mb-0">Support Services</h6>
                  <p className="mb-0 text-sm">
                    Enter the support services information you would like displayed in the My Support Services section of the patient-facing app. 
                  </p>
                  <p className="text-sm">
                    The information entered here will be visible by all of your services patients. Information that is deleted from this section will no longer be viewable by any patients.
                  </p>
                </div>
                <div className="col-lg-2 text-right">
                  <div className="actions">
                    <a href="#modal-support-service-create" onClick={openModalCreate} className="action-item mr-3" data-toggle="modal">
                      <i className="fas fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Area of Support</th>
                    <th scope="col">Name of Service Provider</th>
                    <th scope="col">Phone number</th>
                    <th scope="col" className="text-center">Website</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {supportServices.map((supportService, index) => {
                    if (supportServices.length === index + 1) {
                      return (
                        <tr ref={lastSupportServiceElementRef} key={index}>
                          <SupportServiceRow
                            index={index + 1}
                            area={supportService.area}
                            name={supportService.name}
                            phone={supportService.phone}
                            website={supportService.website}
                            handleEdit={() => openModalEdit(supportService)}
                            handleDelete={() => openModalConfirm(supportService.id)} />
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={index}>
                          <SupportServiceRow
                            index={index + 1}
                            area={supportService.area}
                            name={supportService.name}
                            phone={supportService.phone}
                            website={supportService.website}
                            handleEdit={() => openModalEdit(supportService)}
                            handleDelete={() => openModalConfirm(supportService.id)} />
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div>{loading && <LoadMore />}</div>
          <div>{error && 'Error'}</div>
        </div>
      </div>
      <ConfirmModal message={'Are you sure want to delete this item!'} onSubmit={() => handleDeleteSupportService(deletingId)} />
      <SupportServiceModal supportService={editingRecord} />
    </>
  );
}

export default ListSupportServices;
