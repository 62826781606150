import axios from "axios";
import { API_SERVER } from '../../constants';

export default async function (id) {
  let cancel;
  try {
    await axios({
      method: 'DELETE',
      url: `${API_SERVER}/clinicians/videos/${id}.json`,
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    });
  } catch (e) {
    if (e.response.status === 401) window.location = '/clinicians/auth/sign_out';
    if (axios.isCancel(e)) return;
  }
}
