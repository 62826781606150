import React from 'react';
import AdminsClinicianForm from './form';

class AdminsClinicianModal extends React.Component {
  render () {
    return (
      <div className="modal fade" id="modal-clinician-create" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <AdminsClinicianForm clinician={this.props.clinician} clinics={this.props.clinics} />
          </div>
        </div>
      </div>
    )
  }
}

export default AdminsClinicianModal;