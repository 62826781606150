import React, { useState } from 'react';
import GetAssessmentPdf from './get-assessment-pdf';

const AssessmentRow = (props) => {
  const {item, patient, onClick} = props;
  const [isExporting, setIsExporting] = useState(false);

  const exportPdf = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsExporting(true);

    GetAssessmentPdf(item.id)
      .then(res => {
        const file = new Blob([res.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(file);
        const a = document.createElement("a");

        a.href = url;
        a.download = `${patient.name}_assessment_${new Date().toLocaleDateString('en-GB').split('/').join('_')}.pdf`;
        setIsExporting(false);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  }

  const renderBtnExport = () => {
    return isExporting
      ? <span className="btn-inner--icon"><i className="fa fa-spinner fa-spin" /></span>
      : <a href="#" onClick={(e) => exportPdf(e, item)} className="btn btn-secondary btn-icon-only btn-xs rounded-circle">
        <i className="fas fa-file-download"></i>
      </a>
  }

  const renderStatus = (item) => {
    if (item.status == 'done') {
      return (<span className="badge badge-success badge-sm ml-2">done {formatDate(item.done_at)}</span>)
    }

    return (<span className="badge badge-warning badge-sm ml-2">pending</span>)
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB');
  }

  return (
    <div className="list-group-item list-group-item-action" onClick={onClick}>
      <div className="row align-items-center">
        <div className="col">
          <div className="text-sm lh-150 text-dark font-weight-bold">
            Date Requested : {formatDate(item?.created_at)} {renderStatus(item)}
          </div>
          <small className="d-block text-xs text-muted">Requested by: {`${item?.clinician?.title} ${item?.clinician?.name} ${item?.clinician?.surname}`}</small>
        </div>
        <div className="col-auto">
          {renderBtnExport()}
        </div>
        <div className="col-auto text-center">
          <div className="d-inline-block">
            <span className="d-block text-xs text-muted">Score</span>
            {
              item.score ? <span className="d-block h4 mb-0">{item.score}</span> : <span className="d-block h6 mb-0">N/A</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentRow;
