import React, { useState, useRef, useCallback, useEffect } from "react";
import AdminsSearchClinicians from "./search-clinicians";
import MoreClinic from "./more-clinician";
import AdminsClinicianRow from "./clinician-row";
import EmptyTableRow from "../../shared/empty-table-row";
import AdminsClinicModal from "./clinician-modal";

export default function AdminsCliniciansListPage(props) {
  const [query, setQuery] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedClinic, setSelectedClinic] = useState(null);

  const { clinicians, hasMore, loading, error } = AdminsSearchClinicians(
    query,
    pageNumber,
    clinicId
  );

  const observer = useRef();
  const selectClinicPicker = React.createRef();

  useEffect(() => {
    $(selectClinicPicker.current)
      .select2()
      .on("select2:select", (e) => {
        setClinicId(e.params.data.id);
        setQuery("");
        setPageNumber(1);
      });
  }, [props.clinics])


  const lastClinicElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleResetSearch() {
    setQuery("");
  }

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  function handleRowResult(arg) {
    setSelectedClinic(arg);
  }

  function handleNewClinic() {
    setSelectedClinic(null);
  }

  return (
    <React.Fragment>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">
                Workers
              </h5>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <div className="w-50 filter-clinic">
              <select data-toggle="select" ref={selectClinicPicker}>
                <option value="">Select clinic</option>
                {props.clinics.map((el, i) => {
                  return <option key={i} value={el.id}>{el.name}</option>
                })}
              </select>
            </div>
            <a
              href="#modal-clinician-create"
              onClick={handleNewClinic}
              className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4"
              data-toggle="modal"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header actions-toolbar border-0">
          <div className="actions-search" id="actions-search">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent">
                  <i className="fas fa-search"></i>
                </span>
              </div>
              <input
                value={query}
                onChange={handleSearch}
                type="text"
                className="form-control form-control-flush"
                placeholder="Type and hit enter ..."
              />
              <div className="input-group-append">
                <a
                  href="#"
                  onClick={handleResetSearch}
                  className="input-group-text bg-transparent"
                  data-action="search-close"
                  data-target="#actions-search"
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Workers</h6>
            </div>
            <div className="col text-right">
              <div className="actions">
                <a
                  href="#"
                  className="action-item mr-3"
                  data-action="search-open"
                  data-target="#actions-search"
                >
                  <i className="fas fa-search"></i>
                </a>
                <div className="dropdown">
                  <a
                    href="#"
                    className="action-item"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-filter"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-toggle-on"></i>Active
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-toggle-off"></i>Inactive
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Clinician Name</th>
                <th scope="col">Discipline</th>
                <th scope="col">Clinic</th>
                <th scope="col">Email</th>
                <th scope="col">DOB</th>
                <th scope="col">Has Accessed</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {clinicians.map((clinician, index) => {
                if (clinicians.length === index + 1) {
                  return (
                    <tr key={clinician.id} ref={lastClinicElementRef}>
                      <AdminsClinicianRow
                        rowResult={handleRowResult}
                        clinician={clinician}
                      />
                    </tr>
                  );
                } else {
                  return (
                    <tr key={clinician.id}>
                      <AdminsClinicianRow
                        rowResult={handleRowResult}
                        clinician={clinician}
                      />
                    </tr>
                  );
                }
              })}

              {clinicians.length == 0 ? <EmptyTableRow /> : null}
            </tbody>
          </table>
        </div>
      </div>

      <div>{loading && <MoreClinic />}</div>

      <AdminsClinicModal clinician={selectedClinic} clinics={props.clinics} />
    </React.Fragment>
  );
}
