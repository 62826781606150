import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

export default function AdminsSearchClinicians(query, pageNumber, clinicId) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [clinicians, setClinicians] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setClinicians([]);
  }, [query])

  useEffect(() => {
    setClinicians([]);
    setHasMore(true);
  }, [clinicId])

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    const params = {
      'q[m]': 'or',
      'q[name_cont]': query,
      'q[surname_cont]': query,
      'q[clinic_id_eq]': clinicId,
      'p': pageNumber
    }
    axios({
      method: 'GET',
      url: [API_SERVER, 'admins/clinicians.json'].join('/'),
      params: params,
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(result => {
      setClinicians(prevClinicians => {
        return [...new Set([...prevClinicians, ...result.data])]
      });

      setHasMore(result.data.length > 0);

      setLoading(false);
    }).catch(e => {
      if (e.response && e.response.status === 401) window.location = '/admins/auth/sign_out';

      if (axios.isCancel(e)) return;
      setError(true);
    })
    return () => cancel();
  }, [query, pageNumber, clinicId])

  return { loading, error, clinicians, hasMore }
}
