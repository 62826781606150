import React from 'react';
import axios from 'axios';
import flatpickr from 'flatpickr';
import { FORMAT_DATE, API_SERVER } from '../../constants';
import "./notification-modal.css";

class AdminsAnalyticsPage extends React.Component {
  currentDate = new Date(new Date().setMinutes(0));

  constructor(props) {
    super(props);

    this.startDatePicker = React.createRef();
    this.endDatePicker = React.createRef();
    this.state = {
      startDate: null,
      endDate: null,
      checkinCompleted: this.props.initial_analytics.check_in_completeds,
      assessmentPendings: this.props.initial_analytics.assessment_pendings,
      assessmentDones: this.props.initial_analytics.assessment_dones,
      appointments: this.props.initial_analytics.appointments,
      medications: this.props.initial_analytics.medications,
      feedbackComments: this.props.initial_analytics.feedback_comments,
      treeEarneds: this.props.initial_analytics.tree_earneds
    };

    this.handleStartDatePicker = this.handleStartDatePicker.bind(this);
    this.handleEndDatePicker = this.handleEndDatePicker.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount = () => {
    flatpickr(this.startDatePicker.current, {
      dateFormat: 'd/m/Y',
      onChange: this.handleStartDatePicker,
      defaultDate: null
    });

    flatpickr(this.endDatePicker.current, {
      dateFormat: 'd/m/Y',
      onChange: this.handleEndDatePicker,
      defaultDate: null
    });
  };

  handleEndDatePicker = (selectedDates, dateStr, instance) => {
    if (selectedDates?.length < 1) return;
    const date = selectedDates[0]
    this.setState({
      ...this.state,
      endDate: FORMAT_DATE(date)
    })
  }

  handleStartDatePicker = (selectedDates, dateStr, instance) => {
    if (selectedDates?.length < 1) return;
    const date = selectedDates[0]
    this.setState({
      ...this.state,
      startDate: FORMAT_DATE(date)
    })
  }

  handleClick = () => {
    let params = {};
    params['q[start_gteq]'] = this.state.startDate;
    params['q[end_lteq]'] = this.state.endDate;

    axios({
      method: 'GET',
      url: API_SERVER + '/admins/analytics',
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      params: params
    })
    .then((result) => {
      console.log(result.data.initial_analytics)
      this.setState({
        ...this.state,
        checkinCompleted: result.data.initial_analytics.check_in_completeds,
        assessmentPendings: result.data.initial_analytics.assessment_pendings,
        assessmentDones: result.data.initial_analytics.assessment_dones,
        appointments: result.data.initial_analytics.appointments,
        medications: result.data.initial_analytics.medications,
        feedbackComments: result.data.initial_analytics.feedback_comments,
        treeEarneds: result.data.initial_analytics.tree_earneds,
      })
    })
    .catch((result) => console.log(result))
  }

  render () {
    const {
      startDate,
      endDate,
      checkinCompleted,
      assessmentPendings,
      assessmentDones,
      appointments,
      medications,
      feedbackComments,
      treeEarneds
    } = this.state;
    return (
      <>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Analytics</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="card pl-3 pt-3">
          <label className="mb-4 font-weight-bold text-dark">Set Period</label>
          <div className="d-flex">
            <div className="col-4 pl-0">
              <span className="text-dark">Start Date</span>
              <input
                type="text"
                name="start_date"
                ref={this.startDatePicker}
                className="form-control" />
            </div>
            <div className="col-4 ml-8">
              <span className="text-dark">End Date</span>
              <input
                type="text"
                name="start_date"
                ref={this.endDatePicker}
                className="form-control" />
            </div>
            <div className="col-4 ml-8 display-results">
              <button type="button" className="btn btn-sm btn-primary btn-overprimary" onClick={() => this.handleClick()}>Display Results</button>
            </div>
          </div>
          <label className="font-weight-bold mt-4 mb-4 text-dark">Results</label>
          <ol className="list-result pl-3">
            <li className="mb-3">Number of check-ins completed: { checkinCompleted }</li>
            <li className="mb-3">Number of assessments sent: { assessmentPendings }</li>
            <li className="mb-3">Number of assessments completed: { assessmentDones }</li>
            <li className="mb-3">Number of appointments scheduled: { appointments }</li>
            <li className="mb-3">Number of times medications confirmed: { medications }</li>
            <li className="mb-3">Number of feedbacks comments made: { feedbackComments }</li>
            <li>Number of trees earned: { treeEarneds }</li>
          </ol>
        </div>
      </>
    )
  }
}

export default AdminsAnalyticsPage;
