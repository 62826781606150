import React, { useEffect, useState, useRef } from 'react';
import flatpickr from 'flatpickr';

const DatePicker = (props) => {
  const { defaultDate, onChange } = props;
  const datePicker = useRef();

  useEffect(() => {
    flatpickr(datePicker.current, {
      dateFormat: 'd-m-y',
      onChange: onChange,
      defaultDate: defaultDate,
    });
  },[]);

  return (
    <input ref={datePicker} type="text" className="form-control" />
  );
}

export default DatePicker;
