import React from "react";
import UpdateMedicationNav from './update-medication-nav';
import UpdateAppointmentNav from './update-appointment-nav';
import axios from 'axios';
import { API_SERVER } from '../../constants';

class AdminsClinicRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.state = {
      id: props.clinic.id,
      medication_nav: props.clinic.medication_nav,
      appointment_nav: props.clinic.appointment_nav
    };
  }

  handleChangeStatus = (event) => {
    this.setState({active: event.target.checked})

    const formData = new FormData();

    formData.append('clinic[active]', event.target.checked);

    axios({
      method: 'PUT',
      url: API_SERVER + '/admins/clinics/' + this.state.id + '.json',
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      data: formData
    })
  }

  handleEditClick = (clinic) => this.props.rowResult(clinic);

  render() {
    const clinic = this.props.clinic;

    const handleUpdateMedicationNav = (id) => {
      let data = new FormData();
      data.append('clinic[medication_nav]', this.state.medication_nav);
      UpdateMedicationNav(id, data)
        .then(res => {
        })
        .catch(error => console.log(error))
    }

    const handleUpdateAppointNav = (id) => {
      let data = new FormData();
      data.append('clinic[appointment_nav]', this.state.appointment_nav);
      UpdateAppointmentNav(id, data)
        .then(res => {
        })
        .catch(error => console.log(error))
    }

    return (
      <React.Fragment>
        <th scope="row">
          <div className="media align-items-center">
            <div>
              <div className="avatar-parent-child">
                <img
                  alt="Image placeholder"
                  src={clinic.logo}
                  className="avatar rounded-circle"
                />
                <span className="avatar-child avatar-badge bg-success"></span>
              </div>
            </div>
            <div className="media-body ml-4">
              <a href="#modal-clinic-create"
                onClick={() => this.handleEditClick(clinic)}
                className="name h6 mb-0 text-sm"
                data-toggle="modal"
              >
                {clinic.name}
              </a>
            </div>
          </div>
        </th>
        <td>
          <a href="#" className="name h6 mb-0 text-sm">
            {clinic.contact_name} {clinic.contact_surname}
          </a>
        </td>
        <td>
          <a
            href={"tel:" + clinic.phone}
            className="d-block action-item"
            data-toggle="tooltip"
            title="Clinic Phone"
          >
            <i className="fas fa-clinic-medical"></i> {clinic.phone}
          </a>
          <a
            href={"tel:" + clinic.it_department_phone}
            className="d-block action-item"
            data-toggle="tooltip"
            title="Clinic IT Department Phone"
          >
            <i className="fas fa-cogs"></i> {clinic.it_department_phone}
          </a>
          <a
            href={"tel:" + clinic.finance_department_phone}
            className="d-block action-item"
            data-toggle="tooltip"
            title="Clinic Finance Department Phone"
          >
            <i className="fas fa-money-bill-alt"></i>{" "}
            {clinic.finance_department_phone}
          </a>
          <a
            href={"tel:" + clinic.legal_department_phone}
            className="d-block action-item"
            data-toggle="tooltip"
            title="Clinic Legal Department Phone"
          >
            <i className="fas fa-balance-scale"></i>{" "}
            {clinic.legal_department_phone}
          </a>
        </td>
        <td>
          <a href={"mailto:" + clinic.email} className="action-item">
            {clinic.email}
          </a>
        </td>
        <td>
          <a href="{clinic.website}" target="_blank">
            {clinic.website}
          </a>
        </td>
        <td>{clinic.address}</td>
        <td>Annual - Medication Module</td>
        <td>{clinic.subscribed_at}</td>
        <td>
          <div className="custom-control custom-switch">
            <input
              defaultChecked={clinic.medication_nav}
              onChange={() => handleUpdateMedicationNav(clinic.id)}
              type="checkbox"
              className="custom-control-input"
              id={"show-medication-nav-" + clinic.id}

            />
            <label
              onClick={() => this.setState({medication_nav: !this.state.medication_nav})}
              className="custom-control-label"
              htmlFor={"show-medication-nav-" + clinic.id}
              data-active="Show"
              data-inactive="Hide"
            >
              Show
            </label>
          </div>
        </td>
        <td>
          { clinic.appointment_nav }
          <div className="custom-control custom-switch">
            <input
              defaultChecked={clinic.appointment_nav}
              onChange={() => handleUpdateAppointNav(clinic.id)}
              type="checkbox"
              className="custom-control-input"
              id={"show-appointment-nav-" + clinic.id}

            />
            <label
              onClick={() => this.setState({appointment_nav: !this.state.appointment_nav})}
              className="custom-control-label"
              htmlFor={"show-appointment-nav-" + clinic.id}
              data-active="Show"
              data-inactive="Hide"
            >
              Show
            </label>
          </div>
        </td>
        <td>
          <div className="custom-control custom-switch">
            <input
              onChange={this.handleChangeStatus}
              type="checkbox"
              className="custom-control-input"
              id={"clinic-" + clinic.id}
              defaultChecked={clinic.active}
            />
            <label
              className="custom-control-label"
              htmlFor={"clinic-" + clinic.id}
              data-active="Active"
              data-inactive="Inactive"
            ></label>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <a
              onClick={() => this.handleEditClick(clinic)}
              className="action-item mr-2"
              data-toggle="modal"
              data-target="#modal-clinic-create"
            >
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
        </td>
      </React.Fragment>
    );
  }
}

export default AdminsClinicRow;
