import React from 'react';

const CorrespondenceRow = (props) => {
  const {label, description, document_url, created_by, created_at, handleEdit} = props;

  return (
    <>
      <th scope="row">
        <div className="media align-items-center mr-3">
          <img alt="Image placeholder" src="/themes/wellifiy/img/icons/files/pdf.png" className="img-fluid col-auto px-0" style={{width: '40px'}} />
          <div className="ml-2">
            <h6 className="text-sm mb-0">
              <a href={document_url} target="_blank">{label}</a>
            </h6>
          </div>
        </div>
      </th>
      <td className="text-wrap">
        <p className="mb-0" style={{minWidth: '250px'}}>{description}</p>
      </td>
      <td>
        <div className="card-meta d-inline-flex align-items-center ml-sm-auto">
          <div className="media media-pill align-items-center d-inline-flex bg-secondary">
            <div className="ml-2">
              <small>{[created_by?.name, created_by?.surname].join(' ')}</small>
            </div>
          </div>
        </div>
      </td>
      <td>
        <p className="mt-1 pt-2">
          {new Date(created_at).toLocaleDateString()}
        </p>
      </td>
      <td className="text-right">
        <div className="actions ml-3 pt-2">
          <a href="#modal-patient-correspondence-upload" onClick={handleEdit} className="action-item mr-2" data-toggle="modal">
            <i className="fas fa-pencil-alt"></i>
          </a>
        </div>
      </td>
    </>
  )
}

export default CorrespondenceRow;
