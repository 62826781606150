import React from 'react';

const InfoRow = (props) => {
  const {index, label, description, pdfUrl, handleDelete, handleEdit} = props;

  return (
    <>
      <td>{index}</td>
      <td scope="row">
        <h6 className="text-sm mb-0">{label}</h6>
      </td>
      <td className="text-wrap">
        <p className="mb-0" style={{ minWidth: '250px' }}>{description}</p>
      </td>
      <td className="text-center">
        <a href={pdfUrl} target="_blank">
          <img alt="Image placeholder" src="/themes/wellifiy/img/icons/files/pdf.png" className="img-fluid" style={{ width: '40px' }} />
        </a>
      </td>
      <td className="text-right">
        <div className="actions">
          <a href="#modal-clinic-info-create" onClick={handleEdit} className="action-item mr-2" data-toggle="modal">
            <i className="fas fa-pencil-alt"></i>
          </a>
          <a href="#confirm-modal" onClick={handleDelete} className="action-item text-danger mr-2" data-toggle="modal">
            <i className="fas fa-trash"></i>
          </a>
        </div>
      </td>
    </>
  )
}

export default InfoRow;
