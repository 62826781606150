import React from 'react';
import CliniciansPatientsGridPage from './patients-grid-page';
import CliniciansPatientsListPage from './patients-list-page';

class CliniciansPatientsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: this.props.mode,
      patientCount: this.props.patient_count,
      isAdminClinician: this.props.is_admin_clinician
    }
  }

  render () {
    const mode = this.state.mode,
      patientCount = this.state.patientCount,
      isAdminClinician = this.state.isAdminClinician,
      isGrid = mode === 'grid',
      viewPage = isGrid ? <CliniciansPatientsGridPage patientCount={patientCount} isAdminClinician={isAdminClinician} /> : <CliniciansPatientsListPage patientCount={patientCount} isAdminClinician={isAdminClinician} />;

    return viewPage;
  }
}

export default CliniciansPatientsPage;