import React from 'react';

const ConfirmModal = (props) => {
  const {message, onSubmit} = props;

  return (
    <div id="confirm-modal" className="modal fade" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body" style={{textAlign: 'center'}}>
            <h5>{message}</h5>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm mr-auto btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
            <button onClick={onSubmit} type="button" className="btn btn-sm btn-primary btn-overprimary rounded-pill" data-dismiss="modal">Ok</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal;
