import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../../constants';

class ClinicianGoalModal extends React.Component {
  state = {
    isLoading: false,
    description: '',
    patient_id: this.props.patient_id,
    point: ''
  };

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      patient_id,
      description,
      point
    } = this.state;

    const goalModal = window.$('#modal-goal');

    const method = 'POST';
    const url = API_SERVER + '/clinicians/goals.json';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('goal[patient_id]', patient_id);
    formData.append('goal[description]', description);
    formData.append('goal[point]', point);

    axios({
      method: method,
      url: url,
      headers: headers,
      data: formData
    })
    .then((response) => {
      this.props.result()
      goalModal.modal('hide');
      this.setState({isLoading: false});
    })
    .catch(() => {
      goalModal.modal('hide');
      this.setState({isLoading: false});
    })
  }

  render () {
    const {
      isLoading,
      description,
      point
    } = this.state,
      descriptionValid = description.length === 0,
      pointValid = point.length === 0,
      formInvalid = descriptionValid || pointValid,
      descriptionClassName = descriptionValid ? 'form-control is-invalid' : 'form-control',
      pointClassName = pointValid ? 'form-control is-invalid' : 'form-control',
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    
    return (
      <div className="modal fade" id="modal-goal" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <label className="mt-4 text-center">Add New Goal</label>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="modal-body">
                <div className="d-flex">
                  <div className="form-group col-md-6">
                    <label className="form-control-label">Goal:</label>
                    <input
                      type="text"
                      name="description"
                      onChange={this.handleChange}
                      value={description}
                      className={descriptionClassName} />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">Points:</label>
                    <input
                      type="number"
                      name="point"
                      onChange={this.handleChange}
                      value={point}
                      className={pointClassName} />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  Save&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianGoalModal;
