import React from 'react';
import flatpickr from 'flatpickr';
import select2 from 'select2';
import axios from 'axios';
import { API_SERVER, FORMAT_DATE } from '../../constants';
import './appointment.css';

const initialState = {
  isLoading: false,
  id: null,
  clinician_id: '',
  clinician_full_name: '',
  patient_id: '',
  patient_surname: '',
  patient_full_name: '',
  title: '',
  location: '',
  date: '',
  start: '',
  stop: '',
  send_sms: false
}

class ClinicianAppointmentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState;

    this.clinicianPicker = React.createRef();
    this.selectPicker = React.createRef();
    this.datePicker = React.createRef();
    this.startTimePicker = React.createRef();
    this.stopTimePicker = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleStartPicker = this.handleStartPicker.bind(this);
    this.handleStopPicker = this.handleStopPicker.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      clinician_id,
      patient_id,
      title,
      location,
      date,
      start,
      stop,
      send_sms
    } = this.state;

    const method = id ? 'PUT' : 'POST';
    const url = id ? (API_SERVER + '/clinicians/appointments/' + id + '.json') : (API_SERVER + '/clinicians/appointments.json');
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: method,
      url: url,
      headers: headers,
      data: {appointment: {
        clinician_id: clinician_id,
        patient_id: patient_id,
        title: title,
        location: location,
        date: date,
        start: start,
        stop: stop,
        send_sms: send_sms
      }}
    })
    .then((response) => {
      const data = response.data;

      let result = { data: data };

      if (id) {
        result.update = true;
        result.create = false;
      } else {
        result.create = true;
        result.update = false;
      }

      this.props.resultCallback(result);
      window.$('#new-event').modal('hide');
    })
    .catch(() => {
      window.$('#new-event').modal('hide');
    })
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleDelete = (event_id) => {
    this.setState({isLoading: true});

    axios({
      method: 'DELETE',
      url: API_SERVER + '/clinicians/appointments/' + event_id + '.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth'))
    })
    .then((response) => {
      const result = {delete: true, data: response.data};
      this.props.resultCallback(result);

      window.$('#new-event').modal('hide');
    })
    .catch(() => {
      window.$('#new-event').modal('hide');
    })
  };

  handleDatePicker = (selectedDates, dateStr, instance) => {
    const date = new Date(dateStr),
      day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear();

    this.setState({date: dateStr})

    this.state.start.setDate(day);
    this.state.start.setMonth(month);
    this.state.start.setFullYear(year);
    this.state.stop.setDate(day);
    this.state.stop.setMonth(month);
    this.state.stop.setFullYear(year);
  }

  handleStartPicker = (selectedDates, dateStr, instance) => {
    const time = dateStr.split(':');
    const start = this.state.date.setHours(time[0], time[1], 0, 0);
    const startDate = new Date(start);

    this.setState({start: startDate})
  };

  handleStopPicker = (selectedDates, dateStr, instance) => {
    const time = dateStr.split(':');
    const stop = this.state.date.setHours(time[0], time[1], 0, 0);
    const stopDate = new Date(stop);

    this.setState({stop: stopDate})
  };

  componentDidMount = () => {
    const eventModal = window.$('#new-event');
    const clinicianPicker = window.$(this.clinicianPicker.current);
    const patientPicker = window.$(this.selectPicker.current);

    eventModal.on('show.bs.modal', () => {
      this.setState({isLoading: false});

      const selectedInfo = this.props.selectedInfo;
      const extendedProps = selectedInfo.extendedProps; // its from edit appointment

      let selectedEvent = {
        id: undefined,
        date: selectedInfo.start,
        start: selectedInfo.start,
        stop: selectedInfo.end,
        title: '',
        location: '',
        clinician_id: selectedInfo.clinician_id,
        clinician_full_name: selectedInfo.clinician_full_name,
        patient_id: selectedInfo.patient_id,
        patient_surname: selectedInfo.patient_surname,
        patient_full_name: selectedInfo.patient_full_name
      }

      // edit will contains this info
      if (extendedProps) {
        selectedEvent.id = selectedInfo.id;
        selectedEvent.title = selectedInfo.title;
        selectedEvent.location = extendedProps.location;
        selectedEvent.stop = new Date(extendedProps.stop);
        selectedEvent.patient_id = extendedProps.patient_id;
        selectedEvent.patient_surname = extendedProps.patient.surname;
        selectedEvent.patient_full_name = extendedProps.patient.full_name;
        selectedEvent.clinician_id = extendedProps.clinician.id;
        selectedEvent.clinician_full_name = extendedProps.clinician.full_name;
      }

      this.setState(selectedEvent);

      clinicianPicker.select2({
        ajax: {
          url: API_SERVER + '/clinicians/friends.json',
          type: 'GET',
          headers: JSON.parse(localStorage.getItem('clinician_auth')),
          data: (params) => {
            return {
              'q[surname_cont]': params.term,
              p: params.page
            }
          },
          processResults: function (data, params) {
            params.page = params.page || 1;

            return {
              results: data.map((row) => { return { id: row.id, text: row.full_name } }).sort(),
              pagination: {
                more: params.page === 12
              }
            };
          }
        }
      })
      .on('select2:select', (e) => this.setState({
        clinician_id: e.params.data.id,
        clinician_full_name: e.params.data.text
      }));

      if (this.state.clinician_id > 0 && this.state.clinician_full_name?.length > 0) {
        const options = new Option(this.state.clinician_full_name, this.state.clinician_id, false, false)

        clinicianPicker.append(options).val(this.state.clinician_id).trigger('change');
      } else {
        clinicianPicker.val('').trigger('change');
      }

      patientPicker.select2({
        ajax: {
          url: API_SERVER + '/clinicians/patients.json',
          type: 'GET',
          headers: JSON.parse(localStorage.getItem('clinician_auth')),
          data: (params) => {
            return {
              'q[surname_cont]': params.term,
              p: params.page
            }
          },
          processResults: function (data, params) {
            params.page = params.page || 1;

            return {
              results: data.map((row) => { return { id: row.id, text: row.full_name } }).sort(),
              pagination: {
                more: params.page === 12
              }
            };
          }
        }
      })
      .on('select2:select', (e) => this.setState({
        patient_id: e.params.data.id,
        patient_full_name: e.params.data.text
      }));

      if (this.state.patient_id > 0 && this.state.patient_full_name?.length > 0) {
        const options = new Option(this.state.patient_full_name, this.state.patient_id, false, false)

        patientPicker.append(options).val(this.state.patient_id).trigger('change');
      } else {
        patientPicker.val('').trigger('change');
      }

      flatpickr(this.datePicker.current, {
        dateFormat: 'd/m/Y',
        onChange: this.handleDatePicker,
        defaultDate: FORMAT_DATE(this.state.date)
      });

      flatpickr(this.startTimePicker.current, {
        onChange: this.handleStartPicker,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        defaultDate: this.state.start,
        static: true
      });

      flatpickr(this.stopTimePicker.current, {
        onChange: this.handleStopPicker,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        defaultDate: this.state.stop,
        static: true
      });
    })

  }

  render () {
    const {
      id,
      isLoading,
      patient_id,
      title,
      location,
      send_sms
    } = this.state,
      patientInvalid = patient_id?.length === 0,
      titleInvalid = title?.length === 0,
      locationInvalid = location?.length === 0,
      titleClassName = titleInvalid ? 'form-control is-invalid' : 'form-control',
      locatoinClassName = locationInvalid ? 'form-control is-invalid' : 'form-control',
      formInvalid = patientInvalid || titleInvalid || locationInvalid,
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <div className="modal fade" id="new-event" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form className="new-event--form" onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label">Worker</label>
                  <select
                    name="clinician_id"
                    ref={this.clinicianPicker}
                    className="form-control"
                    data-placeholder="Select clinician"
                  />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Participant</label>
                  <select
                    name="patient_id"
                    ref={this.selectPicker}
                    className="form-control"
                    data-placeholder="Select the attendee">
                  </select>
                </div>
                <div className="form-group">
                  <label className="form-control-label">Appointment Title</label>
                  <input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={title}
                    className={titleClassName}
                    placeholder="Appointment Title" />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Appointment Location</label>
                  <input
                    type="text"
                    name="location"
                    onChange={this.handleChange}
                    value={location}
                    className={locatoinClassName}
                    placeholder="Appointment Location" />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Appointment Date</label>
                  <input
                    type="text"
                    name="date"
                    ref={this.datePicker}
                    className="form-control"
                    placeholder="Appointment Date" />
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Start at</label>
                      <input
                        type="text"
                        name="start"
                        ref={this.startTimePicker}
                        className="form-control"
                        placeholder="hh:mm" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">End at</label>
                      <input
                        type="text"
                        name="stop"
                        ref={this.stopTimePicker}
                        className="form-control"
                        placeholder="hh:mm" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {id && <button type="button" onClick={() => this.handleDelete(id)} className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete</button>}
                <button type="button" className="btn btn-sm btn-link" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  {id ? "Update event" : "Create event"}&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianAppointmentModal;