import React from 'react';
import { connect } from 'react-redux';
import MoodChart from './charts/mood';
import SleepHourChart from './charts/sleep_hour';
import { FORMAT_DATE } from '../../constants';

class ClinicianPatientRow extends React.Component {
  handleEditClick = (selectedPatient) => {
    const patient = {
      id: selectedPatient.id,
      mrn: selectedPatient.mrn,
      name: selectedPatient.name,
      surname: selectedPatient.surname,
      date_of_birth: selectedPatient.date_of_birth || '',
      country_code: selectedPatient.country_code || 'AU',
      mobile_phone: selectedPatient.mobile_phone || '',
      email: selectedPatient.email || '',
      avatar: selectedPatient.avatar || undefined
    }

    this.props.dispatch({type: 'EDIT_PATIENT', selectedPatient: patient});
  }

  render() {
    const patient = this.props.patient

    return (
      <React.Fragment>
        <th scope="row">
          <div className="media align-items-center">
            <div>
              <div className="avatar-parent-child">
                <img alt="Image placeholder" src={patient.avatar} className="avatar rounded-circle" />
              </div>
            </div>
            <div className="media-body ml-4">
              <a href={'/clinicians/patients/' + patient.id} className="name h6 mb-0 text-sm">{patient.name} {patient.surname}</a>
              <small className="d-block font-weight-bold">{patient.mrn}</small>
            </div>
          </div>
        </th>
        <td>
          {patient.date_of_birth && FORMAT_DATE(patient.date_of_birth)}
        </td>
        <td>
          <a href={'tel:' + patient.mobile_phone} className="action-item mr-2">
            {patient.mobile_phone}
          </a>
        </td>
        <td>
          <div style={{maxWidth: '100px'}}>
            <MoodChart patient={patient} />
          </div>
        </td>
        <td>
          <div style={{maxWidth: '100px'}}>
            <SleepHourChart patient={patient} />
          </div>
        </td>
        <td>
          {(new Date(patient.created_at)).toLocaleString()}
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <a href="#" onClick={() => this.handleEditClick(patient)} className="action-item mr-2" data-toggle="modal" data-target="#modal-patient-create">
              <i className="fas fa-pencil-alt" />
            </a>
          </div>
        </td>
      </ React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedPatient: state.selectedPatient
})

export default connect(mapStateToProps)(ClinicianPatientRow);
