import axios from 'axios';
import { API_SERVER } from '../../../constants';

export default function (id) {
  return axios({
    method: 'GET',
    url: `${API_SERVER}/clinicians/assessments/${id}/export_pdf.pdf`,
    headers: JSON.parse(localStorage.getItem('clinician_auth')),
    responseType: "blob",
  });
}
