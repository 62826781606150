import React from "react";

import AdminsCliniciansListPage from "./clinicians-list-page";

class AdminsCliniciansPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clinicianCount: this.props.clinician_count,
      clinics: this.props.clinics,
    };
  }

  render() {
    const clinicianCount = this.state.clinicianCount,
      clinics = this.state.clinics,
      viewPage = (
        <AdminsCliniciansListPage
          clinicianCount={clinicianCount}
          clinics={clinics}
        />
      );

    return viewPage;
  }
}

export default AdminsCliniciansPage;
