import React, { useState,useEffect } from 'react';
import { API_SERVER, VALID_PHONE } from '../../constants';
import axios from 'axios';

const CrisisServiceForm = (props) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [hasError, setHasError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.crisisService) {
      const crisisService = props.crisisService;
      setId(crisisService.id);
      setName(crisisService.name);
      setPhone(crisisService.phone);
    } else {
      setId('');
      setName('');
      setPhone('');
    }
  }, [props.crisisService]);

  useEffect(() => {
    (!name || !phone) ? setHasError(true) : setHasError(false);
  }, [name, phone]);

  const handleSubmit = () => {
    event.preventDefault();
    setIsLoading(true);

    const url = id ? `${API_SERVER}/clinicians/crisis_services/${id}.json` : `${API_SERVER}/clinicians/crisis_services.json`;
    const method = id ? 'PUT' : 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    let formData = new FormData();
    formData.append('crisis_service[name]', name);
    formData.append('crisis_service[phone]', phone);

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  const validPhone = phone && VALID_PHONE.test(phone);

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <label className="form-control-label">Name of Service</label>
          <input
            value={name}
            name="name"
            onChange={() => setName(event.target.value)}
            type="text"
            className={`form-control ${name ? 'is-valid' : 'is-invalid'}`} />
          <div className="invalid-feedback">Please provide a valid name.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Phone Number</label>
          <input
            value={phone}
            name="phone"
            onChange={() => setPhone(event.target.value)}
            type="text"
            className={`form-control ${validPhone ? 'is-valid' : 'is-invalid'}`} />
          <div className="invalid-feedback">Please provide a valid phone.</div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={hasError && !validPhone}>
          <span className="btn-inner--text">Save&nbsp;</span>
          <span className="btn-inner--icon"><i className={isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right'} /></span>
        </button>
      </div>
    </form>
  )

}

export default CrisisServiceForm;
