import React, { useState, useRef, useCallback } from 'react'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import ClinciansSearchPatients from './search-patients';
import ClinicianPatientRow from './patient-row';
import MorePatient from './more-patient';
import ClinicianPatientModal from './patient-modal';

const initialPatient = {
  id: undefined,
  mrn: '',
  name: '',
  surname: '',
  date_of_birth: '',
  program: 0,
  country_code: 'AU',
  mobile_phone: '',
  email: '',
  password: '',
  password_confirmation: '',
  avatar: undefined
}

const initialState = {
  selectedPatient: null
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case 'NEW_PATIENT':
      return {
        selectedPatient: action.selectedPatient
      }
    case 'EDIT_PATIENT':
      return {
        selectedPatient: action.selectedPatient
      }
    default:
      return state;
  }
}

const store = createStore(reducer);

const EmptyPatient = () => {
  return (
    <tr>
      <td colSpan="7">
        <p className="text-muted">Empty participants</p>
      </td>
    </tr>
  )
}

export default function CliniciansPatientsListPage(props) {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const {
    patients,
    hasMore,
    loading,
    error
  } = ClinciansSearchPatients(query, pageNumber)

  const observer = useRef()
  const lastPatientElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore])

  function handleSearch(e) {
    setQuery(e.target.value)
    setPageNumber(1)
  }

  function handleNewPatient() {
    store.dispatch({type: 'NEW_PATIENT', selectedPatient: initialPatient});
  }

  return (
    <Provider store={store}>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Participants</h5>
            </div>
            <a href="/clinicians/patients" className="text-sm text-info d-none d-lg-inline-block ml-4">Grid view</a>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a href="#modal-patient-create" onClick={handleNewPatient} className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4" data-toggle="modal">
              <span className="btn-inner--icon"><i className="fas fa-plus" /></span>
            </a>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header actions-toolbar border-0">
          <div className="actions-search" id="actions-search">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent"><i className="fas fa-search" /></span>
              </div>
              <input type="text" value={query} onChange={handleSearch} className="form-control form-control-flush" placeholder="Type and hit enter ..." />
              <div className="input-group-append">
                <a href="#" className="input-group-text bg-transparent" data-action="search-close" data-target="#actions-search"><i className="fas fa-times" /></a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Patients</h6>
            </div>
            <div className="col text-right">
              <div className="actions"><a href="#" className="action-item mr-3" data-action="search-open" data-target="#actions-search"><i className="fas fa-search" /></a>
                <div className="dropdown">
                  <a href="#" className="action-item" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-filter" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-sort-amount-down" />Newest
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-sort-alpha-down" />From A-Z
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-sort-alpha-up" />From Z-A
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">DOB</th>
                <th scope="col">Mobile phone</th>
                <th scope="col">Mood Tracking</th>
                <th scope="col">Sleep hours Tracking</th>
                <th scope="col">Created at</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {patients.map((patient, index) => {
                if (patients.length === index + 1) {
                  return (
                    <tr key={patient.id} ref={lastPatientElementRef}><ClinicianPatientRow patient={patient} /></tr>
                  )
                } else {
                  return (
                    <tr key={patient.id}><ClinicianPatientRow patient={patient} /></tr>
                  )
                }
              })}

              {patients.length == 0 && !loading && <EmptyPatient />}
            </tbody>
          </table>
        </div>
      </div>

      <div>{loading && <MorePatient />}</div>
      <div>{error && 'Error'}</div>
      <ClinicianPatientModal store={store} />
    </Provider>
  )
}
