import React from 'react';
import CorrespondenceForm from './form';

const CorrespondenceModal = (props) => {
  const {patient, correspondence, onDelete} = props;

  return (
    <div className="modal fade" id="modal-patient-correspondence-upload" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <CorrespondenceForm patient={patient} correspondence={correspondence} onDelete={onDelete}/>
        </div>
      </div>
    </div>
  )
}

export default CorrespondenceModal;
