import React from 'react';
import '../health-wellbeing/health-wellbeing.scss';
import { API_SERVER } from "../../../constants";
import axios from 'axios';

class Mindfulness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabledMindfulnesses: [],
      isLoading: false,
      patient_id: this.props.patient ? this.props.patient.id : 0,
      healthWellbeings: [],
      mindfulnesses: [],
    }
    this.toggleList = this.toggleList.bind(this);
    this.mindfulnessCheck = this.mindfulnessCheck.bind(this);
    this.onChangeCheckBoxMindfulness = this.onChangeCheckBoxMindfulness.bind(this);
    this.handleSaveMindfulnesses = this.handleSaveMindfulnesses.bind(this);
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchMindfulnesses = () => {
    axios({
      method : 'GET',
      url : API_SERVER + '/mindfulnesses/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      console.log(res.data)
      this.setState({
        ...this.state,
        mindfulnesses : res.data,
      });
    }).catch((error) => {
      console.log("Mindfulnesses fetch error", error);
    });
  }

  fetchUserMindfulnesses = () => {
    const params = {'q[patient_id_eq]': this.state.patient_id};
    axios({
      method : 'GET',
      url : API_SERVER + '/clinicians/user_mindfulnesses/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
      params : params
    }).then((res) => {
      console.log("response user mindfulnesses", res.data);
      if (res.data[0] && res.data[0].mindfulnesses && JSON.parse(res.data[0].mindfulnesses)) {
        console.log(JSON.parse(res.data[0].mindfulnesses))
        this.setState({
          ...this.state,
          enabledMindfulnesses: JSON.parse(res.data[0].mindfulnesses)
        });
      }
    }).catch((error) => {
      console.log("user mindfulnesses fetch error", error);
    });
  }

  fetchData = async () => {
    await this.fetchMindfulnesses();
    await this.fetchUserMindfulnesses();
  }

  setEnabledMindfulnesses = (mindfulnesses) => {
    this.setState({
      ...this.state,
      enabledMindfulnesses : mindfulnesses,
    });
  }

  onChangeCheckBoxMindfulness = (e, mindfulnessMobileDataId) => {
    const mindfulnesses = this.state.mindfulnesses.find(item => item.mobile_data_id === mindfulnessMobileDataId);
    if (!mindfulnesses) return
    let tempEnabledMindfulnessIds = [...this.state.enabledMindfulnesses];
    if(e.target.checked) {
      tempEnabledMindfulnessIds = [...new Set(tempEnabledMindfulnessIds.concat(mindfulnesses.mobile_data_id))];
    } else {
      tempEnabledMindfulnessIds = tempEnabledMindfulnessIds.filter(x => mindfulnesses.mobile_data_id !== x);
    }
    console.log(tempEnabledMindfulnessIds);
    this.setEnabledMindfulnesses(tempEnabledMindfulnessIds);
  }

  toggleList = (index) => {
    this.state.healthWellbeings[index].is_open_list = !this.state.healthWellbeings[index].is_open_list
    this.setState({...this.state, healthWellbeings : this.state.healthWellbeings});
    if(this.state.healthWellbeings.every(item => item.is_open_list === false)) {
      this.setState({ ...this.state, isExpandAll: true })
    }
  }

  mindfulnessCheck = (mindfulness_mobile_data_id) => {
    const mindfulness = this.state.mindfulnesses.find(item => item.mobile_data_id === mindfulness_mobile_data_id)
    return this.state.enabledMindfulnesses.includes(mindfulness.id)
  }

  handleSaveMindfulnesses = () => {
    this.setState({...this.state, isLoading : true});

    const request = {
      "clinician_id": this.state.clinician_id,
      "patient_id": this.state.patient_id,
      "mindfulnesses": JSON.stringify(this.state.enabledMindfulnesses),
    }
    axios({
      method : 'POST',
      url : API_SERVER + '/clinicians/user_mindfulnesses/',
      data : request,
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      console.log(res.data.mindfulnesses);
      this.setState({...this.state, enabledMindfulnesses: JSON.parse(res.data.mindfulnesses), isLoading: false});
    }).catch((error) => {
      console.log(error);
      this.setState({...this.state, isLoading: false});
    });
  }

  render () {
    const { enabledMindfulnesses, isLoading, mindfulnesses } = this.state;
    const loadingClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    return (
      <>
        <div className="card card-container mt-6">
          <p className="organisationName" >Mindfulness Modules</p>
          <div className="organisations">
            {
              mindfulnesses.map((mindfulness, i) => {
                return (
                  <div className="organisation" key={i}>
                    <div className="organisation-header">
                      <span className="organisation-header-name">{mindfulness.title}</span>
                      <div className="organisation-header-action">
                        <input type="checkbox" className="organisation-header-action-checkbox"
                          checked={ enabledMindfulnesses && this.mindfulnessCheck(mindfulness.mobile_data_id) }
                          value={mindfulness.mobile_data_id}
                          onChange={(e) => this.onChangeCheckBoxMindfulness(e, mindfulness.mobile_data_id)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className="mt-4 mr-4 flex justify-end">
            <button onClick={this.handleSaveMindfulnesses} className="btn btn-sm btn-primary btn-overprimary">Save&nbsp;
              <span className="btn-inner--icon"><i className={loadingClassName} /></span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Mindfulness;