import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

const CliniciansSearchFriends = (query, pageNumber) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [clinicians, setClinicians] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setClinicians([]);
  }, [query])

  useEffect(() => {
    setLoading(true);
    setError(false);

    let cancel;
    const params = {
      'q[name_cont]': query,
      'p': pageNumber
    };

    axios({
      method: 'GET',
      url: `${API_SERVER}/clinicians/friends.json`,
      params: params,
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(result => {
      setClinicians(prevClinicians => {
        return [...new Set([...prevClinicians, ...result.data])]
      });

      setHasMore(result.data.length > 0);

      setLoading(false);
    }).catch(e => {
      if (e.response && e.response.status === 401) window.location = '/clinicians/auth/sign_out';

      if (axios.isCancel(e)) return;
      setError(true);
    })
    return () => cancel();
  }, [query, pageNumber])

  return { loading, error, clinicians, hasMore }
}

export default CliniciansSearchFriends;