import React from 'react';
import './safety-plan.css';
import axios from "axios";
import moment from "moment";
import {API_SERVER} from "../../../constants";


class SafetyPlanPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      commencedDate: '12/01/21',
      lastEditDate: '12/05/21',
      safety_plan: {},
      user_safety_plans: {},
    }
  }

  fetchData = async () => {
    const spResp = await axios({
      method: 'GET',
      url: API_SERVER + '/safety_plans/',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
    })
    const safety_plan = spResp?.data?.safety_plan ?? {}
    const uspResp = await axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/user_safety_plans/',
      params: {'q[patient_id_eq]': this.props.patient.id},
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
    })
    const user_safety_plan = Array.isArray(uspResp?.data) && uspResp?.data?.length > 0 ? uspResp?.data[0] : {}
    let user_safety_plans = {}
    const now = new Date()
    let commencedDate = now;// created_at
    let lastEditDate = now;// updated_at
    try {
      if (user_safety_plan.created_at) commencedDate = new Date(user_safety_plan.created_at)
      if (user_safety_plan.updated_at) lastEditDate = new Date(user_safety_plan.updated_at)

      user_safety_plans = JSON.parse(user_safety_plan.data || '{}')
    } catch (e) {
      console.log(116, e)
    }
    commencedDate = moment(commencedDate).format('DD/MM/YY');
    lastEditDate = moment(lastEditDate).format('DD/MM/YY');
    this.setState({safety_plan, user_safety_plans, commencedDate, lastEditDate})
  }

  componentDidMount() {
    this.fetchData().then(() => null)
  }

  render() {
    const {safety_plan, user_safety_plans} = this.state;
    return (
      <>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div
              className="col col-lg-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Safety Plan</h5>
              </div>
            </div>
            <div className="col-auto col-lg-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler"
                 data-action="sidenav-pin" data-target="#sidenav-main">
                <i className="fas fa-bars"/>
              </a>
            </div>
          </div>
        </div>
        <div className="card card-container mt-6">
          <div className="date-container col text-dark font-weight-600 mt-4">
            <div>
              <span>Date commenced: {this.state.commencedDate}</span>
            </div>
            <div>
              <span>Last edited on: {this.state.lastEditDate}</span>
            </div>
          </div>
          <div className="text-dark">
            {
              safety_plan?.keys && Object.keys(safety_plan.keys).map((item) => {
                return (
                  <div key={item}>
                    <div className="content-container font-weight-600 font-size-md">
                      <span>{safety_plan?.keys[item].title}: </span>
                    </div>
                    <div className="respond-container font-weight-400 font-size-sm grey-background">
                      {user_safety_plans.hasOwnProperty(item) && user_safety_plans[item].map((val_item, val_index) => (
                        <div key={val_index}>
                          <span>{val_item}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </>
    );
  }
}

export default SafetyPlanPage;
