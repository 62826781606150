import React, { useState, useEffect } from 'react';
import { API_SERVER, VIDEO_STATUSES } from '../../constants';
import axios from 'axios';

const VideoForm = (props) => {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [file, setFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [status, setStatus] = useState('');
  const [presenter_name, setPresenterName] = useState('');
  const [youtube_link, setYoutubeLink] = useState('');
  const [hasError, setHasError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.video) {
      const video = props.video;
      setId(video.id);
      setTitle(video.title);
      setFile(video.file);
      setThumbnail(video.thumbnail);
      setStatus(video.status);
      setPresenterName(video.presenter_name);
      setYoutubeLink(video.youtube_link);
    } else {
      setId('');
      setTitle('');
      setFile('');
      setThumbnail('');
      setStatus('public');
      setPresenterName('');
      setYoutubeLink('');
    }
  }, [props.video]);

  useEffect(() => {
    (!title || !file) ? setHasError(true) : setHasError(false);
  }, [title, file]);

  const handleSubmit = () => {
    event.preventDefault();
    setIsLoading(true);

    const url = id ? `${API_SERVER}/clinicians/videos/${id}.json` : `${API_SERVER}/clinicians/videos.json`;
    const method = id ? 'PUT' : 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    let formData = new FormData();
    formData.append('video[title]', title);
    formData.append('video[file]', file);
    formData.append('video[status]', status);
    formData.append('video[thumbnail]', thumbnail);
    formData.append('video[presenter_name]', presenter_name);
    formData.append('video[youtube_link]', youtube_link);

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <label className="form-control-label">Title</label>
          <input
            value={title}
            name="title"
            onChange={() => setTitle(event.target.value)}
            type="text"
            className="form-control"/>
        </div>
        <div className="form-group">
          <label className="form-control-label">Sub-heading</label>
          <input
            value={presenter_name}
            name="presenter_name"
            onChange={() => setPresenterName(event.target.value)}
            type="text"
            className="form-control"/>
        </div>
        <div className="form-group">
          <label className="form-control-label">Youtube link</label>
          <input
            value={youtube_link}
            name="youtube_link"
            onChange={() => setYoutubeLink(event.target.value)}
            type="text"
            placeholder="Paste link"
            className="form-control"/>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" >
          <span className="btn-inner--text">Save&nbsp;</span>
          <span className="btn-inner--icon"><i className={isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right'} /></span>
        </button>
      </div>
    </form>
  )
}

export default VideoForm;
