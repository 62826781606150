import React from 'react';
import select2 from 'select2';
import axios from 'axios';
import {
  API_SERVER,
  INVALID_DATE,
  VALID_EMAIL,
  VALID_PASSWORD,
  VALID_PHONE,
  PROGRAMS,
  ACTIVE_STATUSES
} from '../../../constants';
import { COUNTRY_CODES } from '../../../country-codes';

const ALERT_MESSAGE = (props) => {
  const type = props.type || 'success',
    className = 'alert alert-' + type;

  return <div className={className} role="alert">{props.content}</div>
}

class CliniciansPatientsProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: null,
      alertType: 'success',
      isLoading: false,
      id: props.patient.id || undefined,
      mrn: props.patient.mrn || '',
      name: props.patient.name || '',
      surname: props.patient.surname || '',
      date_of_birth: props.patient.date_of_birth || '',
      dd: '', mm: '', yyyy: '',
      country_code: props.patient.country_code || 'AU',
      mobile_phone: props.patient.mobile_phone || '',
      email: props.patient.email || '',
      program: props.patient.program_value || 0,
      password: '',
      active: props.patient.active,
      gender: props.patient.gender,
      participant_id: props.patient.participant_id,
      patient_notifications: props.patient.patient_notifications,
      clinicians: props.patient.clinicians
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChooseGender = this.handleChooseGender.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleGeneratePassword = this.handleGeneratePassword.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.countryCodePicker = React.createRef();
    this.notificationsSelect = React.createRef();
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleCancel = () => {
    window.location = '/clinicians/patients/' + this.state.id
  }

  handleChooseGender = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDelete = (patientId) => {
    const confirm = window.confirm("Are you sure want to delete this patient?");

    if (confirm) {
      axios({
        method: 'DELETE',
        url: API_SERVER + '/clinicians/patients/' + patientId + '.json',
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then(() => {
        window.location = '/clinicians/patients'
      })
      .catch((response) => console.log(response))
    }
  }

  handleGeneratePassword = () => {
    const generator = require('generate-password');

    const password = generator.generate({
      length: 6,
      numbers: true
    });

    this.setState({password: password});
  }

  handleFileChange = (event) => this.setState({avatar: event.target.files[0]});

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      mrn,
      name,
      surname,
      dd, mm, yyyy,
      country_code,
      mobile_phone,
      email,
      program,
      password,
      active,
      gender,
      patient_notifications
    } = this.state;

    const method = 'PUT';
    const url = API_SERVER + '/clinicians/patients/' + id + '.json';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('patient[mrn]', mrn);
    formData.append('patient[name]', name);
    formData.append('patient[surname]', surname);
    formData.append('patient[active]', active);
    formData.append('patient[gender]', gender);
    formData.append('patient[program]', program);
    formData.append('patient[patient_notification_ids]', patient_notifications);

    const date_of_birth = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date_of_birth)) {
      formData.append('patient[date_of_birth]', date_of_birth);
    } else {
      formData.append('patient[date_of_birth]', '');
    }

    formData.append('patient[country_code]', country_code);
    formData.append('patient[mobile_phone]', mobile_phone);
    formData.append('patient[email]', email);

    if (password.length > 0) {
      formData.append('patient[password]', password);
      formData.append('patient[password_confirmation]', password);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers
    })
    .then(() => this.setState({isLoading: false, alertMessage: 'Patient has been saved successfully'}))
    .catch(() => this.setState({isLoading: false, alertMessage: 'Patient unable to be saved ', alertType: 'danger'}))
  }

  componentDidMount = () => {
    const date_of_birth = this.props.patient.date_of_birth;

    if (!INVALID_DATE(date_of_birth)) {
      const dates = date_of_birth.split('-');

      this.setState({ dd: dates[2], mm: dates[1], yyyy: dates[0] });
    }

    $(this.countryCodePicker.current).select2({
      data: COUNTRY_CODES.map((el) => {
        return {id: el.code, text: el.name, element: {'data-country-code': el.dial_code}}
      })
    }).on('select2:select', (e) => {
      this.setState({ country_code: e.params.data.id })
    });

    console.log(this.state.patient_notifications.map(item => item.id.toString()))

    $(this.notificationsSelect.current).select2({
    }).on('change.select2', (e) => {
      const data = $(this.notificationsSelect.current).select2('data').map((item) => item.id)
      this.setState({patient_notifications: data })
    })
  }

  render () {
    const {
      alertMessage,
      alertType,
      isLoading,
      id,
      mrn,
      name,
      surname,
      dd, mm, yyyy,
      country_code,
      mobile_phone,
      email,
      password,
      active,
      clinicians,
      program,
      gender,
      patient_notifications,
      participant_id
    } = this.state,
      mrnInvalid = mrn.length === 0,
      nameInvalid = name.length === 0,
      surnameInvalid = surname.length === 0,
      country_codeInvalid = country_code.length === 0,
      phoneInvalid = !VALID_PHONE.test(mobile_phone) && mobile_phone.length > 0,
      emailInvalid = !VALID_EMAIL.test(email),
      passwordInvalid = !VALID_PASSWORD.test(password) && password.length > 6,
      mrnClassName = mrnInvalid ? 'form-control is-invalid' : 'form-control',
      nameClassName = nameInvalid ? 'form-control is-invalid' : 'form-control',
      surnameClassName = surnameInvalid ? 'form-control is-invalid' : 'form-control',
      phoneClassName = phoneInvalid ? 'form-control is-invalid' : 'form-control',
      emailClassName = emailInvalid ? 'form-control is-invalid' : 'form-control',
      passwordClassName = (passwordInvalid && !id) ? 'form-control is-invalid' : 'form-control',
      formValid = !mrnInvalid && !nameInvalid && !surnameInvalid && !country_codeInvalid && !phoneInvalid && !emailInvalid && !passwordInvalid,
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <form onSubmit={this.handleSubmit}>
        {alertMessage && <ALERT_MESSAGE type={alertType} content={alertMessage} />}
        <div className="form-group">
          <label className="form-control-label">MRN No. (Participant ID.)</label>
          <input
            name="mrn"
            value={mrn}
            onChange={this.handleChange}
            className={mrnClassName}
            type="text"
            placeholder="Enter MRN No." />
          <div className="invalid-feedback">Please provide a valid participant ID.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Status</label>
          <select
            value={active}
            className="form-control"
            name="active"
            onChange={this.handleChange}>
              {ACTIVE_STATUSES.map((el) => {
                return <option key={el.value} value={el.value}>{el.name}</option>;
              })}
          </select>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Name</label>
              <input
                name="name"
                value={name}
                onChange={this.handleChange}
                className={nameClassName}
                type="text"
                placeholder="Enter your name" />
              <div className="invalid-feedback">Please provide a valid name.</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Surname</label>
              <input
                name="surname"
                value={surname}
                onChange={this.handleChange}
                className={surnameClassName}
                type="text"
                placeholder="Also your surname" />
              <div className="invalid-feedback">Please provide a valid surname.</div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Birthday</label>
              <div className="form-row">
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="DD"
                    type="number"
                    onChange={this.handleChange}
                    name="dd"
                    value={dd}
                    min="1"
                    max="31" />
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="MM"
                    type="number"
                    onChange={this.handleChange}
                    name="mm"
                    value={mm}
                    min="1"
                    max="12" />
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="YYYY"
                    type="number"
                    onChange={this.handleChange}
                    name="yyyy"
                    value={yyyy}
                    min="1920"
                    max={new Date().getFullYear()} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group js-form-group-country-code">
              <label className="form-control-label">Mobile phone</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select onChange={this.handleChange} name="country_code" ref={this.countryCodePicker} className="form-control">
                    {COUNTRY_CODES.map((el) => {
                      return <option key={el.code} value={el.code}>{el.name}</option>
                    })}
                  </select>
                </div>
                <input
                  type="tel"
                  name="mobile_phone"
                  onChange={this.handleChange}
                  value={mobile_phone}
                  className={phoneClassName} />
                <div className="invalid-feedback">Please provide a valid mobile phone</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">
                Gender
              </label>
              <div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="gender_male"
                    name="gender"
                    value="male"
                    checked={gender.toString() === 'male' }
                    onChange={this.handleChooseGender}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="gender_male">Male</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="gender_female"
                    name="gender"
                    value="female"
                    checked={gender.toString() === 'female'}
                    onChange={this.handleChooseGender}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="gender_female">Female</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="gender_unidentified"
                    name="gender"
                    value="unindentified"
                    checked={gender.toString() === 'unidentified'}
                    onChange={this.handleChooseGender}
                    className="custom-control-input"
                  />
                  <label className="custom-control-label" htmlFor="gender_unidentified">Unidentified</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label">
              Program
              </label>
              <select
                value={program}
                className="form-control"
                name="program"
                onChange={this.handleChange}>
                  {PROGRAMS.map((el) => {
                    return <option key={el.value} value={el.value}>{el.name}</option>;
                  })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Email</label>
              <input
                name="email"
                value={email}
                onChange={this.handleChange}
                className={emailClassName}
                type="email"
                placeholder="name@exmaple.com" />
              <div className="invalid-feedback">Please provide a valid email.</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Password</label>
              <div className="input-group input-group-merge">
                <input
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  type="text"
                  className={passwordClassName} />
                <div className="input-group-append">
                  <span onClick={this.handleGeneratePassword} className="input-group-text" data-toggle="tooltip" title="Click here to renew password"><i className="fas fa-sync" /></span>
                </div>
                <div className="invalid-feedback">Please provide a valid password.</div>
              </div>
              <small className="form-text text-muted mt-2">Reset password here.</small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label">Notify to clinicians</label>
              <select multiple='true' onChange={this.handleChange} ref={this.notificationsSelect}  name="notifications" className="form-control">
                {clinicians.map((el) => {
                  return <option key={el.id} value={el.id} selected={patient_notifications.some(item => item.id == el.id) }>{el.email}</option>
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-control-label">Profile ID: { participant_id }</label>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <button onClick={() => this.handleDelete(id)} type="button" className="btn btn-link text-danger">Delete</button>
          <button onClick={this.handleCancel} type="button" className="btn btn-link text-muted ml-auto">Cancel</button>
          <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={!formValid}>
            <span className="btn-inner--text">Save&nbsp;</span>
            <span className="btn-inner--icon"><i className={iconClassName} /></span>
          </button>
        </div>
      </form>
    );
  }
}

export default CliniciansPatientsProfileForm;