import React from 'react';
import { API_SERVER } from '../../../constants';
import consumer from '../../../../channels/consumer';
import './messeage-box.scss';
import axios from 'axios';

class MessagePages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages : [],
      message: '',
      isLoading : false,
      isPatientChannel: true,
      patient_name: this.props.patient.full_name,
      patient_id : this.props.patient ? this.props.patient.id : 0,
      clinician_id : this.props.patient.clinician_id || 0
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.changeChannel = this.changeChannel.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.noteMessage = this.noteMessage.bind(this);
  }

  componentDidMount() {
    consumer.subscriptions.create({
      channel: 'MessagesChannel',
      patient_name: this.props.patient.full_name,
      patient_id: this.props.patient.id
    },{
      connected: () => console.log('connected'),
      disconnected: () => console.log('disconnected'),
      received: data => {
        const tempMessages = this.state.messages;
        if (data.message) {
          tempMessages.push(data.message);
          this.setState({ messages: tempMessages })
        } else if (data.messages) {
          let tempMessages = [...this.state.messages];
          tempMessages.map((message) => {
            const readMessage = data.messages.find(m => m.id === message.id)
            if (readMessage) {
              message.read_at = readMessage.read_at
            }
          })
          this.setState({ messages: tempMessages })
        }
        this.scrollToBottom();
      },
    })
    consumer.subscriptions.create({
      channel: 'CliniciansChannel',
      patient_id: this.props.patient.id
    },{
      connected: () => console.log('connected CliniciansChannel'),
      disconnected: () => console.log('disconnected'),
      received: data => {
        const tempMessages = this.state.messages;
        tempMessages.push(data.message);
        this.setState({ messages: tempMessages })
        this.scrollToBottom();
      },
    })
    this.fetchMessages();
  };

  fetchMessages = () => {
    this.setState({isLoading : true});
    let params = {
      'q[patient_id_eq]': this.props.patient.id,
      channel_patient: this.state.isPatientChannel,
      p: 1
    };
    axios({
      method : 'GET',
      url : API_SERVER + '/clinicians/messages',
      params: params,
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      this.setState({ isLoading : false });
      this.setState({ messages: res.data });
      this.scrollToBottom();
    }).catch((error) => {
      console.log("messages fetch error", error);
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  };


  handleSubmit = (event) => {
    event.preventDefault();

    const {
      message,
      patient_id,
      isPatientChannel
    } = this.state;

    const url = API_SERVER + '/clinicians/messages';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const channel_type = isPatientChannel == true ? 1 : 0;
    axios({
      method: 'POST',
      url: url,
      headers: headers,
      data: {
        channel_patient: isPatientChannel,
        message: {
          content: message,
          patient_id: patient_id,
          channel_type: channel_type
        }
      }
    })
    .then((response) => {
    })
    .catch(() => {
    })
    this.setState({ message: '' });
  }

  scrollToBottom = () => {
    const messages = document.getElementById('messages');
    messages.scroll({ top: messages.scrollHeight, behavior: 'smooth' });
  }

  changeChannel = (tab) => {
    const changePatient = tab === 'patient' ? true : false;
    this.setState({isPatientChannel : changePatient}, () => this.fetchMessages());
  }

  noteMessage = (isPatientChannel, patient_name) => {
    if (isPatientChannel) {
      return `You are in the #Patient Channel. Messages sent here will be viewable by ${patient_name} & other clinicians`;
    } else {
      return `You are in #Clinician Channel. Messages sent here will NOT be viewable by ${patient_name}`;
    }
  }

  componentWillUnmount() {
    consumer.disconnect()
  };

  render () {
    const {
      message,
      messages,
      isPatientChannel,
      patient_name
    } = this.state;

    const clinicianTabClassName = isPatientChannel ? 'btn btn-sm btn-icon rounded-pill' : 'btn btn-sm btn-icon rounded-pill btn-primary btn-overprimary';
    const patientTabClassName = isPatientChannel ? 'btn btn-sm btn-icon rounded-pill btn-primary btn-overprimary' : 'btn btn-sm btn-icon rounded-pill';
    return (
      <React.Fragment>
        <div className="page-title card-responsive">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Message Centre</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-responsive px-6 py-5">
          <div className="channels px-4 mb-4 pb-4 d-flex align-items-center justify-content-between">
            <span onClick={() => this.changeChannel('patient')} className={patientTabClassName}>#PatientChannel</span>
            <span onClick={() => this.changeChannel('clinician')} className={clinicianTabClassName}>#ClinicianChannel</span>
          </div>
          <div className="message-container" id="messages">
            {
              messages.map((message) => (
                <div key={message.id}>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="text-dark font-weight-bold">{message.send_by_name} <small className="font-size-11 text-gray">{message.send_at}</small></span>
                    { 
                      message.read_at && (
                      <small className="font-size-11 text-gray">Read by {message.patient_name} {message.read_at}</small>)
                    }
                  </div>
                  <p className="text-dark text-wrap font-weight-400" >{message.content}</p>
                </div>
              ))
            }
          </div>
          <small className="font-size-11 text-gray">{this.noteMessage(isPatientChannel, patient_name)}</small>
          <div className="d-flex align-items-center justify-content-between send-box">
            <input type="text" className="form-control" name="message" value={message} placeholder="Send Message" onChange={this.handleChange} onKeyDown={this.handleKeypress} />
            <span className="send-icon" onClick={this.handleSubmit}><i className="fas fa-paper-plane fa-2x"></i></span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MessagePages;
