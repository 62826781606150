import React from 'react';
import './overview.scss';

class OverviewDocuments extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      documents: props.documents
    }
  }

  render () {
    const {
      documents
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Documents</h6>
            </div>
              <span className="btn btn-sm btn-icon-only rounded-circle circle-around">
                <i className="fa-regular fa-file-lines purple fa-lg"></i>
              </span>
          </div>
          {
            documents && (
              documents.map((document, index) => {
                return <p className={ index != (documents.length -1) ? 'mb-0' : ''}>{document.title} - {document.date}</p>
              })
            )
          }
        </div>
      </div>
    )
  }
}

export default OverviewDocuments;