import React from 'react';

const SupportServiceRow = (props) => {
  const { area, name, phone, website, handleDelete, handleEdit} = props;

  return (
    <>
      <td scope="row">
        <span className="h6 text-sm">{ area }</span>
      </td>
      <td>{ name }</td>
      <td>{ phone }</td>
      <td className="text-center">
        <a href={website} target="_blank"><i className="fas fa-globe-asia"></i></a>
      </td>
      <td className="text-right">
        <div className="actions ml-3">
          <a href="#modal-support-service-create" onClick={handleEdit} className="action-item mr-2" data-toggle="modal">
            <i className="fas fa-pencil-alt"></i>
          </a>
          <a href="#confirm-modal" onClick={handleDelete} className="action-item text-danger mr-2" data-toggle="modal" title="Move to trash">
            <i className="fas fa-trash"></i>
          </a>
        </div>
      </td>
    </>
  )
}

export default SupportServiceRow;
