import React from 'react';
import axios from 'axios';
import { API_SERVER, INVALID_DATE } from '../../../constants';

class ClinicianReadingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      type: 'new',
      id: null,
      date: '',
      dd: '',
      mm: '',
      yyyy: '',
      page: '',
      heading: '',
      quote: '',
      quote_page: '',
      main_text: '',
      just_for_today: ''
    };
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      dd,
      mm,
      yyyy,
      page,
      heading,
      quote,
      quote_page,
      main_text,
      just_for_today,
      type
    } = this.state;

    const readingModal = window.$('#modal-reading');

    const method = id ? 'PUT' : 'POST';
    const url = method === 'POST' ? API_SERVER + '/clinicians/readings' : API_SERVER + '/clinicians/readings/' + id;
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    const date = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date)) {
      formData.append('reading[date]', date);
    } else {
      formData.append('reading[date]', '');
    }

    formData.append('reading[page]', page);
    formData.append('reading[heading]', heading);
    formData.append('reading[quote]', quote);
    formData.append('reading[quote_page]', quote_page);
    formData.append('reading[main_text]', main_text);
    formData.append('reading[just_for_today]', just_for_today);

    axios({
      method: method,
      url: url,
      headers: headers,
      data: formData
    })
    .then((response) => {
      this.props.result()
      readingModal.modal('hide');
      this.setState({isLoading: false});
    })
    .catch(() => {
      readingModal.modal('hide');
      this.setState({isLoading: false});
    })
  }

  componentDidMount = () => {
    const eventModal = window.$('#modal-reading');

    eventModal.on('show.bs.modal', () => {
      const selectedReading = this.props.selectedReading;
      
      let reading = {
        date: null,
        page: '',
        heading: '',
        quote: '',
        quote_page: '',
        main_text: '',
        just_for_today: '',
        type: ''
      };

      if (selectedReading) {
        if (!INVALID_DATE(selectedReading.date_for_edit)) {
          const dates = selectedReading.date_for_edit.split('-');
  
          reading.dd = dates[2];
          reading.mm = dates[1];
          reading.yyyy = dates[0];
        }
        reading.id = selectedReading.id,
        reading.date = selectedReading.date;
        reading.page = selectedReading.page;
        reading.heading = selectedReading.heading;
        reading.quote = selectedReading.quote;
        reading.quote_page = selectedReading.quote_page;
        reading.main_text = selectedReading.main_text;
        reading.just_for_today = selectedReading.just_for_today;
      }

      reading.type = this.props.type;
      console.log(reading);

      this.setState(reading);
    })
  }

  render () {
    const {
      isLoading,
      date,
      dd,
      mm,
      yyyy,
      page,
      heading,
      quote,
      quote_page,
      main_text,
      just_for_today
    } = this.state,
      headingValid = heading.length === 0,
      pageValid = page.length === 0,
      quoteValid = quote.length === 0,
      quotePageValid = quote_page.length === 0,
      mainTextValid = main_text.length === 0,
      justForTodayValid = just_for_today.length === 0,
      formInvalid = headingValid || pageValid || quoteValid || quotePageValid || mainTextValid || justForTodayValid,
      headingClassName = headingValid ? 'form-control is-invalid' : 'form-control',
      pageClassName = pageValid ? 'form-control is-invalid' : 'form-control',
      quoteClassName = quoteValid ? 'form-control is-invalid' : 'form-control',
      quotePageClassName = quotePageValid ? 'form-control is-invalid' : 'form-control',
      mainTextClassName = mainTextValid ? 'form-control is-invalid' : 'form-control',
      justForTodayClassName = justForTodayValid ? 'form-control is-invalid' : 'form-control',
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    
    return (
      <div className="modal fade" id="modal-reading" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <label className="mt-4 text-center">Add\Edit Reading</label>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label">Date</label>
                  <div className="form-row">
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="DD"
                        type="number"
                        onChange={this.handleChange}
                        name="dd"
                        value={dd}
                        min="1"
                        max="31" />
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="MM"
                        type="number"
                        onChange={this.handleChange}
                        name="mm"
                        value={mm}
                        min="1"
                        max="12" />
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="YYYY"
                        type="number"
                        onChange={this.handleChange}
                        name="yyyy"
                        value={yyyy}
                        min="1920"
                        max={new Date().getFullYear()} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-control-label">Page:</label>
                  <input
                    type="number"
                    name="page"
                    onChange={this.handleChange}
                    value={page}
                    className={pageClassName} />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Heading:</label>
                  <input
                    type="text"
                    name="heading"
                    onChange={this.handleChange}
                    value={heading}
                    className={headingClassName} />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Quote:</label>
                  <textarea
                    type="text"
                    name="quote"
                    onChange={this.handleChange}
                    value={quote}
                    className={quoteClassName} />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Quote page:</label>
                  <input
                    type="text"
                    name="quote_page"
                    onChange={this.handleChange}
                    value={quote_page}
                    className={quotePageClassName} />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Main text:</label>
                  <textarea
                    type="text"
                    name="main_text"
                    onChange={this.handleChange}
                    rows={8}
                    value={main_text}
                    className={mainTextClassName} />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Just for today:</label>
                  <textarea
                    type="text"
                    name="just_for_today"
                    onChange={this.handleChange}
                    value={just_for_today}
                    className={justForTodayClassName} />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  Save&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianReadingModal;
