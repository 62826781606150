import React from 'react';
import axios from 'axios';
import { API_SERVER, GOAL_STATUSES } from '../../../constants';
import ClinicianGoalModal from './modal/goal';
import ClinicianPatientGoalCommentModal from './modal/goal_comment';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import './reward.scss';

class CliniciansPatientsRewardPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      patient_id: props.patient.id,
      point_tree: props.patient.point_tree,
      point: props.patient.point,
      extra_point: '',
      status: '',
      inprocess_goals: [],
      previous_goals: [],
      goals_update_status: [],
      update_status_goals_ids: [],
      isLoading: false,
      goal_progresses: [],
      meta: {
        total: 1,
        current: 1
      },
      selectedGoalId: undefined,
      current_goal_active: true
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeGoalStatus = this.handleChangeGoalStatus.bind(this);
    this.handleUpdateGoalStatus = this.handleUpdateGoalStatus.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeGoalStatus = (event) => {
    let inprocess_goals = this.state.inprocess_goals;
    const update_status_ids = this.state.update_status_goals_ids;
    inprocess_goals.map(item => {
      if (item.id === parseInt(event.target.id)) {
        update_status_ids.push(item.id)
        item.status_key = event.target.value
      }
    })
    this.setState({inprocess_goals: inprocess_goals, update_status_goals_ids: update_status_ids})
  }

  handleGoalResult = () => this.fetchData();

  handleUpdateGoalStatus = (event) => {
    event.preventDefault();

    const url = API_SERVER + '/clinicians/goals/update_status/';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const goals_data = [];

    this.state.inprocess_goals.map(item => {
      if(this.state.update_status_goals_ids.includes(item.id) && item.status_key !== '1') {
        const temp_hash = {
          id: item.id,
          status: item.status_key
        }
        goals_data.push(temp_hash)
      }
    })
    
    axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: {
        goals: goals_data
      }
    })
    .then((response) => {
      this.fetchData()
      if (response.data.patient.point) {
        this.setState({ point: response.data.patient.point })
      }
    })
    .catch(() => {
    })
  }

  changeTab = (value) => {
    this.setState({ current_goal_active: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      extra_point,
      patient_id
    } = this.state;

    const url = API_SERVER + '/clinicians/patients/' + patient_id + '/update_extra_point'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: {
        extra_point: extra_point
      }
    })
    .then((response) => {
      this.setState({ point_tree: response.data.reward.point_tree });
      this.setState({ point: response.data.reward.point });
    })
    .catch(() => {
    })
  }

  fetchData = () => {
    let params = {};
    params['q[patient_id_eq]'] = this.props.patient.id;

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/goals',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => {
      const inprocesses = [], previous = [];
      result.data.map(item => {
        item.status_key === 1 ? inprocesses.push(item) : previous.push(item)
      })
      this.setState({ inprocess_goals: inprocesses, previous_goals: previous, update_status_goals_ids: [] })
    })
    .catch((result) => console.log(result))
  }

  fetchGoldUpdateProcess = () => {
    this.setState({ isLoading: true })
    let params = {};
    params['q[patient_id_eq]'] = this.props.patient.id;

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/goal_progress',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => {
      this.setState({ goal_progresses: result.data.goal_progresses, meta: result.data.meta, isLoading: false })
    })
    .catch((result) => console.log(result))
  }

  loadRecords = () => {
    this.fetchGoldUpdateProcess();
  }

  componentDidMount = () => {
    this.fetchData();
    this.fetchGoldUpdateProcess();
  }

  render () {
    const {
      point_tree,
      point,
      extra_point,
      patient_id,
      inprocess_goals,
      previous_goals,
      status_initial,
      goal_progresses,
      selectedGoalId,
      current_goal_active
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Goals</h5>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <button className="btn btn-sm btn-white rounded-pill ml-4" type="button" data-toggle="modal" data-target="#modal-goal">
                <span className="btn-inner--icon mr-2"><i className="fas fa-plus" /></span>
                New Goal
              </button>
              <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                <i className="fas fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="table-responsive">
            <div className="reward-container">
              <span className="point-tree">Number of trees planted: { point_tree }</span>
              <span className="point">Current points: { point }</span>
              <div className="extra-point-container">
                <label className="extra-point-label" >Allocate extra points</label>
                <input
                  className="extra-point-input"
                  value={extra_point}
                  name="extra_point"
                  type="number"
                  min="0"
                  onChange={this.handleChange}
                />
                <button className="btn btn-sm btn-primary btn-overprimary save-extra-point-button" onClick={this.handleSubmit} >Save</button>
              </div>
            </div>
            <hr className="line" />
          </div>
        </div>
        <div className="card">
          <div className="table-responsive">
            {
              current_goal_active &&
              <div className="tab-current-goals">
                <p className="current-goal-title">
                  <span className={ `tab-title current-title ${ current_goal_active ? 'active' : undefined }`} onClick={() => this.changeTab(true)}>Current Goals</span>
                  <span className={ `tab-title ${ !current_goal_active ? 'active' : undefined }` } onClick={() => this.changeTab(false)}>Previous Goals</span>
                </p>
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Goal</th>
                      <th scope="col">Points</th>
                      <th scope="col">Worker</th>
                      <th scope="col">Date set</th>
                      <th scope="col">Time set</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {
                    inprocess_goals.map((goal, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {goal.description}
                          </td>
                          <td>
                            {goal.point}
                          </td>
                          <td>
                            <div className="card-meta d-inline-flex align-items-center ml-sm-auto">
                              <div className="media media-pill align-items-center d-inline-flex bg-secondary">
                                <div className="ml-2">
                                  <small>
                                    {[goal.clinician.name, goal.clinician.surname].join(' ')}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {goal.date_set}
                          </td>
                          <td>
                            {goal.time_set}
                          </td>
                          <td>
                            <select
                              name="status"
                              id={goal.id}
                              value={goal.status_key}
                              onChange={this.handleChangeGoalStatus}
                              className="form-control"
                            >
                              {GOAL_STATUSES.map((el) => {
                                return <option key={el.value} value={el.value}>{el.name}</option>;
                              })}
                            </select>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
                <p className="sumit-container">
                  <button className="btn btn-sm btn-primary btn-overprimary submit-button" onClick={this.handleUpdateGoalStatus} >Update</button>
                </p>
              </div>
            }
            {
              !current_goal_active &&
              <div className="tab-previous-goals">
                <p className="current-goal-title">
                  <span className={ `tab-title current-title ${ current_goal_active && `active` }`} onClick={() => this.changeTab(true)}>Current Goals</span>
                  <span className={ `tab-title ${ !current_goal_active && `active`}` } onClick={() => this.changeTab(false)}>Previous Goals</span>
                </p>
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Goal</th>
                      <th scope="col">Points</th>
                      <th scope="col">Worker</th>
                      <th scope="col">Date set</th>
                      <th scope="col">Time set</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {
                    previous_goals.map((goal, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {goal.description}
                          </td>
                          <td>
                            {goal.point}
                          </td>
                          <td>
                            <div className="card-meta d-inline-flex align-items-center ml-sm-auto">
                              <div className="media media-pill align-items-center d-inline-flex bg-secondary">
                                <div className="ml-2">
                                  <small>
                                    {[goal.clinician.name, goal.clinician.surname].join(' ')}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {goal.date_set}
                          </td>
                          <td>
                            {goal.time_set}
                          </td>
                          <td>
                            {goal.status}
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
        <ClinicianGoalModal result={this.handleGoalResult} patient_id={patient_id} />
      </React.Fragment>
    )
  }
}

export default CliniciansPatientsRewardPage;
