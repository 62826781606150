import React from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import ClinicianZoomMeetingScheduleModal from './zoom-meeting-schedule-modal';
import { API_SERVER } from '../../constants';

const initialCalendar = {
  isLoading: false,
  events: [],
  selectedInfo: null
}

class CliniciansClinicianZoomMeetingScheduleCalendar extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = initialCalendar;
  }

  componentDidMount = () => {
    this.setState({isLoading: true});

    const url = API_SERVER + '/clinicians/zoom_meeting_schedules';
    const method = 'GET';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: method,
      url: url,
      headers: headers
    })
    .then((response) => {
      this.setState({events: this.state.events.concat(response.data)})
    })
    .catch((response) => console.log(response))
  }

  // edit appointment
  handleClick = (clickedInfo) => {
    this.setState({selectedInfo: clickedInfo.event});
    window.$('#new-event-zoom').modal('show');
  }

  // new appointment
  handleSelect = (selectInfo) => {
    this.setState({selectedInfo: selectInfo});
    window.$('#new-event-zoom').modal('show');
  }

  handleResult = (event) => {
    let events = [];

    if (event.create) {
      events = this.state.events.concat(event.data)
    }

    if (event.update) {
      events = this.state.events.filter(el => el.id != event.data.id);
      events = events.concat(event.data);
    }

    if (event.delete) {
      if (event.data.zoom_meeting_ids) {
        events = this.state.events.filter(el => !event.data.zoom_meeting_ids.includes(el.id));
      } else {
        events = this.state.events.filter(el => el.id != event.data.id);
      }
    }

    this.setState({events: events})
  }

  handleContent = (eventContent) => {
    const timeText = eventContent.timeText.toUpperCase(),
          zoomLink = eventContent.event.extendedProps.zoom_link;

    return (
      <div>
        <b>{timeText}</b>&nbsp;<i></i>
        <p className="mb-0">{zoomLink}</p>
      </div>
    )
  }

  render () {
    const {
      events,
      selectedInfo
    } = this.state;

    return (
      <React.Fragment>
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ]}
          headerToolbar={{
            left: 'title',
            right: 'prev,next, dayGridMonth,timeGridWeek,timeGridDay'
          }}
          editable={true}
          events={events}
          eventClick={this.handleClick}
          eventContent={this.handleContent}
          initialView='dayGridMonth'
          selectable={true}
          select={this.handleSelect}
          themeSystem='bootstrap'
        />
        <ClinicianZoomMeetingScheduleModal
          selectedInfo={selectedInfo}
          resultCallback={this.handleResult} />
      </React.Fragment>
    )
  }
}

export default CliniciansClinicianZoomMeetingScheduleCalendar;
