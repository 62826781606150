import React from 'react';
import VideoForm from './form';

const VideoModal = (props) => {
  return (
    <div className="modal fade" id="modal-support-service-create" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <VideoForm video={props.video} />
        </div>
      </div>
    </div>
  );
}

export default VideoModal;
