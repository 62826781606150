import React from 'react';
import MoodChart from './charts/mood';
import SleepHourChart from './charts/sleep_hour';

class ClinicianPatientChart extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="row align-items-center">
          <div className="col">
            <h6 className="text-sm mb-0">Mood Tracking</h6>
          </div>
          <div className="col-4">
            <div style={{maxWidth: '100px'}}>
              <MoodChart patient={this.props.patient} />
            </div>
          </div>
        </div>

        <hr className="my-3" />

        <div className="row align-items-center">
          <div className="col">
            <h6 className="text-sm mb-0">Sleep hours Tracking</h6>
          </div>
          <div className="col-4">
            <div style={{maxWidth: '100px'}}>
              <SleepHourChart patient={this.props.patient} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ClinicianPatientChart;