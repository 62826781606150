import React, { useState, useRef, useCallback } from "react";
import AdminsSearchClinics from "./search-clinics";
import MoreClinic from "./more-clinic";
import AdminsClinicRow from "./clinic-row";
import EmptyTableRow from "../../shared/empty-table-row";
import AdminsClinicModal from './clinic-modal';

export default function AdminsClinicsListPage(props) {
  const [query, setQuery] = useState("");
  const [active, setActive] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedClinic, setSelectedClinic] = useState(null);

  const { clinics, hasMore, loading, error } = AdminsSearchClinics(query, pageNumber, active);

  const observer = useRef();
  const lastClinicElementRef = useCallback(
    (node) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleResetSearch() {
    setQuery("");
  }

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  function handleSearchAll() {
    setActive(null)
  }

  function handleSearchByActive() {
    setActive(true)
  }

  function handleSearchByInactive() {
    setActive(false)
  }

  function handleRowResult(arg) {
    setSelectedClinic(arg);
  }

  function handleNewClinic() {
    setSelectedClinic(null);
  }

  return (
    <React.Fragment>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">
                Clinics
              </h5>
            </div>

            <div className="align-items-center ml-4 d-inline-flex">
              <span className="h4 text-info mb-0 mr-2">
                {props.clinicCount}
              </span>
              <span className="text-sm opacity-7 text-white">clinics</span>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a
              href="#modal-clinic-create"
              onClick={handleNewClinic}
              className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4"
              data-toggle="modal"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-plus"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header actions-toolbar border-0">
          <div className="actions-search" id="actions-search">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent">
                  <i className="fas fa-search"></i>
                </span>
              </div>
              <input
                value={query}
                onChange={handleSearch}
                type="text"
                className="form-control form-control-flush"
                placeholder="Type and hit enter ..."
              />
              <div className="input-group-append">
                <a
                  href="#"
                  onClick={handleResetSearch}
                  className="input-group-text bg-transparent"
                  data-action="search-close"
                  data-target="#actions-search"
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Clinics</h6>
            </div>
            <div className="col text-right">
              <div className="actions">
                <a
                  href="#"
                  className="action-item mr-3"
                  data-action="search-open"
                  data-target="#actions-search"
                >
                  <i className="fas fa-search"></i>
                </a>
                <div className="dropdown">
                  <a
                    href="#"
                    className="action-item"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-filter"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a onClick={handleSearchAll} className="dropdown-item" href="#">All</a>
                    <a onClick={handleSearchByActive} className="dropdown-item" href="#">
                      <i className="fas fa-toggle-on"></i>Active
                    </a>
                    <a onClick={handleSearchByInactive} className="dropdown-item" href="#">
                      <i className="fas fa-toggle-off"></i>Inactive
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Clinic Name</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Website</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription</th>
                <th scope="col">Subscribed at</th>
                <th scope="col">Medication Nav</th>
                <th scope="col">Appointment Nav</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {clinics.map((clinic, index) => {
                if (clinics.length === index + 1) {
                  return (
                    <tr key={index} ref={lastClinicElementRef}>
                      <AdminsClinicRow rowResult={handleRowResult} clinic={clinic} />
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <AdminsClinicRow rowResult={handleRowResult} clinic={clinic} />
                    </tr>
                  );
                }
              })}

              {clinics.length == 0 ? <EmptyTableRow />: null}

            </tbody>
          </table>
        </div>
      </div>

      <div>{loading && <MoreClinic />}</div>

      <AdminsClinicModal clinic={selectedClinic} />
    </React.Fragment>
  );
}
