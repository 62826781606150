import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../constants';
import ClinicianPatientFeedbackCommentModal from '../patient-feedback-comment-modal'
import Pagination from "rc-pagination"
import "rc-pagination/assets/index.css"

class OverviewCurrentFeebacks extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      data: [],
      selectedFeedbackId: undefined,
      selectedPatientComment: "",
      meta: {
        total: 1,
        current: 1
      }
    }
  }

  componentDidMount = () => {
    this.setState({isLoading: true})

    let params = {'q[patient_id_eq]': this.props.patient.id, p: 1};

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/feedbacks',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: false, meta: result.data.meta, data: result.data.feedbacks}))
    .catch((result) => console.log(result))
  }

  loadRecords = (page) => {
    this.setState({isLoading: true})
    this.setState({selectedMoodId: undefined})

    let params = {'q[patient_id_eq]': this.props.patient.id, p: page || this.state.meta.current};

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/feedbacks',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: false, meta: result.data.meta, data: result.data.feedbacks}))
    .catch((result) => console.log(result))
  }

  render () {
    const {data, selectedFeedbackId} = this.state;

    return (
      <>
        <table className="table align-items-center">
          <thead>
            <tr>
              <th scope="col">Date & Time</th>
              <th scope="col">Participant entry</th>
              <th scope="col">Worker comment</th>
            </tr>
          </thead>
          <tbody>
            {data.map((m, i) => {
              return (
                <tr key={m.id}>
                  <td>{m.daily_log_date}<br/>{m.daily_log_time}</td>
                  <td className="text-wrap">{m.comment}</td>
                  <td className="text-wrap">
                    {
                      m.clinician_comment ?
                        <div><b>{`${m.clinician_comment_date} - ${m.clinician_comment_name}`}</b>: <br/>{m.clinician_comment}</div>
                        :
                        <button onClick={() => this.setState({selectedFeedbackId: m.id, selectedPatientComment: m.comment})} className="btn btn-sm btn-primary btn-overprimary" data-toggle="modal" data-target="#modal-patient-feedback-comment">Add comment</button>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <ul className="pagination justify-content-center">
          <Pagination
            defaultCurrent={1}
            pageSize={1}
            total={this.state.meta.total}
            defaultPageSize={1}
            onChange={page => {
              this.loadRecords(page)
            }}
            current={this.state.meta.current}
          />
        </ul>

        <br/>
        <ClinicianPatientFeedbackCommentModal selectedFeedbackId={selectedFeedbackId} patientId={this.props.patient.id} patientComment={this.state.selectedPatientComment} reload={this.loadRecords}/>
      </>
    )
  }
}

export default OverviewCurrentFeebacks;
