import React, { useState, useRef, useCallback, useEffect } from 'react';
import RightNavbar from '../../shared/right-navbar';
import InfoRow from './info-row';
import LoadMore from '../../shared/load-more';
import ConfirmModal from '../../shared/confirm-modal';
import ClinicInfoModal from './info-modal';

import GetListClinicInfos from './get-list-clinic-infos';
import DeleteClinicInfo from './delete-clinic-info';


const InfosListPage = () =>  {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [clinicInfos, setClinicInfos ] = useState([]);
  const [hasMore, setHasMore ] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [error, setError ] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [editingRecord, setEditingRecord] = useState();

  const loadData = async () => {
    const {
      hasMore,
      loading,
      error,
      ...rest
    } = await GetListClinicInfos(query, pageNumber);
    setClinicInfos([...clinicInfos, ...rest.clinicInfos ]);
    setHasMore(hasMore);
    setLoading(loading);
    setError(error);
  };

  // Did mount
  useEffect(() => {
    loadData();
  }, []);

  // Did update
  useEffect(() => {
    loadData();
  }, [query, pageNumber]);

  // load more on scroll page
  const observer = useRef()
  const lastClinicInfoElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // open modal create
  const openModalCreate = () => {
    setEditingRecord(undefined);
  }

  // open modal edit
  const openModalEdit = (info) => {
    setEditingRecord(info);
  }

  // open modal confirm delete
  const openModalConfirm = (id) => {
    setDeletingId(id);
  }

  // handle delete clinic info
  const handleDeleteClinicInfo = (id) => {
    DeleteClinicInfo(id);
    setClinicInfos(clinicInfos.filter((item) => item.id !== id));
  }

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Info &amp; Services</h5>
            </div>
          </div>
          <div className="col-auto col-md-6 d-flex align-items-center justify-content-between justify-content-md-end"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 order-lg-2">
          <RightNavbar />
        </div>
        <div className="col-lg-8 order-lg-1">
          <div className="card mh-615">
            <div className="card-header actions-toolbar border-0">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-10">
                  <h6 className="d-inline-block mb-0">Client Info</h6>
                  <p className="mb-0 text-sm">
                    The documents that you upload in this section will appear in the Client Info screen in the patient-facing app & be viewable by all of your services patients. 
                  </p>
                  <p className="text-sm">
                    When a document is deleted from this section it will no longer be accessible for any patients.
                  </p>
                </div>
                <div className="col-lg-2 text-right">
                  <div className="actions">
                    <a href="#modal-clinic-info-create" onClick={() => openModalCreate()} className="action-item mr-3" data-toggle="modal">
                      <i className="fas fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Label</th>
                    <th scope="col">Description</th>
                    <th scope="col" className="text-center">Uploaded PDF File</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {clinicInfos.map((info, index) => {
                    if (clinicInfos.length === index + 1) {
                      return (
                        <tr ref={lastClinicInfoElementRef} key={index}>
                          <InfoRow
                            index={index+1}
                            label={info.label}
                            description={info.description}
                            pdfUrl={info.pdf_url}
                            handleEdit={() => openModalEdit(info)}
                            handleDelete={() => openModalConfirm(info.id)} />
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={index}>
                          <InfoRow
                            index={index+1}
                            label={info.label}
                            description={info.description}
                            pdfUrl={info.pdf_url}
                            handleEdit={() => openModalEdit(info)}
                            handleDelete={() => openModalConfirm(info.id)} />
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div>{loading && <LoadMore />}</div>
          <div>{error && 'Error'}</div>
        </div>
      </div>
      <ConfirmModal message={'Are you sure want to delete this item!'} onSubmit={() => handleDeleteClinicInfo(deletingId)} />
      <ClinicInfoModal clinicInfo={editingRecord}/>
    </>
  )
}

export default InfosListPage;
