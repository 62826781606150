import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import axios from 'axios';
import { API_SERVER } from '../../../../constants';

class ClinicianMedicationRecordModal extends React.Component {
  state = {
    isLoading: false,
    taken: this.props.taken,
    calendar: '',
    events: []
  }

  handleClick = (clickedInfo) => {
    const yes = window.confirm('Are you sure want to change this medication?');

    if (yes) {
      const currentState = clickedInfo.event.extendedProps.taken;
      const params = {'record[taken]': !currentState};

      axios({
        method: 'PUT',
        url: API_SERVER + '/clinicians/records/'+ parseInt(clickedInfo.event.id) +'.json',
        headers: JSON.parse(localStorage.getItem('clinician_auth')),
        params: params
      })
      .then((response) => {
        const record = response.data;
        const events = this.state.events.filter((el) => el.id !== record.id)
        const event = {
          id: record.id,
          title: this.renderTaken(record),
          start: record.due_date,
          backgroundColor: this.renderBackground(record),
          taken: record.taken
        }
        const newEvents = events.concat(event);
        const calendar = <FullCalendar plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ]} headerToolbar={{ left: 'title', right: 'prev,next, dayGridMonth,timeGridWeek,timeGridDay' }} editable={true} events={newEvents} eventClick={this.handleClick} initialView='dayGridMonth' selectable={true} themeSystem='bootstrap' />

        this.setState({
          isLoading: false,
          taken: record.taken,
          events: newEvents,
          calendar: calendar
        })
      })
      .catch((response) => console.log(response))
    }
  }

  componentDidMount = () => {
    const recordModal = window.$('#modal-medication-record');

    recordModal.on('shown.bs.modal', () => {
      this.setState({isLoading: true})

      const medication = this.props.medication;
      const url = API_SERVER + '/clinicians/records.json';
      const params = {'q[medication_id_eq]': medication.id };

      axios({
        method: 'GET',
        url: url,
        headers: JSON.parse(localStorage.getItem('clinician_auth')),
        params: params
      })
      .then((response) => {
        const events = response.data.map((el) => {
          return {
            id: el.id,
            title: this.renderTaken(el),
            start: el.due_date,
            backgroundColor: this.renderBackground(el),
            taken: el.taken
          }
        })

        const calendar = <FullCalendar plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ]} headerToolbar={{ left: 'title', right: 'prev,next, dayGridMonth,timeGridWeek,timeGridDay' }} editable={true} events={events} eventClick={this.handleClick} initialView='dayGridMonth' selectable={true} themeSystem='bootstrap' />

        this.setState({
          isLoading: false,
          events: events,
          calendar: calendar
        })
      })
      .catch((response) => console.log(response))
    })

    recordModal.on('hidden.bs.modal', () => this.setState({isLoading: true}))
  }

  renderBackground = (rec) => {
    if (rec.taken === true) return '#36b37e';
    if (rec.taken === false) return '#ff5630';
    if (rec.taken === null) return '#3a4d64';
  }

  renderTaken = (rec) => {
    if (rec.taken === true) return 'Taken';
    if (rec.taken === false) return 'Not taken';
    if (rec.taken === null) return 'N/A';
  }

  renderFrequently = (schedule) => {
    if (!schedule) return;

    const daily = schedule.monday && schedule.tuesday &&
                  schedule.wednesday && schedule.thursday &&
                  schedule.friday && schedule.saturday && schedule.sunday;

    if (daily) {
      return 'Daily'
    } else {
      let days = [];
      if (schedule.monday) {days.push('Monday')};
      if (schedule.tuesday) {days.push('Tuesday')};
      if (schedule.wednesday) {days.push('Wednesday')};
      if (schedule.thursday) {days.push('Thursday')};
      if (schedule.friday) {days.push('Friday')};
      if (schedule.saturday) {days.push('Saturday')};
      if (schedule.sunday) {days.push('Sunday')};
      return days.join('-')
    }
  }

  render () {
    const { isLoading, calendar } = this.state;
    const medication = this.props.medication || {};
    const schedules = medication.schedules || [];

    return (
      <div className="modal fade" id="modal-medication-record" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="mb-0">{medication?.name} : {medication?.dosage}mg-{schedules.map((schedule, i) => { return this.renderFrequently(schedule) })}</h6>
              <button className="close text-dark" data-dismiss="modal">×</button>
            </div>
            <div className="modal-body">
              {isLoading ? <i className="fa fa-spinner fa-spin" /> : calendar}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianMedicationRecordModal;