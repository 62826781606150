import React from 'react';
import FORMAT_DATE from '../../constants';

const AdminsAppointmentsRow = ({
  id,
  patient,
  title,
  start,
  stop,
  date,
  send_sms,
  sent_at,
  clinic,
  clinician,
  created_at
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{patient.full_name}</td>
      <td>{title}</td>
      <td>{date}</td>
      <td>{start}</td>
      <td>{stop}</td>
      <td>{send_sms ? 'Yes' : 'No'}</td>
      <td>{sent_at}</td>
      <td>{clinic.name}</td>
      <td>{clinician.full_name}</td>
      <td>{created_at}</td>
    </tr>
  )
}

export default AdminsAppointmentsRow;
