import React from 'react';
import axios from 'axios';
import { API_SERVER, VALID_EMAIL, VALID_PASSWORD } from '../../constants';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      formValid: true,
      passwordHidden: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount = () => localStorage.removeItem('clinician_auth');

  handleSubmit = event => {
    event.preventDefault();

    this.setState({isLoading: true})

    const params = {email: this.state.email, password: this.state.password};

    axios.post(API_SERVER + '/clinicians/auth/sign_in.json', params)
      .then(response => {
        const token = response.headers['access-token'],
          client = response.headers['client'],
          uid = response.headers['uid'],
          queryString = require('query-string'),
          query = {token: token, client: client, uid: uid},
          location = '/clinicians/auth/sign_in?' + queryString.stringify(query),
          clinician = response.data.data;

        localStorage.setItem('clinician_auth', JSON.stringify({
          'access-token': token,
          'client': client,
          'uid': uid,
          'has_access_to_medications': clinician.has_access_to_medications
        }));
        
        window.location = location;
      }).catch(error => {
        if (error.response) {
          let errors;

          if (error.response.data.errors) {
            errors = error.response.data.errors.join(', ');
          } else {
            errors = error.response.statusText;
          }
        }

        this.setState({isLoading: false, formValid: false});
      });
  }

  handleChange = event => {
    event.preventDefault();

    this.setState({[event.target.name]: event.target.value, formValid: true})
  };

  togglePassword = () => this.setState({ passwordHidden: !this.state.passwordHidden });

  render () {
    const {email, password, formValid} = this.state,
      emailValid = VALID_EMAIL.test(email) || email.length === 0,
      // passwordValid = VALID_PASSWORD.test(password),
      passwordValid = password.length >= 0,
      emailClassName = emailValid && formValid ? 'form-control' : 'form-control is-invalid',
      passwordClassName = passwordValid && formValid ? 'form-control' : 'form-control is-invalid',
      formInvalid = !(emailValid && passwordValid && formValid),
      iconClassName = this.state.isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label className="form-control-label">Email</label>
            <div className="input-group input-group-merge">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-envelope" /></span>
              </div>
              <input
                onChange={this.handleChange}
                className={emailClassName}
                placeholder="Enter your email"
                autoFocus="autofocus"
                type="email"
                name="email"
                value={email}
                autoComplete="off"
                required />
              {
                (!emailValid || formInvalid) &&
                <div className="invalid-feedback">Please provide a valid email.</div>
              }
            </div>
          </div>
          <div className="form-group mb-4">
            <label className="form-control-label">Password</label>
            <div className="input-group input-group-merge">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-key" /></span>
              </div>
              <input
                onChange={this.handleChange}
                className={passwordClassName}
                placeholder="Enter your Password"
                type={this.state.passwordHidden ? "password" : "text"}
                name="password"
                value={password}
                autoComplete="off"
                required />
              {
                (!passwordValid || formInvalid) &&
                <div className="invalid-feedback">
                  Passwordn can't be blank
                </div>
              }
              <div className="input-group-append">
                <span className="input-group-text">
                  <a href="#" onClick={this.togglePassword}>
                    <i className="fas fa-eye eye-overprimary" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex align-items-center justify-content-between">
              <a className="small text-muted text-underline--dashed border-primary" href="/clinicians/auth/password/new">Forgot password?</a>
              <button disabled={formInvalid} type="submit" className="btn btn-sm btn-primary btn-overprimary btn-icon rounded-pill">
                <span className="btn-inner--text">Sign in</span>
                <span className="btn-inner--icon"><i className={iconClassName} /></span>
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default LoginForm
