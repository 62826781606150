import React from 'react';

const LoadMore = () => {
  return (
    <div className="mt-4 text-center">
      <span className="btn btn-sm btn-neutral rounded-pill shadow hover-translate-y-n3">Loading ...</span>
    </div>
  )
}

export default LoadMore;
