import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../constants';

class NotificationRuleSettingModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.patient.name,
      patient_id: props.patient.id,
      mood_score_max: props.patient_notification && props.patient_notification.mood_score_max || 0,
      consecutive_posts: props.patient_notification && props.patient_notification.consecutive_posts || 0
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inactiveNotification = this.inactiveNotification.bind(this)
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      patient_id,
      mood_score_max,
      consecutive_posts
    } = this.state;

    const url = API_SERVER + '/clinicians/patients/' + patient_id + '/setting_notification_rule'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: 'PUT',
      url: url,
      headers: headers,
      data: {
        mood_score_max: mood_score_max,
        consecutive_posts: consecutive_posts
      }
    })
    .then((response) => {
      this.props.resultCallback(response.data.data);
      window.$('#modal-notification-rule-setting').modal('hide');
    })
    .catch(() => {
      window.$('#modal-notification-rule-setting').modal('hide');
    })
  }

  inactiveNotification = (event) => {
    event.preventDefault();

    const {
      patient_id,
    } = this.state;

    const url = API_SERVER + '/clinicians/patients/' + patient_id + '/toogle_notification'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: 'POST',
      url: url,
      headers: headers,
      data: {
        toogle_notification_flag: false
      }
    })
    .then((response) => {
      this.props.resultCallback(response.data.data);
      window.$('#modal-notification-rule-setting').modal('hide');
    })
    .catch(() => {
      window.$('#modal-notification-rule-setting').modal('hide');
    })
  }

  render () {
    const {
      mood_score_max,
      consecutive_posts,
      name
    } = this.state
    return (
      <div className="modal fade" id="modal-notification-rule-setting" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="notification-rule-setting">
              <label>Set Notification Rule</label>
              <p>
                Send a notification if { name } records a mood score of
                <br />
                <input
                  type="number"
                  onChange={this.handleChange}
                  name="mood_score_max"
                  value={mood_score_max}
                  min="0"
                  max="100" />
                  &nbsp; 
                 or less,
                in <br/>
                <input
                  type="number"
                  onChange={this.handleChange}
                  name="consecutive_posts"
                  value={consecutive_posts}
                  min="0"
                  max="100" /> &nbsp;  or more consecutive entires
              </p>
              <span className="btn btn-sm btn-primary btn-overprimary" onClick={this.handleSubmit}>OK</span>
              <a href="#" onClick={this.inactiveNotification} >Disable notification rule</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotificationRuleSettingModal;
