import React from 'react';

class CliniciansPatientsScheduleRow extends React.Component {
  constructor(props) {
    super(props);

    this.timePicker = React.createRef();
  }

  state = this.props.schedule

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleDayClick = (day) => this.setState((state) => ({[day]: !state[day]}));

  handleState = () => this.props.handleScheduleState(this.props.position, this.state);

  componentDidMount = () => {
    flatpickr(this.timePicker.current, {
      onChange: (selectedDates, dateStr) => this.setState({time_at: dateStr}),
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      static: true
    });
  }

  render () {
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      time_at
    } = this.state;

    const deleteIcon = <div className="col-auto"><a onClick={() => this.props.clickedSchedule(this.props.position)} href="#" className="btn btn-link px-0"><i className="fas fa-trash" /></a></div>;

    this.handleState();

    return (
      <div className="row">
        <div className="col-12">
          <div className="form-group">          
            <label className="form-control-label">Schedule</label>
            <div className="row">
              <div className="col-auto">
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                  <div className="btn-group mr-2" role="group" aria-label="First group">
                    <button onClick={() => this.handleDayClick('monday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${monday && `active`}`}>Mon</button>
                    <button onClick={() => this.handleDayClick('tuesday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${tuesday && `active`}`}>Tue</button>
                    <button onClick={() => this.handleDayClick('wednesday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${wednesday && `active`}`}>Wed</button>
                    <button onClick={() => this.handleDayClick('thursday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${thursday && `active`}`}>Thu</button>
                    <button onClick={() => this.handleDayClick('friday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${friday && `active`}`}>Fri</button>
                    <button onClick={() => this.handleDayClick('saturday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${saturday && `active`}`}>Sat</button>
                    <button onClick={() => this.handleDayClick('sunday')} type="button" className={`btn btn-xs btn-primary border-0 py-3 ${sunday && `active`}`}>Sun</button>
                  </div>
                </div>
              </div>
              <div className="col">
                <input
                  name="time_at"
                  ref={this.timePicker}
                  type="text"
                  className="form-control"
                  defaultValue={time_at} />
              </div>
              {this.props.position > 0 && deleteIcon}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CliniciansPatientsScheduleRow