import React, { useState, useEffect, useRef, useCallback } from 'react';
import CorrespondenceRow from './correspondence-row';
import CorrespondenceModal from './correspondence-modal';
import ConfirmModal from '../../../shared/confirm-modal';
import LoadMore from '../../../shared/load-more';
import AdvancedStatementModal from './advanced-statement_modal';

import GetListCorrespondences from './get-list-correspondences';
import DeleteCorrespondence from './delete-correspondence';

const CorrespondenceListPage = (props) => {
  const { patient } = props;
  const [query, setQuery] = useState({patient_id: patient.id});
  const [pageNumber, setPageNumber] = useState(1);
  const [correspondences, setCorrespondences] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [editingRecord, setEditingRecord] = useState();


  const loadData = async () => {
    const {
      hasMore,
      loading,
      error,
      ...rest
    } = await GetListCorrespondences(query, pageNumber);
    setCorrespondences([...correspondences, ...rest.correspondences]);
    setHasMore(hasMore);
    setLoading(loading);
    setError(error);
  };

  // Did mount
  useEffect(() => {
    loadData();
  }, []);

  // Did update
  useEffect(() => {
    loadData();
  }, [query, pageNumber]);

  // load more on scroll page
  const observer = useRef()
  const lastElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // open modal create
  const openModalCreate = () => {
    setEditingRecord('');
  }

  // open modal edit
  const openModalEdit = (correspondence) => {
    setEditingRecord(correspondence);
  }

  // open modal confirm delete
  const openModalConfirm = (id) => {
    setDeletingId(id);
  }

  // handle delete correspondence
  const handleDeleteCorrespondence = (id) => {
    DeleteCorrespondence(id);
    setCorrespondences(correspondences.filter((item) => item.id !== id));
  }

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col col-lg-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Correspondence</h5>
            </div>
          </div>
          <div className="col-auto col-lg-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a href="#modal-patient-correspondence-upload" onClick={openModalCreate} className="btn btn-white btn-sm rounded-pill" data-toggle="modal">Add Document</a>
            <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
              <i className="fas fa-bars"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="card mh-680">
        <div className="card-header actions-toolbar">
          <div className="actions-search" id="actions-search">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent"><i className="fas fa-search"></i></span>
              </div>
              <input type="text" className="form-control form-control-flush" placeholder="Type and hit enter ..." />
              <div className="input-group-append">
                <a href="#" className="input-group-text bg-transparent" data-action="search-close" data-target="#actions-search"><i className="fas fa-times"></i></a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Documents</h6>
              <p>
                The documents that you add here will be accessible to this individual participant only via the My Correspondence section of their HARP+ mobile app.
              </p>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                <th scope="col">Created by</th>
                <th scope="col">Created at</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {correspondences.map((correspondence, index) => {
                if (correspondences.length === index + 1) {
                  return (
                    <tr ref={lastElementRef} key={index}>
                      <CorrespondenceRow
                        index={index + 1}
                        label={correspondence.label}
                        description={correspondence.description}
                        document_url={correspondence.document_url}
                        created_by={correspondence.created_by}
                        created_at={correspondence.created_at}
                        handleEdit={() => openModalEdit(correspondence)}/>
                    </tr>
                  )
                } else {
                  return (
                    <tr key={index}>
                      <CorrespondenceRow
                        index={index + 1}
                        label={correspondence.label}
                        description={correspondence.description}
                        document_url={correspondence.document_url}
                        created_by={correspondence.created_by}
                        created_at={correspondence.created_at}
                        handleEdit={() => openModalEdit(correspondence)}/>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div>{loading && <LoadMore />}</div>
      <div>{error && 'Error'}</div>
      <ConfirmModal message={'Are you sure want to delete this item!'} onSubmit={() => handleDeleteCorrespondence(deletingId)} />
      <CorrespondenceModal patient={patient} correspondence={editingRecord} onDelete={openModalConfirm}/>
      <AdvancedStatementModal patient={patient} />
    </>
  )
}

export default CorrespondenceListPage;
