import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

export default function AdminsSearchClinics(query, pageNumber, active) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setClinics([]);
  }, [query])

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    let params = {'q[name_cont]': query, p: pageNumber}

    if (active !== null) {
      params['q[active_eq]'] = active;
    }

    axios({
      method: 'GET',
      url: [API_SERVER, 'admins/clinics.json'].join('/'),
      params: params,
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then(result => {
      setClinics(prevClinics => {
        return [...new Set([...prevClinics, ...result.data])]
      });

      setHasMore(result.data.length > 0);

      setLoading(false);
    }).catch(e => {
      if (e.response && e.response.status === 401) window.location = '/admins/auth/sign_out';

      if (axios.isCancel(e)) return;
      setError(true);
    })
    return () => cancel();
  }, [query, pageNumber, active])

  return { loading, error, clinics, hasMore }
}
