import React, { useEffect } from 'react';

const AssessmentDetailModal = (props) => {
  const { assessment } = props;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB');
  }
  return (
    <div className="modal fade" id="modal-assessment-msg" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="card-header border-0">
          <span className="text-muted">Requested at {formatDate(assessment?.created_at)}</span>
          <h5 className="mb-0">Message for {assessment?.title}</h5>
        </div>
        <div className="modal-body">
          <p>{assessment?.message}</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AssessmentDetailModal;
