import React from 'react';
import AdminsClinicForm from './form';

class AdminsClinicModal extends React.Component {
  render () {
    return (
      <div className="modal fade" id="modal-clinic-create" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <AdminsClinicForm clinic={this.props.clinic} />
          </div>
        </div>
      </div>
    )
  }
}

export default AdminsClinicModal;