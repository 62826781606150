import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';
import ClinicianReadingModal from './modal/reading';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import './reading.scss';

class CliniciansReadingPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      type: '',
      readings: [],
      selectedReading: null,
      meta: {
        total: 1,
        current: 1
      }
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  handleReadingResult = () => this.fetchData();

  fetchData = () => {
    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/readings',
      headers: JSON.parse(localStorage.getItem('clinician_auth'))
    })
    .then((result) => {
      this.setState({ readings: result.data.readings, meta: result.data.meta })
    })
    .catch((result) => console.log(result))
  }

  loadRecords = (page) => {
    this.setState({isLoading: true})

    let params = {p: page || this.state.meta.current};

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/readings',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: false, meta: result.data.meta, readings: result.data.readings}))
    .catch((result) => console.log(result))
  }

  componentDidMount = () => this.fetchData();

  openEditModal = (reading, type) => {
    this.setState({ selectedReading: reading, type: type }, () => {
      window.$('#modal-reading').modal('show');
    });
  }

  onDeleteReading = (id) => {
    this.setState({
      ...this.state,
      isLoading : true,
    });
    axios({
      method: 'DELETE',
      url: API_SERVER + '/clinicians/readings/' + id,
      headers: JSON.parse(localStorage.getItem('clinician_auth'))
    })
      .then((response) => {
        this.setState({
          ...this.state,
          isLoading : false
        });
        this.fetchData();
      })
      .catch((error) => {
        console.log("delete response", error);
      })
  }

  render () {
    const {
      readings,
      selectedReading
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Readings</h5>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <button className="btn btn-sm btn-white rounded-pill ml-4" type="button" onClick={() => this.openEditModal(null, 'new')} >
                <span className="btn-inner--icon mr-2"><i className="fas fa-plus" /></span>
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="table-responsive">
            <p className="current-goal-title" >Readings</p>
            <table className="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Page</th>
                  <th scope="col">Heading</th>
                  <th scope="col">Quote</th>
                  <th scope="col">Quote page</th>
                  <th scope="col">Main text</th>
                  <th scope="col">Just for today</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
              {
                readings.map((reading, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {reading.date}
                      </td>
                      <td>
                        {reading.page}
                      </td>
                      <td>
                        {reading.heading}
                      </td>
                      <td>
                        <p className="paragraph">
                          {reading.quote}
                        </p>
                      </td>
                      <td>
                        <p className="paragraph">
                          {reading.quote_page}
                        </p>
                      </td>
                      <td>
                        <p className="paragraph main-text">
                          {reading.main_text}
                        </p>
                      </td>
                      <td>
                        <p className="paragraph">
                          {reading.just_for_today}
                        </p>
                      </td>
                      <td style={{minWidth: 235,}}>
                        <div className="row justify-content-between">
                          <div className="col-6">
                            {
                              this.state.isLoading ?
                                <button type="button"
                                        disabled
                                        className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Delete
                                </button> :
                                <button type="button"
                                        onClick={() => this.onDeleteReading(reading.id)}
                                        className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Delete
                                </button>
                            }
                          </div>
                          <div className="col-6">
                            <button type="button"
                              onClick={() => this.openEditModal(reading, 'edit')}
                              className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Edit
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          <ul className="pagination justify-content-center">
            <Pagination
              defaultCurrent={1}
              pageSize={1}
              total={this.state.meta.total}
              defaultPageSize={1}
              onChange={page => {
                this.loadRecords(page)
              }}
              current={this.state.meta.current}
            />
          </ul>
        </div>
        <ClinicianReadingModal result={this.handleReadingResult} selectedReading={selectedReading} type="" />
      </React.Fragment>
    )
  }
}

export default CliniciansReadingPage;
