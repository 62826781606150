import React from "react";
import axios from 'axios';
import { API_SERVER, FORMAT_DATE } from '../../constants';

class AdminsClinicianRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  state = this.props.clinician;

  handleEditClick = (clinician) => {
    this.props.rowResult(clinician);
  };

  handleChangeStatus = (event) => {
    this.setState({active: event.target.checked})

    const formData = new FormData();

    formData.append('clinician[active]', event.target.checked);

    axios({
      method: 'PUT',
      url: API_SERVER + '/admins/clinicians/' + this.state.id + '.json',
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      data: formData
    })
  }

  render() {
    const clinician = this.state

    return (
      <React.Fragment>
        <th scope="row">
          <div className="media align-items-center">
            <div>
              <div className="avatar-parent-child">
                <img
                  alt="Image placeholder"
                  src={clinician.avatar}
                  className="avatar rounded-circle"
                />
                <span className="avatar-child avatar-badge bg-success"></span>
              </div>
            </div>
            <div className="media-body ml-4">
              <a href="#" className="name h6 mb-0 text-sm">
              {clinician.title} {clinician.name} {clinician.surname}
              </a>
            </div>
          </div>
        </th>
        <td>
          <a href="#" className="name h6 mb-0 text-sm">
            {clinician.discipline_title}
          </a>
        </td>
        <td>
          {clinician.clinic.name}
        </td>
        <td>
          <a href={"mailto:" + clinician.email} className="action-item">
            {clinician.email}
          </a>
        </td>
        <td>
            {FORMAT_DATE(clinician.date_of_birth)}
        </td>
        <td>{clinician.has_access_to_medications ? 'Yes' : 'No'}</td>
        <td>
          <div className="custom-control custom-switch">
            <input
              onChange={this.handleChangeStatus}
              type="checkbox"
              className="custom-control-input"
              id={"clinician-" + clinician.id}
              checked={clinician.active}
            />
            <label
              className="custom-control-label"
              htmlFor={"clinician-" + clinician.id}
              data-active="Active"
              data-inactive="Inactive"
            ></label>
          </div>
        </td>
        <td className="text-right">
          <div className="actions ml-3">
            <a
              onClick={() => this.handleEditClick(clinician)}
              className="action-item mr-2"
              data-toggle="modal"
              data-target="#modal-clinician-create"
            >
              <i className="fas fa-pencil-alt"></i>
            </a>
          </div>
        </td>
      </React.Fragment>
    );
  }
}

export default AdminsClinicianRow;
