import React from 'react';
import axios from 'axios';
import { API_SERVER } from '../../../constants';
import './overview.scss';

class OverviewNotificationStatus extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      patient: props.patient,
      notification_current_clinician: props.notification_current_clinician
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    const {
      patient
    } = this.state;

    const url = API_SERVER + '/clinicians/patients/' + patient.id + '/toogle_notification'
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: 'POST',
      url: url,
      headers: headers,
      data: {
        toogle_notification_flag: event.target.value,
        toogle_all: true
      }
    })
    .then((response) => {
      this.props.resultCallback(response.data.data);
      window.$('#modal-notification-rule-setting').modal('hide');
    })
    .catch(() => {
      window.$('#modal-notification-rule-setting').modal('hide');
    })
  }

  render () {
    const {
      patient,
      notification_current_clinician
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Notification Status</h6>
            </div>
            <span className="btn btn-sm btn-icon-only rounded-circle circle-around">
              <i className="fa-regular fa-bell purple fa-lg"></i>
            </span>
          </div>
          <p className="mb-0">Recieve notifications for {patient.name} {patient.surname}</p>
          <div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="notificationCurrentClinician_1"
                name="notification_current_clinician"
                value="true"
                checked={notification_current_clinician.toString() === 'true' }
                onChange={this.handleChange}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="notificationCurrentClinician_1">Yes</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                id="notificationCurrentClinician_2"
                name="notification_current_clinician"
                value="false"
                checked={notification_current_clinician.toString() === 'false'}
                onChange={this.handleChange}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="notificationCurrentClinician_2">No</label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OverviewNotificationStatus;
