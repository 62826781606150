import React from 'react';
import axios from 'axios';
import { API_SERVER, VALID_PASSWORD } from '../../constants';

const ALERT_MESSAGE = (props) => {
  const type = props.type || 'success',
    className = 'alert alert-' + type;

  return <div className={className} role="alert">{props.content}</div>
}

class ClinicianProfilePasswordForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      isLoading: false,
      alertMessage: null,
      alertType: 'success',
      current_password: '',
      password: '',
      password_confirmation: ''
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      current_password,
      password,
      password_confirmation
    } = this.state;

    const method = 'PUT';
    const url = API_SERVER + '/clinicians/auth.json';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));
    const data = {
      current_password: current_password,
      password: password,
      password_confirmation: password_confirmation
    };

    axios({
      method: method,
      url: url,
      data: data,
      headers: headers
    })
    .then(() => {
      this.setState({
        isLoading: false,
        alertMessage: 'Passwords has been changed successfully'
      })
    })
    .catch((error) => {
      let errorMsg = 'Unable to change passwords';

      if (error.response) {
        if (error.response.data.errors) {
          const errors = error.response.data.errors;
          const keys = Object.keys(errors)
          errorMsg = keys.map((key) => errors[key]).join(', ')
        } else {
          errorMsg = error.response.statusText;
        }
      }

      this.setState({
        isLoading: false,
        alertMessage: errorMsg,
        alertType: 'danger'
      })
    })

  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  render () {
    const {
      isLoading,
      alertMessage,
      alertType,
      current_password,
      password,
      password_confirmation
    } = this.state;

    const invalidCurrentPassword = !VALID_PASSWORD.test(current_password) && current_password.length !== 0,
      invalidPassword = !VALID_PASSWORD.test(password) && password.length !== 0,
      invalidPasswordConfirmation = password != password_confirmation,
      invalidForm = invalidCurrentPassword || invalidPassword || invalidPasswordConfirmation || password.length === 0,
      currentPasswordClassName = invalidCurrentPassword ? 'form-control is-invalid' : 'form-control',
      passwordClassName = invalidPassword ? 'form-control is-invalid' : 'form-control',
      passwordConfirmationClassName = invalidPasswordConfirmation ? 'form-control is-invalid' : 'form-control',
      loadingClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <form onSubmit={this.handleSubmit}>
        {alertMessage && <ALERT_MESSAGE type={alertType} content={alertMessage} />}
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label">Old password</label>
                <input
                  onChange={this.handleChange}
                  className={currentPasswordClassName}
                  value={current_password}
                  type="password"
                  autoComplete="current-password"
                  name="current_password" />
                <div className="invalid-feedback">Please provide a valid old password.</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label">New password</label>
                <input
                  onChange={this.handleChange}
                  className={passwordClassName}
                  value={password}
                  type="password"
                  name="password" />
                <div className="invalid-feedback">Please provide a valid password.</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label">Confirm password</label>
                <input
                  onChange={this.handleChange}
                  className={passwordConfirmationClassName}
                  value={password_confirmation}
                  type="password"
                  name="password_confirmation" />
                <div className="invalid-feedback">Please provide a valid password confirmation.</div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button disabled={invalidForm} type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill">
              Update password&nbsp;
              <span className="btn-inner--icon"><i className={loadingClassName} /></span>
            </button>
          </div>
        </div>

      </form>
    )
  }
}

export default ClinicianProfilePasswordForm;
