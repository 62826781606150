import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { API_SERVER } from '../../../../constants';

class ClinicianPatientGoalCommentModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      patient_id: this.props.patientId,
      comment: ""
    }

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange = (event) => this.setState({
    [event.target.name]: event.target.value,
  });

  handleSubmit = event => {
    event.preventDefault();
    const {comment, patient_id, isLoading} = this.state

    if(isLoading)
    {
      return
    }

    this.setState({isLoading: true});

    const url = (API_SERVER + '/clinicians/goal_progress/');
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('comment', comment);
    formData.append('patient_id', patient_id);

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: headers
    })
    .then((res) => {
      $('[data-dismiss]').click()
      this.setState({isLoading: false, comment: ''})
      this.props.reload()
    })
    .catch(error => {
      console.log(error)
    })
  }

  render () {
    const { comment, isLoading } = this.state;
    const iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    const saveDisabled = (comment === "");

    return (
      <div className="modal fade" id="modal-patient-goal-progress-comment" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal"></button>
            <form onSubmit={this.handleSubmit}>
              <div className="container-fluid">
                <div className="row p-3">
                  <h3>Send Message</h3>
                </div>
                <div className="row px-3 pb-3">
                  <textarea name="comment" value={comment} onChange={this.handleChange} className="col rounded"></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-sm btn-primary btn-overprimary" disabled={saveDisabled}>
                  <span className="btn-inner--text">Send&nbsp;</span>
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianPatientGoalCommentModal;
