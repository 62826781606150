import axios from "axios";
import { API_SERVER } from '../../../constants'

export default async function (query, pageNumber) {
  let assessments = [];
  let pagy = {};
  let error = false;
  let loading = true;
  let cancel;

  try {
    let res = await axios({
      method: 'GET',
      url: `${API_SERVER}/clinicians/assessments.json`,
      params: { assessment_template_id: query?.assessment_template_id, patient_id: query?.patient_id, p: pageNumber},
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    });
    assessments = res.data.data;
    pagy = res.data.pagy;
    loading = false;
  } catch (e) {
    if (e.response.status === 401) window.location = '/clinicians/auth/sign_out';

    if (axios.isCancel(e)) return;
    loading = false;
    error = true;
  }

  return {assessments, error, loading, pagy};
}
