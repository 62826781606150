import React, { useEffect, useState } from 'react';
import AdminsAppointmentsRow from './row';

const AdminsAppointmentsPage = ({initial_appointments}) => {
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    const parsedAppointments = JSON.parse(initial_appointments);

    setAppointments(parsedAppointments);
  }, [initial_appointments])

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Appointments</h5>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <a href="/admins/appointments.csv" className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4">
              <span className="btn-inner--icon">
                <i className="fa fa-download" aria-hidden="true"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header actions-toolbar border-0">
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Appointments</h6>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Patient</th>
                <th scope="col">Title</th>
                <th scope="col">Date</th>
                <th scope="col">Start</th>
                <th scope="col">Stop</th>
                <th scope="col">Sent At</th>
                <th scope="col">Clinic</th>
                <th scope="col">Created By</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment) => {
                return (<AdminsAppointmentsRow key={appointment.id} {...appointment} />)
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default AdminsAppointmentsPage;
