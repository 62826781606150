import React from 'react';
import axios from 'axios';
import CliniciansPatientsMedicationRow from './rows/medication';
import CliniciansPatientsAllergyRow from './rows/allergy';
import ClinicianMedicationModal from './modals/medication';
import ClinicianAllergyModal from './modals/allergy';
import './medication.scss';
import ClinicianMedicationRecordModal from './modals/medication-record';
import { API_SERVER } from '../../../constants';

const rowSpinner = <tr><td><i className="fa fa-spinner fa-spin" /></td></tr>;

class CliniciansPatientsMedicationPage extends React.Component {
  state = {
    isLoading: null,
    medications: [],
    medication: {
      id: null,
      patient_id: this.props.patient.id,
      name: '',
      dosage: ''
    },
    allergies: [],
    allergy: {
      id: null,
      patient_id: this.props.patient.id,
      name: '',
      reaction: ''
    }
  }

  handlAllergyResult = (allergy, action = 'add') => {
    if (action === 'add') {
      this.setState({allergies: [allergy].concat(this.state.allergies)})
    }

    if (action === 'update') {
      const allergies = this.state.allergies.filter((el) => (el.id !== allergy.id));

      this.setState({allergies: [allergy].concat(allergies)})
    }

    if (action === 'delete') {
      const allergies = this.state.allergies.filter((el) => (el.id !== allergy.id));

      this.setState({allergies: allergies});
    }
  };

  handleClickedMedication = (medication) => this.setState({medication: medication});

  handleMedicationResult = (medication, action = 'add') => this.fetchData('medications');

  handleMedicationRow = (medication) => this.setState({medication: medication});

  handleAllergyRow = (allergy) => this.setState({allergy: allergy});

  handleTabClick = (tab) => this.fetchData(tab);

  handleNewClick = (type) => {
    if (type === 'medication') {
      this.setState({
        medication: {
          id: null,
          patient_id: this.props.patient.id,
          clinic_id: this.props.patient.clinic_id,
          name: '',
          dosage: '',
          schedules: [{
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            time_at: new Date()
          }]
        }
      });
      window.$('#modal-medication').modal('show');
    }

    if (type === 'allergy') {
      this.setState({
        allergy: {
          id: null,
          patient_id: this.props.patient.id,
          name: '',
          reaction: ''
        }
      });
      window.$('#modal-allergy').modal('show');
    }
  }

  fetchData = (tab) => {
    this.setState({isLoading: currentTab})

    const currentTab = this.getTab(tab);

    let params = {};
    params['q[patient_id_eq]'] = this.props.patient.id;

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/' + currentTab + '.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: null, [currentTab]: result.data}))
    .catch((result) => console.log(result))
  }

  getTab = (tab) => {
    return ['medications', 'allergies'].indexOf(tab) > 0 ? tab : 'medications'
  }

  componentDidMount = () => this.fetchData('medications')

  render () {
    const {
      isLoading,
      medications,
      medication,
      allergies,
      allergy
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-title card-responsive">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Medications</h5>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <div className="dropdown">
                <button className="btn btn-sm btn-white rounded-pill ml-4" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="btn-inner--icon mr-2"><i className="fas fa-plus" /></span>
                  Add new
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a onClick={() => this.handleNewClick('medication')} href="#" className="dropdown-item">
                    <i className="fas fa-pills" />Medication
                  </a>
                  <a onClick={() => this.handleNewClick('allergy')} href="#" className="dropdown-item">
                    <i className="fas fa-exclamation-circle" />Allergy
                  </a>
                </div>
              </div>
              <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                <i className="fas fa-bars" />
              </a>
            </div>
          </div>
        </div>
        <div className="card card-responsive">
          <div className="card-header actions-toolbar border-0">
            <div className="actions-search" id="actions-search">
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-transparent"><i className="fas fa-search" /></span>
                </div>
                <input type="text" className="form-control form-control-flush" placeholder="Type and hit enter ..." />
                <div className="input-group-append">
                  <a href="#" className="input-group-text bg-transparent" data-action="search-close" data-target="#actions-search"><i className="fas fa-times" /></a>
                </div>
              </div>
            </div>
            <div className="row justify-content-between align-items-center">
              <div className="col">
                <ul className="nav" role="tablist">
                  <li className="nav-item">
                    <a href="#medication-current" onClick={() => this.handleTabClick('medications')} className="nav-link pl-0 active" data-toggle="pill" href="#medication-current" role="tab" aria-controls="medication-current" aria-selected="true">Current Medication</a>
                  </li>
                  <li className="nav-item">
                    <a href="#medication-allergy" onClick={() => this.handleTabClick('allergies')} className="nav-link" data-toggle="pill" href="#medication-allergy" role="tab" aria-controls="medication-allergy" aria-selected="false">Allergy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content mh-680">
            <div id="medication-current" className="tab-pane fade show active" role="tabpanel" aria-labelledby="medication-current-tab">
              <div className="table-responsive">
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Allergy Name</th>
                      <th scope="col">Dosage</th>
                      <th scope="col">Created by</th>
                      <th scope="col">Created on</th>
                      <th scope="col">Reminder Time</th>
                      <th scope="col" className="text-center">Confirm Record</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading === 'medications' ? rowSpinner : <CliniciansPatientsMedicationRow medications={medications} clickedMedication={this.handleClickedMedication} clickedRow={this.handleMedicationRow} />}
                  </tbody>
                </table>
              </div>
            </div>
            <div id="medication-allergy" className="tab-pane fade" role="tabpanel" aria-labelledby="medication-allergy-tab">
              <div className="table-responsive">
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Medication Name</th>
                      <th scope="col">Reaction</th>
                      <th scope="col">Created</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading === 'allergies' ? rowSpinner : <CliniciansPatientsAllergyRow allergies={allergies} clickedRow={this.handleAllergyRow} />}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <ClinicianMedicationModal result={this.handleMedicationResult} medication={medication} />
        <ClinicianAllergyModal result={this.handlAllergyResult} allergy={allergy} />
        <ClinicianMedicationRecordModal medication={medication} />
      </React.Fragment>
    )
  }
}

export default CliniciansPatientsMedicationPage;
