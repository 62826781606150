import React, { useEffect, useState } from 'react';
import AssessmentResultChart from './assessment-result-chart';

const AssessmentChartModal = (props) => {
  const { assessmentTemplate, patient } = props;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const setDateRange = (data) => {
    if (data.length) {
      setStartDate(formatDate(data[0]?.created_at));
      setEndDate(formatDate(data[data.length - 1]?.created_at));
    }
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB');
  }

  return (
    <div className="modal fade" id="modal-patient-assessment-chart" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="card-header border-0">
            <strong className="text-muted">{assessmentTemplate.name}</strong>
            <h6 className="mb-0">{startDate} - {endDate}</h6>
          </div>
          <div className="modal-body">
            <AssessmentResultChart patient={patient} assessmentTemplateId={assessmentTemplate.value} minScore={assessmentTemplate.min_score} maxScore={assessmentTemplate.max_score} height="280" onLoadedData={setDateRange}/>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentChartModal;
