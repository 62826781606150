import React from 'react';
import './wellbeing-courses.css';
import { API_SERVER } from "../../../constants";
import axios from 'axios';


class WellbeingCourses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enabledCourses : [],
      isLoading : false,
      patient_id : this.props.patient ? this.props.patient.id : 0,
      clinician_id : this.props.patient.clinician_id || 0,
      userWellbeingCourseId : 0,
      wellbeingCourses : [],
    }
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchWellbeingCourses = () => {
    axios({
      method : 'GET',
      url : API_SERVER + '/wellbeing_courses/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      this.setState({
        ...this.state,
        wellbeingCourses : res.data,
      });
    }).catch((error) => {
      console.log("wellbeing courses fetch error", error);
    });
  }

  fetchUserWellbeingCourses = () => {
    const params = {'q[patient_id_eq]': this.state.patient_id};
    axios({
      method : 'GET',
      url : API_SERVER + '/clinicians/user_wellbeing_courses/',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
      params : params
    }).then((res) => {
      console.log("response user wellbeing courses", res.data);
      this.setState({
        ...this.state,
        enabledCourses : JSON.parse(res.data[0].wellbeing_courses),
        userWellbeingCourseId : res.data[0].id,
      });
    }).catch((error) => {
      console.log("user wellbeing courses fetch error", error);
    });
  }

  fetchData = async () => {
    await this.fetchWellbeingCourses();
    await this.fetchUserWellbeingCourses();
  }

  setEnabledCourses = (courses) => {
    this.setState({
      ...this.state,
      enabledCourses : courses,
    });
  }

  onChangeCheckBox = (e, index) => {
    const isChecked = e.target.checked;
    let tempEnabledCourses = [...this.state.enabledCourses];
    const targetIndex = tempEnabledCourses.indexOf(index+1);

    if(isChecked && targetIndex === -1) {
      this.setEnabledCourses([...tempEnabledCourses, index+1]);
    } else if(!isChecked && targetIndex !== -1) {
      tempEnabledCourses.splice(targetIndex, 1);
      this.setEnabledCourses(tempEnabledCourses);
    }
  }

  handleSaveCourses = () => {

    if(!this.state.userWellbeingCourseId) {
      this.fetchData();
    }

    this.setState({...this.state, isLoading : true});

    const request = {
      "id": this.state.userWellbeingCourseId,
      "clinician_id": this.state.clinician_id,
      "patient_id": this.state.patient_id,
      "wellbeing_courses": JSON.stringify(this.state.enabledCourses),
    }
    axios({
      method : 'POST',
      url : API_SERVER + '/clinicians/user_wellbeing_courses/',
      data : request,
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      console.log("response update user courses", res);
      this.setEnabledCourses(JSON.parse(res.data.wellbeing_courses));
      this.setState({...this.state, isLoading: false});
    }).catch((error) => {
      console.log("user well being courses update error", error);
      this.setState({...this.state, isLoading: false});
    });

  }

  render () {
    const {wellbeingCourses, enabledCourses, isLoading} = this.state;
    const loadingClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';
    return (
      <>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="col col-lg-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Wellbeing Courses</h5>
              </div>
            </div>
            <div className="col-auto col-lg-6 d-flex align-items-center justify-content-between justify-content-md-end">
              <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                <i className="fas fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="card card-container mt-6">
          <div className="date-container col text-dark font-weight-600 font-size-md mt-4 mb-1">
            <div className="col-md-9">
              <span>Course Title</span>
            </div>
            <div className="col-md-3 text-center">
              <span>Turn On/Off</span>
            </div>
          </div>
          <div className="text-dark">
            {
              wellbeingCourses.map((item, index) => {
                let classname = "flex";
                if(index % 2 === 0) { classname += " grey-background"; }
                return (
                  <div className={classname} key={index}>
                    <div className="col-md-9 respond-container font-weight-400 font-size-sm">
                      <span>{item.title}</span>
                    </div>
                    <div className="col-md-3 flex justify-center align-items-center" >
                      <input type="checkbox"
                             checked={ enabledCourses && (enabledCourses.indexOf(index+1) !== -1) ? true : false }
                             value={item.title}
                             onChange={(e) => this.onChangeCheckBox(e, index)}
                      />
                    </div>
                  </div>
                );
              })
            }
          </div>

          <div className="mt-4 mr-4 flex justify-end">
            <button onClick={this.handleSaveCourses} className="btn btn-sm btn-primary btn-overprimary rounded-pill">Save&nbsp;
              <span className="btn-inner--icon"><i className={loadingClassName} /></span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default WellbeingCourses;
