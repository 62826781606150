import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  ACTIVE_STATUSES,
  DISCIPLINE_TYPES,
  INVALID_DATE,
  API_SERVER
} from '../../constants';

const CliniciansFriendsModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [id, setId] = useState();
  const [active, setActive] = useState(true);
  const [avatar, setAvatar] = useState('');
  const [discipline, setDiscipline] = useState('');
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [dd, setDd] = useState('');
  const [mm, setMm] = useState('');
  const [yyyy, setYyyy] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [has_access_to_medications, setHas_access_to_medications] = useState(true);
  const [is_admin, setIs_admin] = useState(true);
  const {clinician, currentClinician} = props;

  useEffect(() => {
    const {
      id,
      active,
      discipline,
      title,
      name,
      surname,
      date_of_birth,
      email,
      has_access_to_medications,
      is_admin
    } = clinician;

    setId(id);
    setActive(active);
    setDiscipline('' + discipline);
    setTitle('' + title);
    setName('' + name);
    setSurname('' + surname);
    setEmail('' + email);
    setPassword('');
    setHas_access_to_medications(has_access_to_medications);
    setIs_admin(is_admin);

    if (!INVALID_DATE(date_of_birth)) {
      const dates = date_of_birth.split('-');

      setDd(dates[2]);
      setMm(dates[1]);
      setYyyy(dates[0]);
    } else {
      setDd('');
      setMm('');
      setYyyy('');
    }
  }, [clinician]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    const url = id ? `${API_SERVER}/clinicians/friends/${id}.json` : `${API_SERVER}/clinicians/friends.json`;
    const method = id ? 'PUT' : 'POST';
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('clinician[active]', active);
    formData.append('clinician[discipline]', discipline);
    formData.append('clinician[title]', title);
    formData.append('clinician[name]', name);
    formData.append('clinician[surname]', surname);
    formData.append('clinician[email]', email);
    formData.append('clinician[has_access_to_medications]', has_access_to_medications);
    formData.append('clinician[is_admin]', is_admin);

    const date_of_birth = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date_of_birth)) {
      formData.append('clinician[date_of_birth]', date_of_birth);
    }

    if (avatar != '') {
      headers['content-type'] = 'multipart/form-data';
    }

    if (password.length >= 6) {
      formData.append('clinician[password]', password);
    }

    if (!id) {
      formData.append('clinician[author_id]', currentClinician?.id);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers
    })
    .then(() => location.reload())
    .catch(error => {
      setLoading(false)

      const errorResponse = error.response.data.errors
      setServerErrors(errorResponse);
    })
  }

  const handleDelete = () => {
    const confirm = window.confirm("Are you sure want to delete this clinician?");

    if (confirm) {
      axios({
        method: 'DELETE',
        url: `${API_SERVER}/clinicians/friends/${id}.json`,
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
      .then(() => window.location.reload())
      .catch((response) => console.log(response))
    }

  }

  const generatePassword = () => {
    const generator = require('generate-password');
    const generatedPwd = generator.generate({ length: 6, numbers: true });

    setPassword(generatedPwd);
  }

  const iconClassName = loading ? "fa fa-spinner fa-spin" : "fas fa-long-arrow-alt-right";

  return (
    <>
      <div className="modal fade" id="modal-clinician-create" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
              { currentClinician?.is_admin && (
                  <div className="form-group">
                    <label className="form-control-label">Status</label>
                    <select
                      name="active"
                      value={active}
                      onChange={(e) => setActive(e.target.value)}
                      className="form-control">
                        {ACTIVE_STATUSES.map((st, i) => {
                          return <option key={i} value={st.value}>{st.name}</option>
                        })}
                    </select>
                  </div>
                )
              }
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Discipline</label>
                      <select
                        name="discipline"
                        value={discipline}
                        onChange={(e) => setDiscipline(e.target.value)}
                        className="form-control">
                          <option>Select discipline</option>
                          {DISCIPLINE_TYPES.map((el, i) => {
                            return <option key={i} value={el.value}>{el.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Title</label>
                      <input
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                        placeholder="Dr."/>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          serverErrors.name = [];
                          setServerErrors(serverErrors);
                        }}
                        className={serverErrors.name?.length > 0 ? 'form-control is-invalid' : 'form-control'}/>
                      <div className="invalid-feedback">{serverErrors.name?.length > 0 && serverErrors.name.join()}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Surname</label>
                      <input
                        type="text"
                        name="surname"
                        value={surname}
                        onChange={(e) => {
                          setSurname(e.target.value);
                          serverErrors.surname = [];
                          setServerErrors(serverErrors);
                        }}
                        className={serverErrors.surname?.length > 0 ? 'form-control is-invalid' : 'form-control'}/>
                      <div className="invalid-feedback">{serverErrors.surname?.length > 0 && serverErrors.surname.join()}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          serverErrors.email = [];
                          setServerErrors(serverErrors);
                        }}
                        className={serverErrors.email?.length > 0 ? 'form-control is-invalid' : 'form-control'}
                        placeholder="name@domain.com"/>
                      <div className="invalid-feedback">{serverErrors.email?.length > 0 && serverErrors.email.join()}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Password</label>
                      <div className="input-group input-group-merge">
                        <input
                          type="text"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            serverErrors.password = [];
                            setServerErrors(serverErrors);
                          }}
                          name="password"
                          className={serverErrors.password?.length > 0 ? 'form-control is-invalid' : 'form-control'} />
                        <div className="invalid-feedback">{serverErrors.password?.length > 0 && serverErrors.password.join()}</div>
                        <div className="input-group-append">
                          <span
                            onClick={generatePassword}
                            style={{cursor: 'pointer'}}
                            className="input-group-text">
                            <i className="fas fa-sync"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p>
                  The worker you are adding will be sent an automated email from HARP+ with their temporary password &amp; login information.
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-control-label">Birthday</label>
                  <div className="form-row">
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="DD"
                        type="number"
                        name="dd"
                        value={dd}
                        onChange={(e) => setDd(e.target.value)}
                        min="1"
                        max="31"/>
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="MM"
                        type="number"
                        name="mm"
                        value={mm}
                        onChange={(e) => setMm(e.target.value)}
                        min="1"
                        max="12"/>
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        placeholder="YYYY"
                        type="number"
                        name="yyyy"
                        value={yyyy}
                        onChange={(e) => setYyyy(e.target.value)}
                        min="1920"
                        max={new Date().getFullYear()}/>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-control-label">
                    Has Access to Medications in Worker Portal
                  </label>
                  <div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="hasAccessed_1"
                        name="has_access_to_medications"
                        value="true"
                        checked={has_access_to_medications?.toString() === 'true' }
                        onChange={(e) => setHas_access_to_medications(e.target.value)}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="hasAccessed_1">Yes</label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="hasAccessed_2"
                        name="has_access_to_medications"
                        value="false"
                        checked={has_access_to_medications?.toString() === 'false'}
                        onChange={(e) => setHas_access_to_medications(false)}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" htmlFor="hasAccessed_2">No</label>
                    </div>
                  </div>
                </div>
                { currentClinician?.is_admin && (
                    <div className="form-group">
                      <label className="form-control-label">
                        Admin Privileges
                      </label>
                      <div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="isAdmin_1"
                            name="is_admin"
                            value="true"
                            checked={is_admin?.toString() === 'true' }
                            onChange={(e) => setIs_admin(e.target.value)}
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="isAdmin_1">Yes</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="isAdmin_2"
                            name="is_admin"
                            value="false"
                            checked={is_admin?.toString() === 'false'}
                            onChange={(e) => setIs_admin(false)}
                            className="custom-control-input"
                          />
                          <label className="custom-control-label" htmlFor="isAdmin_2">No</label>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="modal-footer">
                {id && (
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="btn btn-sm btn-link text-danger px-2 mr-auto">
                    Delete
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary rounded-pill"
                  data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-primary btn-overprimary rounded-pill">
                  <span className="btn-inner--text">Save&nbsp;</span>
                  <span className="btn-inner--icon">
                    <i className={iconClassName} />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default CliniciansFriendsModal;