import React from 'react';

const CrisisServiceRow = (props) => {
  const { name, phone, handleDelete, handleEdit } = props;

  return (
    <>
      <td scope="row">
        <span className="h6 text-sm">{name}</span>
      </td>
      <td>{phone}</td>
      <td className="text-right">
        <div className="actions ml-3">
          <a href="#modal-crisis-support-service-create" onClick={handleEdit} className="action-item mr-2" data-toggle="modal">
            <i className="fas fa-pencil-alt"></i>
          </a>
          <a href="#confirm-modal" onClick={handleDelete} className="action-item text-danger mr-2" data-toggle="modal">
            <i className="fas fa-trash"></i>
          </a>
        </div>
      </td>
    </>
  )
}

export default CrisisServiceRow;
