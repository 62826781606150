import React, { useState, useEffect } from 'react';
import { API_SERVER, VALID_PHONE } from '../../constants';
import axios from 'axios';

const SupportServiceForm = (props) => {
  const [id, setId] = useState('');
  const [area, setArea] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [website, setWebsite] = useState('');
  const [hasError, setHasError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.supportService) {
      const supportService = props.supportService;
      setId(supportService.id);
      setArea(supportService.area);
      setName(supportService.name);
      setPhone(supportService.phone);
      setWebsite(supportService.website);
    } else {
      setId('');
      setArea('');
      setName('');
      setPhone('');
      setWebsite('');
    }
  }, [props.supportService]);

  useEffect(() => {
    (!area || !name || !phone || !website) ? setHasError(true) : setHasError(false);
  }, [area, name, phone, website]);

  const handleSubmit = () => {
    event.preventDefault();
    setIsLoading(true);

    const url = id ? `${API_SERVER}/clinicians/support_services/${id}.json` : `${API_SERVER}/clinicians/support_services.json`;
    const method = id ? 'PUT' : 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    let formData = new FormData();
    formData.append('support_service[area]', area);
    formData.append('support_service[name]', name);
    formData.append('support_service[phone]', phone);
    formData.append('support_service[website]', website);

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  const validPhone = phone && VALID_PHONE.test(phone);

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <label className="form-control-label">Area of Support</label>
          <input
            value={area}
            name="area"
            onChange={() => setArea(event.target.value)}
            type="text"
            className={`form-control ${area ? 'is-valid' : 'is-invalid'}`} />
          <div className="invalid-feedback">Please provide a valid area.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Name of Provider</label>
          <input
            value={name}
            name="name"
            onChange={() => setName(event.target.value)}
            type="text"
            className={`form-control ${name ? 'is-valid' : 'is-invalid'}`} />
          <div className="invalid-feedback">Please provide a valid name.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Phone Number</label>
          <input
            value={phone}
            name="phone"
            onChange={() => setPhone(event.target.value)}
            type="text"
            className={`form-control ${validPhone ? 'is-valid' : 'is-invalid'}`} />
            <div className="invalid-feedback">Please provide a valid phone.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Website Address</label>
          <input
            value={website}
            name="website"
            onChange={() => setWebsite(event.target.value)}
            type="text"
            className={`form-control ${website ? 'is-valid' : 'is-invalid'}`} />
          <div className="invalid-feedback">Please provide a valid website.</div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={hasError && !validPhone} >
          <span className="btn-inner--text">Save&nbsp;</span>
          <span className="btn-inner--icon"><i className={isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right'} /></span>
        </button>
      </div>
    </form>
  )
}

export default SupportServiceForm;
