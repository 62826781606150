import React from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { API_SERVER } from '../../../constants';

class MoodChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: props.patient.charts.moods.ydata || [],
      xData: props.patient.charts.moods.xdata || []
    } 
  }

  componentDidMount = () => {
    return;
    this.setState({isLoading: true});

    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/moods.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: { 'q[patient_id_eq]': this.props.patient.id }
    })
    .then((result) => {
      const data = result.data;

      this.setState({
        isLoading: false,
        data: data.map((el) => el.score),
        xData: data.map((el) => {
          const date = new Date(el.date);
          const dateRow = date.toDateString().split(' ');
          const groupedBy = [dateRow[1], dateRow[2]].join(' ')

          return groupedBy;
        })
      })
    })
    .catch((result) => console.log(result))
  }

  render () {
    const options = {
      chart: {
        type: 'area',
        height: 30,
        sparkline: {
          enabled: true
        }
      },
      xaxis: {
        categories: this.state.xData
      }
    }

    const series = [{name: 'Score', data: this.state.data}],
      renderChart = this.state.isLoading ? <span className="btn-inner--icon"><i className="fa fa-spinner fa-spin" /></span> : <Chart options={options} series={series} type="line" />;

    return renderChart;
  }
}

export default MoodChart;