import React from 'react';
import { API_SERVER } from '../../constants';
import Pagination from 'rc-pagination'
import axios from 'axios';
import 'rc-pagination/assets/index.css';

class UsageStatisticsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usage_statistics : [],
      isLoading : false,
      meta: {
        total: 1,
        current: 1
      }
    }
  }

  componentDidMount() {    
    this.fetchUsageStatistics();
  };

  fetchUsageStatistics = () => {
    let param = {};
    axios({
      method : 'GET',
      url : API_SERVER + '/admins/usage_statistics',
      headers : JSON.parse(localStorage.getItem('admin_auth')),
    }).then((res) => {
      this.setState({ usage_statistics: res.data.usage_statistics });
    }).catch((error) => {
      console.log("usage_statistics fetch error", error);
    });
  }

  loadRecords = (page) => {

    let params = {p: page || this.state.meta.current};

    axios({
      method: 'GET',
      url: API_SERVER + '/admins/usage_statistics',
      headers: JSON.parse(localStorage.getItem('admin_auth')),
      params: params
    })
    .then((result) => this.setState({isLoading: false, meta: result.data.meta, data: result.data.feedbacks}))
    .catch((result) => console.log(result))
  }

  render () {
    const {
      usage_statistics
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-title">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
              <div className="d-inline-block">
                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">
                  Usage Statistics
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header actions-toolbar border-0">
          </div>
          <div className="table-responsive">
            <table className="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">Participant ID</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Number of times app opened</th>
                  <th scope="col">Number of times home screen opened</th>
                  <th scope="col">Date of last open</th>
                </tr>
              </thead>
              <tbody>
                {usage_statistics.map((usage_statistic, i) => {
                  return (
                    <tr key={usage_statistic.id}>
                      <td>{ usage_statistic.participant_id }</td>
                      <td>{ usage_statistic.created_on }</td>
                      <td>{ usage_statistic.number_of_time_app_opened }</td>
                      <td>{ usage_statistic.number_of_times_home_screen_opened }</td>
                      <td>{ usage_statistic.date_of_last_open }</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <ul className="pagination justify-content-center">
              <Pagination
                defaultCurrent={1}
                pageSize={1}
                total={this.state.meta.total}
                defaultPageSize={1}
                onChange={page => {
                  this.loadRecords(page)
                }}
                current={this.state.meta.current}
              />
            </ul>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default UsageStatisticsList;
