import React from 'react';
import axios from 'axios';
import select2 from 'select2';
import {
  API_SERVER,
  VALID_EMAIL,
  DISCIPLINE_TYPES,
  INVALID_DATE
} from '../../constants';

const ALERT_MESSAGE = (props) => {
  const type = props.type || 'success',
    className = 'alert alert-' + type;

  return <div className={className} role="alert">{props.content}</div>
}

class ClinicianProfileForm extends React.Component {
  constructor(props) {
    super(props)

    const clinician = this.props.clinician || {};

    this.state = {
      isLoading: false,
      alertMessage: null,
      alertType: 'success',
      id: clinician.id  || null,
      discipline: clinician.discipline || '',
      title: clinician.title || '',
      name: clinician.name || '',
      surname: clinician.surname || '',
      email: clinician.email || '',
      date_of_birth: clinician.date_of_birth || '',
      access_token: clinician.microsoft_graph_access_token || null,
      dd: '', mm: '', yyyy: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.selectPicker = React.createRef();
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({isLoading: true});

    const {
      discipline,
      title,
      name,
      surname,
      email,
      dd, mm, yyyy
    } = this.state;

    const method = 'PUT';
    const url = API_SERVER + '/clinicians/profile.json';
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    const formData = new FormData();

    formData.append('clinician[discipline]', discipline);
    formData.append('clinician[title]', title);
    formData.append('clinician[name]', name);
    formData.append('clinician[surname]', surname);

    const date_of_birth = [yyyy, mm, dd].join('-');

    if (!INVALID_DATE(date_of_birth)) {
      formData.append('clinician[date_of_birth]', date_of_birth);
    } else {
      formData.append('clinician[date_of_birth]', '');
    }

    formData.append('clinician[email]', email);

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers
    })
    .then(() => {
      this.setState({
        isLoading: false,
        alertMessage: 'Profile has been saved successfully'
      })
    })
    .catch(() => {
      this.setState({
        isLoading: false,
        alertMessage: 'Profile unable to be saved',
        alertType: 'danger'
      })
    })
  }

  componentDidMount = () => {
    const date_of_birth = this.props.clinician.date_of_birth;

    if (!INVALID_DATE(date_of_birth)) {
      const dates = date_of_birth.split('-');

      this.setState({ dd: dates[2], mm: dates[1], yyyy: dates[0] });
    }

    $(this.selectPicker.current).select2();

    $(this.selectPicker.current).select2().on('select2:select', (event) => {
      this.setState({[event.target.name]: event.target.value})
    });

    if(this.props.clinician.discipline !== '')
    {
      const defaultDisciplineSelected = DISCIPLINE_TYPES.find(item => item.value === this.props.clinician.discipline)

      if(defaultDisciplineSelected)
      {
        $(this.selectPicker.current).val(defaultDisciplineSelected.value).trigger('change');
      }
    }
  }

  render () {
    const {
      isLoading,
      alertMessage,
      alertType,
      title,
      name,
      surname,
      email,
      access_token,
      dd, mm, yyyy
    } = this.state,
      nameInvalid = name.length === 0,
      surnameInvalid = surname.length === 0,
      emailInvalid = !VALID_EMAIL.test(email),
      nameClassName = nameInvalid ? 'form-control is-invalid' : 'form-control',
      surnameClassName = surnameInvalid ? 'form-control is-invalid' : 'form-control',
      emailClassName = emailInvalid ? 'form-control is-invalid' : 'form-control',
      loadingClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <form onSubmit={this.handleSubmit}>
        {alertMessage && <ALERT_MESSAGE type={alertType} content={alertMessage} />}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Discipline</label>
              <select onChange={this.handleChange} name="discipline" ref={this.selectPicker} className="form-control">
                {DISCIPLINE_TYPES.map((el, index) => {
                  return <option key={index} value={el.value}>{el.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Title</label>
              <input
                onChange={this.handleChange}
                name="title"
                value={title}
                className="form-control"
                type="text"
                placeholder="Enter your title" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Name</label>
              <input
                onChange={this.handleChange}
                name="name"
                value={name}
                className={nameClassName}
                type="text"
                placeholder="Enter your name" />
              <div className="invalid-feedback">Please provide a valid name.</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Surname</label>
              <input
                onChange={this.handleChange}
                name="surname"
                value={surname}
                className={surnameClassName}
                type="text"
                placeholder="Also your surname" />
              <div className="invalid-feedback">Please provide a valid surname.</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Birthday</label>
              <div className="form-row">
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="DD"
                    type="number"
                    onChange={this.handleChange}
                    name="dd"
                    value={dd}
                    min="1"
                    max="31" />
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="MM"
                    type="number"
                    onChange={this.handleChange}
                    name="mm"
                    value={mm}
                    min="1"
                    max="12" />
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    placeholder="YYYY"
                    type="number"
                    onChange={this.handleChange}
                    name="yyyy"
                    value={yyyy}
                    min="1920"
                    max={new Date().getFullYear()} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-control-label">Email</label>
              <input
                onChange={this.handleChange}
                className={emailClassName}
                name="email"
                value={email}
                type="email"
                placeholder="name@exmaple.com" />
              <small className="form-text text-muted mt-2">This is the email address we will send notifications to & sync with Outlook Calendar.</small>
              <div className="invalid-feedback">Please provide a valid email.</div>
            </div>
            { access_token ? <a className="btn btn-sm btn-primary btn-overprimary rounded-pill" >Synced with Outlook Calendar</a> : <a href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=f1618a5a-027c-4552-8535-c2b0c1822856&response_type=code&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite" className="btn btn-sm btn-primary btn-overprimary rounded-pill" >Sync with Outlook Calendar</a>}
          </div>
        </div>
        <hr />
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill">
          Save changes&nbsp;
          <span className="btn-inner--icon"><i className={loadingClassName} /></span>
        </button>
      </form>
    )
  }
}

export default ClinicianProfileForm;