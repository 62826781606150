import React, { useState, useEffect } from 'react';
import { API_SERVER } from '../../../constants';
import axios from 'axios';

const CorrespondenceForm = (props) => {
  const [id, setId] = useState();
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [document, setDocument] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(true);
  const { onDelete, correspondence, patient } = props;

  useEffect(() => {
    if (correspondence) {
      setId(correspondence.id);
      setLabel(correspondence.label);
      setDescription(correspondence.description);
    } else {
      setId('');
      setLabel('');
      setDescription('');
      setDocument('');
    }
  }, [props.correspondence]);

  useEffect(() => {
    if (id) {
      (!label || !description) ? setHasError(true) : setHasError(false);
    } else {
      (!label || !description || !document) ? setHasError(true) : setHasError(false);
    }
  }, [label, description, document]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const url = id ? `${API_SERVER}/clinicians/correspondences/${id}.json` : `${API_SERVER}/clinicians/correspondences.json`;
    const method = id ? 'PUT' : 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('correspondence[label]', label);
    formData.append('correspondence[description]', description);
    if (!id) {
      formData.append('correspondence[patient_id]', patient.id);
    }
    if (document) {
      formData.append('correspondence[document]', document);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  const iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <label className="form-control-label">Title</label>
          <input
            value={label}
            onChange={() => setLabel(event.target.value)}
            type="text"
            className={`form-control ${label ? 'is-valid' : 'is-invalid'}`}
            placeholder="Enter the title" />
          <div className="invalid-feedback">Please provide a valid title.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Description</label>
          <textarea
            value={description}
            onChange={() => setDescription(event.target.value)}
            className={`form-control form-control-alternative edit-event--description textarea-autosize ${description ? 'is-valid' : 'is-invalid'}`}
            data-toggle="autosize"
            placeholder="Enter description for this document"></textarea>
          <div className="invalid-feedback">Please provide a valid description.</div>
        </div>
        <div className="form-group">
          <label className="form-control-label">Upload File</label>
          <input
            accept="application/pdf"
            name="document"
            onChange={() => setDocument(event.target.files[0])}
            className={`form-control ${id || document ? 'is-valid' : 'is-invalid'}`}
            type="file"/>
          <div className="invalid-feedback">Please provide a valid document.</div>
        </div>
      </div>
      <div className="modal-footer">
        {id && <a href="#confirm-modal" onClick={() => onDelete(id)} className="btn btn-sm text-danger mr-auto pl-0" data-dismiss="modal" data-toggle="modal">Delete</a>}
        <button type="button" className="btn btn-sm btn-link" data-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={hasError}>
          <span className="btn-inner--text">Save&nbsp;</span>
          <span className="btn-inner--icon"><i className={iconClassName} /></span>
        </button>
      </div>
    </form>
  )
}

export default CorrespondenceForm;
