import React from 'react';
import flatpickr from 'flatpickr';
import select2 from 'select2';
import axios from 'axios';
import { API_SERVER, FORMAT_DATE } from '../../constants';
import './zoom_meeting_schedule.css';

const initialState = {
  isLoading: false,
  id: null,
  clinician_id: '',
  zoom_link: '',
  date: '',
  start: ''
}

class ClinicianZoomMeetingScheduleModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = initialState;

    this.clinicianPicker = React.createRef();
    this.selectPicker = React.createRef();
    this.datePicker = React.createRef();
    this.startTimePicker = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRepeatStatus = this.handleChangeRepeatStatus.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.handleStartPicker = this.handleStartPicker.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteAllRepeatedMeeting = this.handleDeleteAllRepeatedMeeting.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({isLoading: true});

    const {
      id,
      zoom_link,
      date,
      start,
      is_repeat
    } = this.state;

    const method = id ? 'PUT' : 'POST';
    const url = id ? (API_SERVER + '/clinicians/zoom_meeting_schedules/' + id + '.json') : (API_SERVER + '/clinicians/zoom_meeting_schedules');
    const headers = JSON.parse(localStorage.getItem('clinician_auth'));

    axios({
      method: method,
      url: url,
      headers: headers,
      data: {
        zoom_meeting_schedule: {
          zoom_link: zoom_link,
          date: date,
          start: start,
          is_repeat: is_repeat
        }
      }
    })
    .then((response) => {
      const data = response.data;

      let result = { data: data };

      if (id) {
        result.update = true;
        result.create = false;
      } else {
        result.create = true;
        result.update = false;
      }

      this.props.resultCallback(result);
      window.$('#new-event-zoom').modal('hide');
    })
    .catch(() => {
      window.$('#new-event-zoom').modal('hide');
    })
  }

  handleChange = (event) => this.setState({[event.target.name]: event.target.value});

  handleChangeRepeatStatus = (event) => {
    this.setState({is_repeat: event.target.checked})
  }

  handleDelete = (event_id) => {
    this.setState({isLoading: true});

    axios({
      method: 'DELETE',
      url: API_SERVER + '/clinicians/zoom_meeting_schedules/' + event_id + '.json',
      headers: JSON.parse(localStorage.getItem('clinician_auth'))
    })
    .then((response) => {
      const result = {delete: true, data: response.data};
      this.props.resultCallback(result);

      window.$('#new-event-zoom').modal('hide');
    })
    .catch(() => {
      window.$('#new-event-zoom').modal('hide');
    })
  };

  handleDeleteAllRepeatedMeeting = (event_id) => {
    this.setState({isLoading: true});

    axios({
      method: 'DELETE',
      url: API_SERVER + '/clinicians/zoom_meeting_schedules/destroy_all_repeat/' + event_id + '',
      headers: JSON.parse(localStorage.getItem('clinician_auth'))
    })
    .then((response) => {
      const result = {delete: true, data: response.data};
      this.props.resultCallback(result);

      window.$('#new-event-zoom').modal('hide');
    })
    .catch(() => {
      window.$('#new-event-zoom').modal('hide');
    })
  }

  handleDatePicker = (selectedDates, dateStr, instance) => {
    const date = new Date(dateStr),
      day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear();

    this.setState({date: dateStr})

    this.state.start.setDate(day);
    this.state.start.setMonth(month);
    this.state.start.setFullYear(year);
  }

  handleStartPicker = (selectedDates, dateStr, instance) => {
    const time = dateStr.split(':');
    const start = this.state.start.setHours(time[0], time[1], 0, 0);
    const startDate = new Date(start);

    this.setState({start: startDate});
    console.log(this.state.start)
  };

  componentDidMount = () => {
    const eventModal = window.$('#new-event-zoom');

    eventModal.on('show.bs.modal', () => {
      this.setState({isLoading: false});

      const selectedInfo = this.props.selectedInfo;
      const extendedProps = selectedInfo.extendedProps; // its from edit appointment

      let selectedEvent = {
        id: undefined,
        date: selectedInfo.start,
        start: selectedInfo.start,
        is_repeat: false,
        zoom_link: '',
      }

      // edit will contains this info
      if (extendedProps) {
        selectedEvent.id = selectedInfo.id;
        selectedEvent.zoom_link = extendedProps.zoom_link;
        selectedEvent.is_repeat = extendedProps.is_repeat;
      }

      this.setState(selectedEvent);

      flatpickr(this.datePicker.current, {
        dateFormat: 'd/m/Y',
        onChange: this.handleDatePicker,
        defaultDate: FORMAT_DATE(this.state.date)
      });

      flatpickr(this.startTimePicker.current, {
        onChange: this.handleStartPicker,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        defaultDate: this.state.start,
        static: true
      });
    })

  }

  render () {
    const {
      id,
      isLoading,
      zoom_link,
      is_repeat
    } = this.state,
      zoomLinkInvalid = zoom_link?.length === 0,
      zoomLinkClassName = zoomLinkInvalid ? 'form-control is-invalid' : 'form-control',
      formInvalid = zoomLinkInvalid,
      iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <div className="modal fade" id="new-event-zoom" tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form className="new-event--form" onSubmit={this.handleSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label">Zoom Link</label>
                  <input
                    type="text"
                    name="zoom_link"
                    onChange={this.handleChange}
                    value={zoom_link}
                    className={zoomLinkClassName}
                    placeholder="Zoom Link" />
                </div>
                <div className="form-group">
                  <label className="form-control-label">Date</label>
                  <input
                    type="text"
                    name="date"
                    ref={this.datePicker}
                    className="form-control"
                    placeholder="" />
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-control-label">Time</label>
                      <input
                        type="text"
                        name="start"
                        ref={this.startTimePicker}
                        className="form-control"
                        placeholder="hh:mm" />
                    </div>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          onChange={this.handleChangeRepeatStatus}
                          type="checkbox"
                          name="is_repeat"
                          className="form-check-input"
                          id="isRepeatCheckbox"
                          checked={is_repeat || false}
                          />
                        <label className="form-check-label" htmlFor="isRepeatCheckbox">Repeat every 7 days</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="modal-footer-delete-container" >
                  {id && <button type="button" onClick={() => this.handleDelete(id)} className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete this meeting only</button>}
                  {id && <button type="button" onClick={() => this.handleDeleteAllRepeatedMeeting(id)} className="btn btn-sm btn-link text-danger px-2 mr-auto">Delete this meeting & all repeated mettings</button>}
                </div>
                <button type="button" className="btn btn-sm btn-link" data-dismiss="modal">Cancel</button>
                <button type="submit" disabled={formInvalid} className="btn btn-sm btn-primary btn-overprimary rounded-pill float-right">
                  {id ? "Update event" : "Create event"}&nbsp;
                  <span className="btn-inner--icon"><i className={iconClassName} /></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ClinicianZoomMeetingScheduleModal;
