import React from 'react';
import axios from 'axios';
import { API_SERVER, VALID_EMAIL } from '../../constants';

const allertMessage = (props) => {
  return (
    <div className="alert alert-danger" role="alert">
      {props}
    </div>
  )
}

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      formError: '',
      formValid: true,
      passwordHidden: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
  }

  componentDidMount = () => localStorage.removeItem('admin_auth');

  handleSubmit = event => {
    event.preventDefault();

    this.setState({isLoading: true})

    const params = {email: this.state.email, password: this.state.password};

    axios.post(API_SERVER + '/admins/auth/sign_in.json', params)
      .then(response => {
        const token = response.headers['access-token'],
          client = response.headers['client'],
          uid = response.headers['uid'],
          query = 'token=' + token + '&client=' + client + '&uid=' + uid,
          location = '/admins/auth/sign_in?' + query,
          clinician = response.data.data;

        localStorage.setItem('admin_auth', JSON.stringify({
          'access-token': token,
          'client': client,
          'uid': uid
        }));
        
        window.location = location;
      }).catch(error => {
        const response = error.response;
        let errors = '';

        if (response?.data?.errors) {
          errors = response.data.errors.join(', ');
        } else {
          errors = response.statusText;
        }

        this.setState({
          isLoading: false,
          formValid: false,
          formError: errors
        });
      });
  }

  handleChange = event => {
    event.preventDefault();

    this.setState({[event.target.name]: event.target.value, formValid: true})
  };

  togglePassword = () => this.setState({ passwordHidden: !this.state.passwordHidden });

  render () {
    const {
      email,
      password,
      formValid,
      formError
    } = this.state;

    const iconClassName = this.state.isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

    return (
      <form onSubmit={this.handleSubmit}>
        {!formValid && allertMessage(formError)}
        <div className="form-group">
          <label className="form-control-label">Email</label>
          <div className="input-group input-group-merge">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-envelope" /></span>
            </div>
            <input
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter your email"
              autoFocus="autofocus"
              type="email"
              name="email"
              value={email}
              autoComplete="off"
              required />
          </div>
        </div>
        <div className="form-group mb-4">
          <label className="form-control-label">Password</label>
          <div className="input-group input-group-merge">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-key" /></span>
            </div>
            <input
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter your Password"
              type={this.state.passwordHidden ? "password" : "text"}
              name="password"
              value={password}
              autoComplete="off"
              required />
            <div className="input-group-append">
              <span className="input-group-text">
                <a href="#" onClick={this.togglePassword}>
                  <i className="fas fa-eye eye-overprimary" />
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center justify-content-between">
            <button type="submit" className="btn btn-sm btn-primary btn-overprimary btn-icon rounded-pill">
              <span className="btn-inner--text">Sign in</span>
              <span className="btn-inner--icon"><i className={iconClassName} /></span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default LoginForm
