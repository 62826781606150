import React from 'react';
import { API_SERVER } from '../../constants';
import consumer from '../../../channels/consumer';
import axios from 'axios';

class NotificationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications : [],
      isLoading : false,
      showMess: false,
      current_clinician : this.props.current_clinician || 0
    }
    this.showNotification = this.showNotification.bind(this);
    this.showClassNotification = this.showClassNotification.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
  }

  componentDidMount() {
    consumer.subscriptions.create({
      channel: 'NotificationsChannel',
      clinician_id: this.props.current_clinician.id
    },{
      connected: () => console.log('connected Notification Channel'),
      disconnected: () => console.log('disconnected'),
      received: data => {
        this.fetchNotifications();
      },
    })
    this.fetchNotifications();
  };

  fetchNotifications = () => {
    this.setState({isLoading : true});

    axios({
      method : 'GET',
      url : API_SERVER + '/clinicians/notifications',
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
      this.setState({ notifications: res.data.notifications });
      this.setState({ special_mood_notification_message: res.data.special_mood_notification_message });
    }).catch((error) => {
      console.log("notifications fetch error", error);
    });
  }

  showNotification = () => {
    this.setState({ showMess: !this.state.showMess });
  }

  showClassNotification = (notififcation_type) => {
    if(['feedback_comment', 'message_reply'].includes(notififcation_type)) {
      return 'mb-2 small wrap-delete-icon text-notice-notification';
    } else if (notififcation_type === 'risk_journal_entry') {
      return 'mb-2 small wrap-delete-icon text-danger-notification';
    } else {
      return 'mb-2 small wrap-delete-icon'
    }
  }

  deleteNotification = (notification_id) => {
    this.setState({isLoading : true});

    axios({
      method : 'DELETE',
      url : API_SERVER + `/clinicians/notifications/${notification_id}`,
      headers : JSON.parse(localStorage.getItem('clinician_auth')),
    }).then((res) => {
    }).catch((error) => {
      console.log("notification destroy error", error);
    });
  }

  componentWillUnmount() {
    consumer.disconnect()
  };

  render () {
    const {
      notifications,
      special_mood_notification_message,
      showMess
    } = this.state;

    return (
      <React.Fragment>
        <li className="nav-item dropdown dropdown-animate notifications">
          <a className="nav-link pr-lg-0 position-relative" onClick={this.showNotification} role="button">
            <i className={`far fa-bell bell bell-style ${notifications && notifications.length > 0 ? 'has-notifications' : ''}`}></i>
          </a>
          <div className={`dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu-arrow notifications-dropdown ${showMess ? 'show': ''}`}>
            <div>
              <p className="mb-2 text-center">
                <a className="clear-all" href='#' data-toggle="modal" data-target="#modal-notification-settings">Notification Settings</a>
              </p>
              <span className="small notification-note">
                { special_mood_notification_message }
              </span>
              {
                (notifications && notifications.length > 0) &&
                notifications.map((notification, index) => {
                  return (
                    <p key={index} className={this.showClassNotification(notification.notify_type)}>
                      <a rel="nofollow" data-method="PUT" href={`/clinicians/notifications/${notification.id}/read`}>{ notification.patient_full_name }</a>
                      &nbsp;{ notification.notification_message }
                      <i className="fa-solid fa-x delete-icon" onClick={() => this.deleteNotification(notification.id)}></i>
                    </p>
                  )
                })
              }
              {
                (notifications && notifications.length > 0) && (
                  <React.Fragment>
                    <hr />
                    <p className="mb-0 text-center">
                      <a className="clear-all" rel="nofollow" data-method="PUT" href="/clinicians/notifications/read_all">Clear all notifications</a>
                    </p>
                  </React.Fragment>
                )
              }
              {
                notifications && notifications.length == 0 && (
                  <p className="mb-0">You don't have any new notifications.</p>
                )
              }
            </div>
          </div>
        </li>
      </React.Fragment>
    )
  }
}

export default NotificationComponent;
