import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_SERVER } from '../../constants';

const ClinicInfoForm = (props) => {
  const [id, setId] = useState();
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    if (props.clinicInfo) {
      const clinicInfo = props.clinicInfo;
      setId(clinicInfo.id);
      setLabel(clinicInfo.label);
      setDescription(clinicInfo.description);
    } else {
      setId('');
      setLabel('');
      setDescription('');
      setPdfFile('');
    }
  }, [props.clinicInfo]);

  useEffect(() => {
    if (id) {
      (!label || !description) ? setHasError(true) : setHasError(false);
    } else {
      (!label || !description || !pdfFile) ? setHasError(true) : setHasError(false);
    }
  }, [label, description, pdfFile]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const url = id ? `${API_SERVER}/clinicians/clinic_infos/${id}.json` : `${API_SERVER}/clinicians/clinic_infos.json`;
    const method = id ? 'PUT' : 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('clinic_info[label]', label);
    formData.append('clinic_info[description]', description);
    if (pdfFile) {
      formData.append('clinic_info[pdf_file]', pdfFile);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  const iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="form-group">
          <label className="form-control-label">Label</label>
          <input
            value={label}
            onChange={() => setLabel(event.target.value)}
            name="label"
            className={`form-control ${label ? 'is-valid' : 'is-invalid'}`}
            type="text" placeholder="Enter document label"/>
          <div className="invalid-feedback">Please provide a valid label.</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <div className="form-group">
                <label className="form-control-label">Description</label>
                <textarea
                  value={description}
                  name="description"
                  onChange={() => setDescription(event.target.value)}
                  className={`form-control ${description ? 'is-valid' : 'is-invalid'}`}
                  placeholder="Enter a brief description of the document"
                  rows="3" data-toggle="autosize"></textarea>
                <div className="invalid-feedback">Please provide a valid description.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="form-control-label">
            Upload files
          </label>
          <input
            accept="application/pdf"
            name="pdfFile"
            onChange={() => setPdfFile(event.target.files[0])}
            className={`form-control ${id || pdfFile ? '' : 'is-invalid'}`}
            type="file"/>
          <div className="invalid-feedback">Please provide a valid pdf file.</div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
        <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={hasError} >
          <span className="btn-inner--text">Save&nbsp;</span>
          <span className="btn-inner--icon"><i className={iconClassName} /></span>
        </button>
      </div>
    </form>
  )
}

export default ClinicInfoForm;
