import React, { useState, useRef, useCallback, useEffect } from 'react';
import CliniciansSearchFriends from './search-friends';
import CliniciansFriendsModal from './modal';
import { FORMAT_DATE } from '../../constants';
import LoadMore from '../../shared/load-more'

export const EmptyClinicians = <tr><td colSpan={7}><p className="text-muted">Empty workers</p></td></tr>;

export const ClinicianRow = ({currentClinician, clinician, selectedClinician}) => {
  return (
    <>
      <th>
        <div className="media align-items-center">
          <div>
            <div className="avatar-parent-child">
              <img
                alt="Clinician Avatar"
                src={clinician.avatar}
                className="avatar rounded-circle"
              />
              <span className="avatar-child avatar-badge bg-success"></span>
            </div>
          </div>
          <div className="media-body ml-4">
            <a
              onClick={() => selectedClinician(clinician)}
              date-toggle="modal"
              data-target="#modal-clinician-create"
              className="name h6 mb-0 text-sm">
                {clinician.title} {clinician.name} {clinician.surname}
            </a>
          </div>
        </div>
      </th>
      <td>{clinician.discipline_title}</td>
      <td>{clinician.email}</td>
      <td>{FORMAT_DATE(clinician.date_of_birth)}</td>
      <td>{clinician.has_access_to_medications ? 'Yes' : 'No'}</td>
      <td>{clinician.active ? 'Yes' : 'No'}</td>
      {
        currentClinician.is_admin && (
          <td className="text-right">
            <div className="actions ml-3">
              <a
                onClick={() => selectedClinician(clinician)}
                className="action-item mr-2"
                data-toggle="modal"
                data-target="#modal-clinician-create" >
                <i className="fas fa-pencil-alt"></i>
              </a>
            </div>
          </td>
        )
      }
    </>
  );
}

const CliniciansFriendsListPage = ({currentClinician, totalClinician}) => {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [clinician, setClinician] = useState({});
  const {clinicians, hasMore, loading, error} = CliniciansSearchFriends(query, pageNumber);
  const observer = useRef();
  const lastElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore])

  const handleResetSearch = () => {
    setQuery('');
    setPageNumber(1);
  }

  const handleSearch = (e) => {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  const handleSelectedClinician = (selectedClinician) => setClinician(selectedClinician);

  const handleNewClinician = () => setClinician({
    active: true,
    title: '',
    discipline: '',
    name: '',
    surname: '',
    email: '',
    date_of_birth: '',
    has_access_to_medications: true,
    is_admin: false
  });

  useEffect(() => {
    //console.log(clinicians)
  }, [clinicians])

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">
                Workers
              </h5>
            </div>
          </div>
          { currentClinician.is_admin && (
              <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
                <a
                  onClick={handleNewClinician}
                  href="#modal-clinician-create"
                  className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4"
                  data-toggle="modal" >
                  <span className="btn-inner--icon">
                    <i className="fas fa-plus"></i>
                  </span>
                </a>
              </div>
            )
          }
        </div>
      </div>
      <div className="card">
        <div className="card-header actions-toolbar border-0">
          <div className="actions-search" id="actions-search">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent">
                  <i className="fas fa-search"></i>
                </span>
              </div>
              <input
                value={query}
                onChange={handleSearch}
                type="text"
                className="form-control form-control-flush"
                placeholder="Type and hit enter ..."/>
              <div className="input-group-append">
                <a
                  href="#"
                  onClick={handleResetSearch}
                  className="input-group-text bg-transparent"
                  data-action="search-close"
                  data-target="#actions-search">
                  <i className="fas fa-times"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <h6 className="d-inline-block mb-0">Workers</h6>
            </div>
            <div className="col text-right">
              <div className="actions">
                <a
                  href="#"
                  className="action-item mr-3"
                  data-action="search-open"
                  data-target="#actions-search">
                  <i className="fas fa-search"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Discipline</th>
                <th scope="col">Email</th>
                <th scope="col">DOB</th>
                <th scope="col">Has Accessed</th>
                <th scope="col">Status</th>
                { currentClinician?.is_admin && (<th scope="col"></th>) }
              </tr>
            </thead>
            <tbody>
              {clinicians.map((cl, i) => {
                if (clinicians.length === i + 1) {
                  return <tr key={i} ref={lastElementRef}><ClinicianRow currentClinician={currentClinician} clinician={cl} selectedClinician={handleSelectedClinician} /></tr>
                } else {
                  return <tr key={i}><ClinicianRow currentClinician={currentClinician} clinician={cl} selectedClinician={handleSelectedClinician} /></tr>
                }
              })}

              {clinicians.length == 0 && EmptyClinicians}
            </tbody>
          </table>
        </div>
      </div>

      {loading && <LoadMore />}
      {error && 'Error'}
      <CliniciansFriendsModal clinician={clinician} currentClinician={currentClinician} />
    </>
  )
}

export default CliniciansFriendsListPage;
