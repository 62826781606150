import React from 'react';
import axios from 'axios';
import NotificationModal from './notification-modal';
import { API_SERVER } from "../../../constants";

class NotificationsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
          isLoading : false,
          notifications : [],
          selectedNotification : null,
          showModal : false,
          type : null,
          patient: this.props.patient
        }
    }

    setNotifications = (notifications) => {
      this.setState({
        ...this.state,
        notifications : notifications
      });
    }

    fetchNotifications = () => {
      this.setState({isLoading : true});
      let params = {};
      if (this.props.patient) {
        params['q[patient_id_eq]'] = this.props.patient.id
        params['q[patient_id_null]'] = true,
        params['q[m]'] = 'or'
      } else {
        params['q[patient_id_null]'] = true
      }

      axios({
        method : 'GET',
        url : API_SERVER + '/user_notifications/',
        params: params,
        headers : JSON.parse(localStorage.getItem('clinician_auth')),
      }).then((res) => {
        this.setState({isLoading : false});
        this.setNotifications(res.data);
      }).catch((error) => {
        console.log("user notifications fetch error", error);
      });
    }

    componentDidMount = () => {
      this.fetchNotifications();
    }

    convertStringToDateObj = (date) => {
      return new Date(date);
    }

    convertDateToString = (date) => {
      const dateObj = this.convertStringToDateObj(date);
      return `${dateObj.getDate()}/${dateObj.getMonth()+1}/${dateObj.getFullYear()}`;
    };

    convertTimeToString = (date) => {
      const dateObj = this.convertStringToDateObj(date);
        return dateObj.toLocaleString('en-US', {hour: 'numeric', minute : 'numeric', hour12: true});
    };

    onHandleSendNotifications = (data) => {
      let request = {
        user_notification : {
          message : data.notification.message,
          programs: data.notification.programs,
          scheduled_date_time: data.notification.scheduledDate,
          patient_id: this.props.patient ? this.props.patient.id : null,
          clinician_id: this.props.current_clinician.id,
          notification_type: this.props.patient ? 1 : 2,
          date_time_set : new Date(),
        }
      };
      const method = data.type === 'new' ? 'POST' : 'PUT';
      const url = data.type === 'new' ? API_SERVER + '/user_notifications/' : API_SERVER + '/user_notifications/' + data.id;
      axios({
        method : method,
        url : url,
        data : request,
        headers : JSON.parse(localStorage.getItem('clinician_auth')),
      }).then((res) => {
        window.$('#modal-patient-notification-create').modal('hide');
        this.fetchNotifications();
        console.log("response for create or update", res);
      }).catch((error) => {
        console.log("user scheduled notifications fetch error", error);
        window.$('#modal-patient-notification-create').modal('hide');
      });
      window.$('#modal-patient-notification-create').modal('hide');
    };

    onDeleteNotification = (id) => {
      this.setState({
        ...this.state,
        isLoading : true,
      });
      axios({
        method: 'DELETE',
        url: API_SERVER + '/user_notifications/' + id,
        headers: JSON.parse(localStorage.getItem('clinician_auth'))
      })
        .then((response) => {
          this.setState({
            ...this.state,
            isLoading : false
          });
          this.fetchNotifications();
        })
        .catch((error) => {
          console.log("delete response", error);
        })
    }

    openEditModal = (notification) => {
      this.setState({
        ...this.state,
        selectedNotification : notification,
        showModal : !this.state.showModal,
        type : notification ? 'edit' : 'new',
      });
    }

    render () {
        const { notifications, patient } = this.state;
        return (
            <>
                <div className="page-title">
                    <div className="row justify-content-between align-items-center">
                        <div className="col col-lg-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
                            <div className="d-inline-block">
                                <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">{ patient ? 'Notifications' : 'Group Notifications' }</h5>
                            </div>
                        </div>
                        <div className="col-auto col-lg-6 d-flex align-items-center justify-content-between justify-content-md-end">
                            <a onClick={() => this.openEditModal(null)} className="btn btn-white btn-sm rounded-pill" data-toggle="modal">
                                <span className="btn-inner--icon mr-2"><i className="fas fa-plus"></i></span>
                                New Notification
                            </a>
                            <a href="#" className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle sidenav-toggler" data-action="sidenav-pin" data-target="#sidenav-main">
                            <i className="fas fa-bars"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="d-inline-block">
                            <h6 className="h6 d-inline-block font-weight-600 ml-4 mb-0 text-underline text-white">Upcoming Notifications</h6>
                        </div>
                        <div className="card mt-2 table-min-height">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">Message</th>
                                        <th scope="col">Scheduled Date</th>
                                        <th scope="col">Scheduled Time</th>
                                        <th scope="col">Worker</th>
                                        <th scope="col">Date Set</th>
                                        <th scope="col">Time Set</th>
                                        <th scope="col">Programs</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        notifications.map((upcomingNotification, index) => {
                                          console.log(upcomingNotification)
                                          if(!upcomingNotification.date_time_sent) {
                                            return (
                                              <tr key={upcomingNotification.id}>
                                                <th scope="row"
                                                    className="text-wrap table-message alert-link">{upcomingNotification.message}</th>
                                                <td>{this.convertDateToString(upcomingNotification.scheduled_date_time)}</td>
                                                <td>{this.convertTimeToString(upcomingNotification.scheduled_date_time)}</td>
                                                <td>{upcomingNotification.clinician.name + ' ' + upcomingNotification.clinician.surname}</td>
                                                <td>{this.convertDateToString(upcomingNotification.date_time_set)}</td>
                                                <td>{this.convertTimeToString(upcomingNotification.date_time_set)}</td>
                                                <td className="text-wrap">{upcomingNotification.program_text}</td>
                                                <td style={{minWidth: 235,}}>
                                                  <div className="row justify-content-between">
                                                    <div className="col-6">
                                                      {
                                                        this.state.isLoading ?
                                                          <button type="button"
                                                                  disabled
                                                                  className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Delete
                                                          </button> :
                                                          <button type="button"
                                                                  onClick={() => this.onDeleteNotification(upcomingNotification.id)}
                                                                  className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Delete
                                                          </button>
                                                      }
                                                    </div>
                                                    <div className="col-6">
                                                      <button type="button"
                                                              onClick={() => this.openEditModal(upcomingNotification)}
                                                              className="col-12 btn btn-sm btn-outline-secondary text-dark border-dark">Edit
                                                      </button>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
    
                    <div className="col-12 mt-10">
                        <div className="d-inline-block">
                            <h6 className="h6 d-inline-block font-weight-600 ml-4 mb-0 text-underline text-dark">Previously Sent Notifications</h6>
                        </div>
                        <div className="card mt-2 table-min-height">
                            <div className="table-responsive">
                                <table className="table break-space-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Message</th>
                                            <th scope="col">Date Sent</th>
                                            <th scope="col">Time Sent</th>
                                            <th scope="col">Worker</th>
                                            <th scope="col">Date Set</th>
                                            <th scope="col">Time Set</th>
                                            <th scope="col">Programs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.notifications.map((pastNotification, index) => {
                                          if(pastNotification.date_time_sent) {
                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{pastNotification.message}</th>
                                                    <td>{this.convertDateToString(pastNotification.date_time_sent)}</td>
                                                    <td>{this.convertTimeToString(pastNotification.date_time_sent)}</td>
                                                    <td>{pastNotification.clinician.name + ' ' + pastNotification.clinician.surname}</td>
                                                    <td>{this.convertDateToString(pastNotification.date_time_set)}</td>
                                                    <td>{this.convertTimeToString(pastNotification.date_time_set)}</td>
                                                    <td className="text-wrap" >{pastNotification.program_text}</td>
                                                </tr>
                                            );
                                          }
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationModal key={this.state.showModal} sendNotification={this.onHandleSendNotifications} isInvidiual={ this.state.patient ? true : false } notification={this.state.selectedNotification} type={this.state.type}/>
            </>
        )
    }

}

export default NotificationsPage;
