import React, { useState, useEffect, useRef, useCallback } from 'react';
import ConfirmModal from '../../shared/confirm-modal';
import VideoModal from './video-modal';
import VideoRow from './video-row';

import GetListVideos from './get-list-videos';
import DeleteVideo from './delete-video';

const ListVideos = () => {
  const [videos, setVideos] = useState([]);
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [editingRecord, setEditingRecord] = useState();

  const loadData = async () => {
    const {
      hasMore,
      loading,
      error,
      ...rest
    } = await GetListVideos(query, pageNumber);
    setVideos([...videos, ...rest.videos]);
    setHasMore(hasMore);
    setLoading(loading);
    setError(error);
  };

  // Did mount
  useEffect(() => {
    loadData();
  }, []);

  // Did update
  useEffect(() => {
    loadData();
  }, [query, pageNumber]);

  // load more on scroll page
  const observer = useRef()
  const lastVideosElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  // open modal create
  const openModalCreate = () => {
    setEditingRecord('');
  }

  // open modal edit
  const openModalEdit = (supportService) => {
    setEditingRecord(supportService);
  }

  // open modal confirm delete
  const openModalConfirm = (id) => {
    setDeletingId(id);
  }

  // handle delete support service
  const handleDeleteVideo = (id) => {
    DeleteVideo(id);
    setVideos(videos.filter((item) => item.id !== id));
  }

  return (
    <>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Videos</h5>
            </div>
          </div>
          <div className="col-auto col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 order-lg-1">
          <div className="card mh-615">
            <div className="card-header actions-toolbar border-0">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-10">
                  <h6 className="d-inline-block mb-0">Videos</h6>
                </div>
                <div className="col-lg-2 text-right">
                  <div className="actions">
                    <a href="#modal-support-service-create" onClick={openModalCreate} className="action-item mr-3" data-toggle="modal">
                      <i className="fas fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center">
                <thead>
                  <tr>
                    <th scope="col">Date posted</th>
                    <th scope="col">Time posted</th>
                    <th scope="col">Video Title</th>
                    <th scope="col">Sub-heading</th>
                    <th scope="col">Posted by</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {videos.map((video, index) => {
                    if (video.length === index + 1) {
                      return (
                        <tr ref={lastVideosElementRef} key={index}>
                          <VideoRow
                            index={index + 1}
                            upload_date={video.upload_date}
                            upload_time={video.upload_time}
                            title={video.title}
                            presenter_name={video.presenter_name}
                            uploaded_by={video.uploaded_by}
                            handleEdit={() => openModalEdit(video)}
                            handleDelete={() => openModalConfirm(video.id)} />
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={index}>
                          <VideoRow
                            index={index + 1}
                            upload_date={video.upload_date}
                            upload_time={video.upload_time}
                            title={video.title}
                            presenter_name={video.presenter_name}
                            uploaded_by={video.uploaded_by}
                            handleEdit={() => openModalEdit(video)}
                            handleDelete={() => openModalConfirm(video.id)} />
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div>{loading && <LoadMore />}</div>
          <div>{error && 'Error'}</div>
        </div>
      </div>
      <ConfirmModal message={'Are you sure want to delete this item!'} onSubmit={() => handleDeleteVideo(deletingId)} />
      <VideoModal video={editingRecord} />
    </>
  );
}

export default ListVideos;
