import React from "react";
import flatpickr from "flatpickr";
import select2 from "select2";
import axios from "axios";
import {
  API_SERVER,
  VALID_EMAIL,
  ACTIVE_STATUSES,
  SUBSCRIPTION_TYPES,
} from "../../constants";

class AdminsClinicForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.datePicker = React.createRef();

    this.state = {
      isLoading: false,
      formErrors: "",
      id: "",
      active: true,
      name: "",
      contact_name: "",
      contact_surname: "",
      subscribed_at: "",
      subscription_type: "",
      subscription_level: "",
      phone: "",
      it_department_phone: "",
      finance_department_phone: "",
      legal_department_phone: "",
      email: "",
      website: "",
      address: "",
      logo: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const clinic = props.clinic || { id: "" };
    if (clinic.id !== state.id) {
      return {
        id: clinic.id,
        active: clinic.active,
        name: clinic.name || "",
        contact_name: clinic.contact_name || "",
        contact_surname: clinic.contact_surname || "",
        subscribed_at: clinic.subscribed_at,
        subscription_type: clinic.subscription_type || "",
        subscription_level: clinic.subscription_level || "",
        phone: clinic.phone || "",
        it_department_phone: clinic.it_department_phone || "",
        finance_department_phone: clinic.finance_department_phone || "",
        legal_department_phone: clinic.legal_department_phone || "",
        email: clinic.email || "",
        website: clinic.website || "",
        address: clinic.address || "",
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFileChange = (event) => this.setState({ logo: event.target.files[0] });

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true, formErrors: "" });

    const {
      id,
      active,
      name,
      contact_name,
      contact_surname,
      subscription_type,
      subscribed_at,
      phone,
      it_department_phone,
      finance_department_phone,
      legal_department_phone,
      email,
      website,
      address,
      logo,
    } = this.state;

    const url =
      id != ""
        ? API_SERVER + "/admins/clinics/" + id + ".json"
        : API_SERVER + "/admins/clinics.json";
    const method = id ? "PUT" : "POST";

    const formData = new FormData();

    let cancel;
    let headers = JSON.parse(localStorage.getItem("admin_auth"));

    formData.append("clinic[active]", active);
    formData.append("clinic[name]", name);
    formData.append("clinic[contact_name]", contact_name);
    formData.append("clinic[contact_surname]", contact_surname);
    formData.append("clinic[contact_surname]", contact_surname);
    formData.append("clinic[phone]", phone);
    formData.append("clinic[it_department_phone]", it_department_phone);
    formData.append(
      "clinic[finance_department_phone]",
      finance_department_phone
    );
    formData.append("clinic[legal_department_phone]", legal_department_phone);
    formData.append("clinic[email]", email);
    formData.append("clinic[website]", website);
    formData.append("clinic[address]", address);
    formData.append("clinic[subscription_type]", subscription_type)
    formData.append("clinic[subscribed_at]", subscribed_at);

    if (logo) {
      headers["content-type"] = "multipart/form-data";
      formData.append("clinic[logo]", logo);
    }

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then(() => window.location.reload())
      .catch(() => window.location.reload());
  };

  handleDelete = (clinicId) => {
    axios({
      method: "DELETE",
      url: API_SERVER + "/admins/clinics/" + clinicId + ".json",
      headers: JSON.parse(localStorage.getItem("admin_auth")),
    })
      .then(() => window.location.reload())
      .catch(() => window.location.reload());
  };

  componentDidMount = () => {
    flatpickr(this.datePicker.current, {
      onChange: (selectedDates, dateStr) =>
        this.setState({ subscribed_at: dateStr }),
    });
  };

  render() {
    const isLoading = this.state.isLoading,
      id = this.state.id,
      active = this.state.active,
      name = this.state.name,
      contact_name = this.state.contact_name,
      contact_surname = this.state.contact_surname,
      phone = this.state.phone,
      it_department_phone = this.state.it_department_phone,
      finance_department_phone = this.state.finance_department_phone,
      legal_department_phone = this.state.legal_department_phone,
      website = this.state.website,
      address = this.state.address,
      email = this.state.email,
      subscription_type = this.state.subscription_type,
      nameInvalid = name.length === 0,
      contactNameInvalid = contact_name.length === 0,
      contactSurnameInvalid = contact_surname.length === 0,
      phoneInvalid = phone.length === 0,
      emailInvalid = !VALID_EMAIL.test(email),
      nameClassName = nameInvalid ? "form-control is-invalid" : "form-control",
      contactNameClassName = contactNameInvalid
        ? "form-control is-invalid"
        : "form-control",
      contactSurnameClassName = contactSurnameInvalid
        ? "form-control is-invalid"
        : "form-control",
      phoneClassName = phoneInvalid
        ? "form-control is-invalid"
        : "form-control",
      emailClassName = emailInvalid
        ? "form-control is-invalid"
        : "form-control",
      formValid =
        !nameInvalid &&
        !contactNameInvalid &&
        !contactSurnameInvalid &&
        !phoneInvalid &&
        !emailInvalid,
      iconClassName = isLoading
        ? "fa fa-spinner fa-spin"
        : "fas fa-long-arrow-alt-right";

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="modal-body">
          <div className="form-group">
            <label className="form-control-label">Logo</label>
            <input
              type="file"
              name="logo"
              onChange={this.handleFileChange}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label className="form-control-label">Clinic Status</label>
            <select
              name="active"
              value={active}
              onChange={this.handleChange}
              className="form-control"
            >
              {ACTIVE_STATUSES.map((el) => {
                return <option key={el.value} value={el.value}>{el.name}</option>;
              })}
            </select>
          </div>
          <hr />

          <div className="form-group">
            <label className="form-control-label">Clinic Name</label>
            <input
              type="text"
              name="name"
              onChange={this.handleChange}
              value={this.state.name}
              className={nameClassName}
              placeholder="Enter name for clinic"
            />
            <div className="invalid-feedback">
              Please provide a valid clinic name.
            </div>
          </div>

          <div className="row align-items-end">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Contact</label>
                <input
                  type="text"
                  name="contact_name"
                  onChange={this.handleChange}
                  value={contact_name}
                  className={contactNameClassName}
                  placeholder="Name of Contact"
                />
                <div className="invalid-feedback">
                  Please provide a valid name.
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  type="text"
                  name="contact_surname"
                  onChange={this.handleChange}
                  value={contact_surname}
                  className={contactSurnameClassName}
                  placeholder="Surname of Contact"
                />
                <div className="invalid-feedback">
                  Please provide a valid surname.
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row align-items-end">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Phones</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-clinic-medical"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="phone"
                    onChange={this.handleChange}
                    value={phone}
                    className={phoneClassName}
                    placeholder="Clinic phone"
                  />
                  <div className="invalid-feedback">
                    Please provide a valid phone
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-cogs"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="it_department_phone"
                    onChange={this.handleChange}
                    value={it_department_phone}
                    className="form-control"
                    placeholder="Clinic IT Department Phone"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-money-bill-alt"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="finance_department_phone"
                    onChange={this.handleChange}
                    value={finance_department_phone}
                    className="form-control"
                    placeholder="Clinic Finance Department Phone"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-balance-scale"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="legal_department_phone"
                    onChange={this.handleChange}
                    value={legal_department_phone}
                    className="form-control"
                    placeholder="Clinic Legal Department Phone"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Email</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    className={emailClassName}
                    placeholder="name@domain.com"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Website</label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-globe-asia"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="website"
                    onChange={this.handleChange}
                    value={website}
                    className="form-control"
                    placeholder="https://www.domain.com"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-control-label">Address</label>
            <input
              type="text"
              name="address"
              onChange={this.handleChange}
              value={address}
              className="form-control"
              placeholder="Enter address of clinic"
            />
          </div>
          <hr />
          <div className="row align-items-end">
            <div className="col-6">
              <div className="form-group">
                <label className="form-control-label">Subscription</label>
                <select
                  name="subscription_type"
                  value={subscription_type}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  {SUBSCRIPTION_TYPES.map((el, i) => {
                    return <option key={i} value={el.value}>{el.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <input
                  type="text"
                  ref={this.datePicker}
                  className="form-control"
                  placeholder="Subscription Date"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="switchMedicationTabStatus"
                    defaultChecked={true}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="switchMedicationTabStatus"
                    data-active="Show Medication Tab"
                    data-inactive="Hide Medication Tab"
                  >
                    Show Medication Tab
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          {id && (
            <button
              type="button"
              onClick={() => this.handleDelete(id)}
              className="btn btn-sm btn-link text-danger px-2 mr-auto"
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="btn btn-sm btn-secondary rounded-pill"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-primary btn-overprimary rounded-pill"
            disabled={!formValid}
          >
            <span className="btn-inner--text">Save&nbsp;</span>
            <span className="btn-inner--icon">
              <i className={iconClassName} />
            </span>
          </button>
        </div>
      </form>
    );
  }
}

export default AdminsClinicForm;
