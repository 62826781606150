import React from 'react';

const AdvancedStatementModal = (props) => {
  const {patient} = props;
  console.log(patient);
  const formatDateTime = (str) => {
    return new Date(str).toLocaleTimeString('en-GB',{day: '2-digit',month: '2-digit', year: 'numeric', hour: '2-digit', minute:'2-digit'});
  }

  return (
    <div className="modal fade" id="modal-advanced-statement" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header align-items-center">
            <div className="modal-title">
              <h6 className="mb-0">Advanced Statement</h6>
              <span className="d-block text-sm">Created at {formatDateTime(patient.updated_at)}</span>
            </div>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="scrollbar-inner">
            <div className="list-group">
              <div className="list-group-item">
                <div className="d-flex" data-toggle="tooltip" data-placement="right" data-title="">
                  <div>
                    <div className="avatar-parent-child">
                      <img alt="Image placeholder" src={patient.avatar_url} className="avatar rounded-circle" />
                      <span className="avatar-child avatar-badge bg-warning"></span>
                    </div>
                  </div>
                  <div className="flex-fill ml-3">
                    <h6 className="text-sm mb-0">{patient.name}</h6>
                    <div className="text-sm">
                      <p>{patient.advanced_statement}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedStatementModal;
