import React from 'react';
import ModalForm from './form';

const NotificationModal = (props) => {
  const {sendNotification, notification, type, isInvidiual} = props;

  return (
    <div className="modal fade" id="modal-patient-notification-create" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <ModalForm sendNotification={sendNotification} isInvidiual={isInvidiual} notification={notification} type={type}/>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal;
