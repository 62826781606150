import React, { useEffect, useState } from 'react'
import Apexchart from 'react-apexcharts';
import axios from 'axios';
import { API_SERVER } from '../../../constants';

const AssessmentResultChart = (props) => {
  const { patient, assessmentTemplateId, height, onLoadedData, minScore, maxScore } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [yData, setYdata] = useState([]);
  const [xData, setXData] = useState([]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-GB');
  }

  const loadData = () => {
    setIsLoading(true);
    axios({
      method: 'GET',
      url: API_SERVER + '/clinicians/assessments/results',
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      params: { 'patient_id': patient.id, 'assessment_template_id': assessmentTemplateId, 'status': 1 }
    })
    .then(res => {
      setIsLoading(false);
      setYdata(res.data.map (item => item.score));
      setXData(res.data.map((item) => {
        return formatDate(item.done_at);
      }));
      onLoadedData && onLoadedData(res.data);
    })
    .catch((error) => console.log(error))
  }

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  },[assessmentTemplateId])

  const renderChart = () => {
    const series = [
      {
        name: 'Score',
        data: yData
      }
    ];

    const options = {
      chart: {
        type: 'line',
        sparkline: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          tools: {
              download: false
          }
        }
      },
      colors: ['#d97d6c'],
      stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        colors: '#d97d6c',
        width: 2,
        dashArray: 0,
      },
      tooltip: {
        fixed: {
          enabled: false
        },
        x: {
          show: true
        },
      },
      markers: {
        size: 4,
        colors: undefined,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3
        }
      },
      xaxis: {
        categories: xData,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontSize: '11px'
          }
        }
      },
      yaxis: {
        min: minScore,
        max: maxScore
      }
    };

    if (isLoading)
      return <span className="btn-inner--icon"><i className="fa fa-spinner fa-spin" /></span>;

    return <Apexchart options={options} height={height || 30} series={series} type="line"/>;;
  }

  return renderChart();
}

export default AssessmentResultChart;
