import React, { useEffect, useState } from 'react';
import GetListAssessments from './get-list-assessments';
import AssessmentResultChart from './assessment-result-chart';
import AssessmentRow from './assessment-row';

const AssessmentListCard = (props) => {
  const {assessmentTemplateId, assessmentTitle, patient, onRequestAgain, onShowChart, onShowDetailAssessment, minScore, maxScore} = props;
  const [query, setQuery] = useState({patient_id: patient.id, assessment_template_id: assessmentTemplateId});
  const [pageNumber, setPageNumber] = useState(1);
  const [assessments, setAssessments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    assessments.length ? setIsShow(true) : setIsShow(false);
  }, [assessments])

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [pageNumber]);

  const loadData = async () => {
    const {
      loading,
      error,
      ...rest
    } = await GetListAssessments(query, pageNumber);
    setAssessments([...assessments, ...rest.assessments]);
    setHasMore([...assessments, ...rest.assessments].length < rest.pagy.total_entries);
    setLoading(loading);
  };

  const openModalShowDetail = (e, item) => {
    e.preventDefault();
    onShowDetailAssessment(item);
    window.$('#modal-assessment-msg').modal('show');
  }

  return (
    <div className="card" style={isShow ? {maxWidth: '30em'} : {display: 'none'}}>
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="mb-0">{assessmentTitle}</h6>
          </div>
          <div className="text-right">
            <div className="actions">
              <a href="#modal-assessment-request" onClick={onRequestAgain} data-toggle="modal" className="btn btn-secondary btn-xs rounded-pill">Request again</a>
              <a href="#modal-patient-assessment-chart" onClick={onShowChart} className="btn btn-secondary btn-icon-only btn-xs rounded-circle" data-toggle="modal">
                <i className="fas fa-chart-bar"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">

        <div className="list-group list-group-flush pb-4">
            <div className="list-group-item list-group-item-action" style={{ maxWidth: '100%' }}>
              <AssessmentResultChart patient={patient} assessmentTemplateId={assessmentTemplateId} height="280" color="danger" minScore={minScore} maxScore={maxScore}/>
            </div>
          { assessments.map((item, index) => (
            <AssessmentRow key={index} onClick={(e) => openModalShowDetail(e, item)} item={item} patient={patient} />
          ))
          }
          { hasMore &&
            <div className="mt-4 text-center">
              <a href="#" onClick={() => setPageNumber(pageNumber+1)} className="btn btn-sm btn-neutral rounded-pill shadow hover-translate-y-n3">Load more ...</a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default AssessmentListCard;
