let backendHost;
const API_VERSION = "v1";
const PROTOCOL = window && window.location.protocol;
const HOST = window && window.location.host; // localhost:3000

export const API_SERVER = `${PROTOCOL}//${HOST}/api/${API_VERSION}`;

export const VALID_EMAIL = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
//export const VALID_PASSWORD = RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i);
export const VALID_PASSWORD = RegExp(/^.{6,}$/i);
export const VALID_PHONE = RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/im
);

export const ACTIVE_STATUSES = [
  { name: "Active", value: "true" },
  { name: "Inactive", value: "false" },
];

export const VIDEO_STATUSES = [
  { name: "Public", value: "public" },
  { name: "Private", value: "private" },
  { name: "Unlisted", value: "unlisted" },
];

export const GOAL_STATUSES = [
  { name: "In progress", value: 1 },
  { name: "Completed", value: 2 },
  { name: "Abandoned", value: 3 }
];

export const SUBSCRIPTION_TYPES = [
  { name: "Monthly", value: "monthly" },
  { name: "Annual", value: "annual" },
];

export const DISCIPLINE_TYPES = [
  { name: "Case Manager", value: "case_manager" },
  { name: "Clinical Psychologist", value: "clinical_psychologist" },
  { name: "Coach", value: "coach" },
  { name: "Counsellor", value: "counsellor"},
  { name: "GP", value: "gp" },
  { name: "Mental Health Clinician", value: "mental_health_clinician" },
  { name: "Occupational Therapist", value: "occupational_therapist" },
  { name: "Pain Specialist", value: "pain_specialist" },
  { name: "Paediatrician", value: "paediatrician"},
  { name: "Peer Support Worker ", value: "peer_support_worker" },
  { name: "Psychiatrist", value: "psychiatrist" },
  { name: "Psychologist", value: "psychologist" },
  { name: "Psychotherapist", value: "psychotherapist" },
  { name: "Social Worker", value: "social_worker" },
  { name: "Support Coordinator", value: "support_coordinator" },
  { name: "Support Worker", value: "support_worker"},
  { name: "Support Facilitator", value: "support_facilitator"},
  { name: "Care Coordinator", value: "care_coordinator"}
];

export const ASSESSMENT_TITLE_TEMPLATES = [
  {name: 'Flourshing Scale', value: 2, min_score: 8, max_score: 40},
  {name: 'GAD-7', value: 3, min_score: 0, max_score: 21},
  {name: 'K10', value: 4, min_score: 10, max_score: 50},
  {name: "Mindful Attention Awareness Scale", value: 5, min_score: 15, max_score: 90},
  {name: "Pain Self-Efficacy Questionnaire", value: 6, min_score: 0, max_score: 60},
  {name: "PCL-5", value: 7, min_score: 0, max_score: 80},
  {name: "PHQ-9", value: 8, min_score: 0, max_score: 27},
  {name: "Rosenberg Self-Esteem Scale", value: 9, min_score: 10, max_score: 40},
];

export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export const FORMAT_DATE = (dateStr) => {
  if (dateStr === undefined || dateStr === '' || dateStr === null) return '';

  const date = new Date(dateStr),
        localeDate = date.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

  return localeDate;
}

export const FORMAT_TIME = (dateStr) => {
  if (dateStr === undefined || dateStr === '' || dateStr === null) return '';
  const date = new Date(dateStr);
  if (isNaN(date)) return dateStr;

  const localeDate = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });

  return localeDate;
}

export const FORMAT_DATE_TO_ISO = (date) => {
  return [date.getFullYear(), (date.getMonth() + 1), date.getDate()].join('-')
}

export const INVALID_DATE = (date) => {
  return new Date(date) == 'Invalid Date' || date == '' || date == null;
}

export const PROGRAMS = [
  { name: "Alcohol Use", value: 0 },
  { name: "Substance Use", value: 1 },
  { name: "Gambling", value: 2 }
];
