import React from "react";

import AdminsClinicsListPage from "./clinics-list-page";

class AdminsClinicsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clinicCount: this.props.clinic_count,
    };
  }

  render() {
    const clinicCount = this.state.clinicCount,
      viewPage = <AdminsClinicsListPage clinicCount={clinicCount} />;

    return viewPage;
  }
}

export default AdminsClinicsPage;
