import React, { useState, useRef, useCallback } from 'react'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import ClinciansSearchPatients from './search-patients';
import ClinicianPatientCard from './patient-card';
import MorePatient from './more-patient';
import ClinicianPatientModal from './patient-modal';
import './patient-card.scss';
import {
  PROGRAMS
} from '../../constants';

const initialPatient = {
  id: undefined,
  mrn: '',
  name: '',
  surname: '',
  date_of_birth: '',
  program: 0,
  country_code: '+61',
  mobile_phone: '',
  email: '',
  password: '',
  password_confirmation: '',
  avatar: undefined
}

const initialState = {
  selectedPatient: null
}

function reducer(state = initialState, action) {
  switch(action.type) {
    case 'NEW_PATIENT':
      return {
        selectedPatient: action.selectedPatient
      }
    case 'EDIT_PATIENT':
      return {
        selectedPatient: action.selectedPatient
      }
    default:
      return state;
  }
}

const store = createStore(reducer);

const EmptyPatient = () => {
  return (
    <div className="col">
      <div className="card hover-shadow-lg" style={{height: '240px'}}>
        <div className="card-body text-center">
          <h3 className="text-muted">Empty participants</h3>
        </div>
      </div>
    </div>
  )
}

export default function CliniciansPatientsGridPage(props) {
  const [query, setQuery] = useState('');
  const [programFilter, setProgramFilter] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const {
    patients,
    hasMore,
    loading,
    error
  } = ClinciansSearchPatients(query, pageNumber, programFilter)
  const observer = useRef()
  const lastPatientElementRef = useCallback(node => {
    if (loading) return

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })

    if (node) observer.current.observe(node);
  }, [loading, hasMore])

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  function handleFilter(e) {
    setProgramFilter(e.target.value);
    setPageNumber(1);
  }

  function handleChangeProgram(e) {
    setProgramFilter(e.target.value);
    setPageNumber(1);
  }

  function handleNewPatient() {
    store.dispatch({type: 'NEW_PATIENT', selectedPatient: initialPatient});
  }

  return (
    <Provider store={store}>
      <div className="page-title">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
            <div className="d-inline-block">
              <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">Participants</h5>
            </div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
            <form className="bg-neutral rounded-pill d-inline-block form-program-filter">
              <div className="input-group input-group-sm input-group-merge input-group-flush">
                <select
                  value={programFilter}
                  className="form-control select-program-filter"
                  name="programFilter"
                  onChange={handleChangeProgram}>
                    <option key='' value=''>Filter by program</option>
                    {PROGRAMS.map((el) => {
                      return <option key={el.value} value={el.value}>{el.name}</option>;
                    })}
                </select>
              </div>
            </form>
            <form className="bg-neutral rounded-pill d-inline-block">
              <div className="input-group input-group-sm input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-transparent"><i className="fas fa-search" /></span>
                </div>
                <input type="text" value={query} onChange={handleSearch} className="form-control form-control-flush" placeholder="Search by name" />
              </div>
            </form>
            <a href="#modal-patient-create" onClick={handleNewPatient} className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4" data-toggle="modal">
              <span className="btn-inner--icon"><i className="fas fa-plus" /></span>
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        {patients.map((patient, index) => {
          if (patients.length === index + 1) {
            return (
              <div className="col-lg-3 col-sm-6" key={patient.id} ref={lastPatientElementRef}>
                <ClinicianPatientCard patient={patient} />
              </div>
            )
          } else {
            return (
              <div className="col-lg-3 col-sm-6" key={patient.id}>
                <ClinicianPatientCard patient={patient} />
              </div>
            )
          }
        })}

        {patients.length == 0 && !loading && <EmptyPatient />}
      </div>

      <div>{loading && <MorePatient />}</div>
      <div>{error && 'Error'}</div>
      <ClinicianPatientModal store={store} isAdminClinician={props.isAdminClinician} />
    </Provider>
  )
}
