import React from 'react';
import ClinicInfoForm from './form';

const ClinicInfoModal = (props) => {
  return (
    <div className="modal fade" id="modal-clinic-info-create" tabIndex={-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <ClinicInfoForm clinicInfo={props.clinicInfo} />
        </div>
      </div>
    </div>
  )
}

export default ClinicInfoModal;
