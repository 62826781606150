import React from 'react';
import {FORMAT_DATE, FORMAT_TIME} from '../../../../constants';

class CliniciansPatientsMedicationRow extends React.Component {
  handleClick = (medication) => this.props.clickedRow(medication);

  renderFrequently = (schedule) => {
    const daily = schedule.monday && schedule.tuesday &&
      schedule.wednesday && schedule.thursday &&
      schedule.friday && schedule.saturday && schedule.sunday;

    if (daily) {
      return ['Daily']
    } else {
      let days = [];
      if (schedule.monday) {
        days.push('Monday')
      }
      ;
      if (schedule.tuesday) {
        days.push('Tuesday')
      }
      ;
      if (schedule.wednesday) {
        days.push('Wednesday')
      }
      ;
      if (schedule.thursday) {
        days.push('Thursday')
      }
      ;
      if (schedule.friday) {
        days.push('Friday')
      }
      ;
      if (schedule.saturday) {
        days.push('Saturday')
      }
      ;
      if (schedule.sunday) {
        days.push('Sunday')
      }
      ;
      return days
    }
  }

  render() {
    const medications = this.props.medications,
      clickedMedication = this.props.clickedMedication;

    return (
      <React.Fragment>
        {medications.map((medication, index) => {
          const clinician = medication.clinician,
            patient = medication.patient,
            schedules = medication.schedules,
            is_personal = medication.is_personal;

          return (
            <tr key={index}>
              <th scope="row">
                <span className="name h6 mb-0 text-sm">{medication.name}</span>
              </th>
              <td>
                {medication.dosage}&nbsp;mg
                <a onClick={() => this.handleClick(medication)} href="#" className="action-item mr-2"
                   data-toggle="modal" data-target="#modal-medication">
                  <i className="fas fa-link"/>
                </a>
                <br/>
                {schedules.map((schedule, i) => {
                  return (
                    <div key={i}>
                      {this.renderFrequently(schedule).map((el, j) => {
                        return <div key={j}>{el}</div>
                      })}
                    </div>
                  )
                })}
              </td>
              <td>
                <div className="col card-meta d-inline-flex align-items-center ml-sm-auto">
                  <div className="media media-pill align-items-center d-inline-flex bg-secondary">
                    <div className="ml-2">
                      <small>
                        {is_personal ? [patient.name, patient.surname].join(' ') : [clinician.name, clinician.surname].join(' ')}
                      </small>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                {FORMAT_DATE(medication.created_at)}
              </td>
              <td>
                {schedules.map((schedule, i) => {
                  return <div key={i}>{FORMAT_TIME(schedule.time_at)}</div>
                })}
              </td>
              <td className="text-center">
                <a onClick={() => clickedMedication(medication)} href="#"
                   className="btn btn-icon-only btn-success btn-sm rounded-circle" data-toggle="modal"
                   data-target="#modal-medication-record">
                  <i className="fas fa-check-double"/>
                </a>
              </td>
              <td className="text-right">
                <div className="actions ml-3">
                  <a onClick={() => this.handleClick(medication)} href="#" className="action-item mr-2"
                     data-toggle="modal" data-target="#modal-medication">
                    <i className="fas fa-pencil-alt"/>
                  </a>
                </div>
              </td>
            </tr>
          )
        })}
      </React.Fragment>
    )
  }
}

export default CliniciansPatientsMedicationRow;
