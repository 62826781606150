import React, { useEffect, useState } from 'react';
import DatePicker from '../../../shared/date-picker';
import AssessmentTitleSelect from './assessment-title-select';
import { ASSESSMENT_TITLE_TEMPLATES, API_SERVER } from '../../../constants';
import axios from 'axios';

const RequestAssessmentModal = (props) => {
  const { patient, assessmentTemplate } = props;

  const [assessmentTemplateId, setAssessmentTemplateId] = useState(ASSESSMENT_TITLE_TEMPLATES[0].value);
  const [message, setMessage] = useState('');
  const [dueDate, setDueDate] = useState(new Date().toISOString());
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(true);

  useEffect(() => {
    if (assessmentTemplate) setAssessmentTemplateId(assessmentTemplate);
  },[props.assessmentTemplate])

  useEffect(() => {
    (!assessmentTemplateId || !dueDate) ? setHasError(true) : setHasError(false);
  }, [assessmentTemplateId, dueDate]);

  const handlePickTitle = (val) => {
    setAssessmentTemplateId(val);
  }

  const handlePickDueDate = (selectedDates, dateStr, instance) => {
    setDueDate(selectedDates[0].toISOString());
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const url = `${API_SERVER}/clinicians/assessments.json`;
    const method = 'POST';
    let cancel;
    let headers = JSON.parse(localStorage.getItem('clinician_auth'));
    headers['content-type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append('assessment[assessment_template_id]', assessmentTemplateId);
    formData.append('assessment[message]', message ? message : `Hi ${props.patient.name} please try to complete this when you have a moment. Thanks`);
    // formData.append('assessment[due_date]', dueDate);
    formData.append('assessment[patient_id]', patient.id);

    axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
      cancelToken: new axios.CancelToken(c => cancel = c)
    })
    .then(res => location.reload())
    .catch(error=> console.log(error))
  }

  const iconClassName = isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-long-arrow-alt-right';

  return (
    <div className="modal fade" id="modal-assessment-request" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label className="form-control-label">Assessment Title</label>
                <AssessmentTitleSelect onChange={handlePickTitle} defaultSelect={assessmentTemplateId}/>
                <div className="invalid-feedback">Please provide a valid title.</div>
              </div>
              {/* <div className="form-group">
                <label className="form-control-label">Due date</label>
                <DatePicker onChange={handlePickDueDate} defaultDate={dueDate}/>
                <div className="invalid-feedback">Please provide a valid message.</div>
              </div> */}
              <div className="form-group">
                <label className="form-control-label">Message</label>
                <textarea
                  onChange={() => setMessage(event.target.value)}
                  className="form-control"
                  rows="3"
                  data-toggle="autosize"
                >
                  {`Hi ${props.patient.name} please try to complete this when you have a moment. Thanks`}
                </textarea>
                <div className="invalid-feedback">Please provide a valid message.</div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-secondary rounded-pill" data-dismiss="modal">Cancel</button>
              <button type="submit" className="btn btn-sm btn-primary btn-overprimary rounded-pill" disabled={hasError}>
                <span className="btn-inner--text">Request&nbsp;</span>
                <span className="btn-inner--icon"><i className={iconClassName} /></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default RequestAssessmentModal;
