import axios from "axios";
import { API_SERVER } from '../../../constants'

export default async function (query, pageNumber) {
  let correspondences = [];
  let hasMore = false;
  let error = false;
  let loading = true;
  let cancel;

  try {
    let res = await axios({
      method: 'GET',
      url: `${API_SERVER}/clinicians/correspondences.json`,
      params: { q: query?.q, patient_id: query?.patient_id, p: pageNumber},
      headers: JSON.parse(localStorage.getItem('clinician_auth')),
      cancelToken: new axios.CancelToken(c => cancel = c)
    });

    correspondences = res.data;
    hasMore = res.data.length > 0;
    loading = false;
  } catch (e) {
    if (e.response.status === 401) window.location = '/clinicians/auth/sign_out';

    if (axios.isCancel(e)) return;
    loading = false;
    error = true;
  }

  return {correspondences, error, hasMore, loading};
}
