import React from 'react';
import './overview.scss';

class OverviewMedication extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      medications: props.medications
    }
  }

  render () {
    const {
      medications
    } = this.state;

    return (
      <div className="card card-container card-stats border-0">
        <div className="card-body alert card-notification-status mb-0">
          <div className="row title-icon-container">
            <div className="col">
              <h6 className="h6 card-notification-status-title">Medication</h6>
            </div>
            <span className="btn btn-sm btn-icon-only bg-white text-dark rounded-circle circle-around">
            <i className="fa-regular fa-pills purple fa-lg"></i>
            </span>
          </div>
          { medications && (
              medications.map((medication, index) => {
                return <p className={ index != (medications.length -1) ? 'mb-0' : ''} key={index}>{medication.name}</p>
              })
            )
          }
        </div>
      </div>
    )
  }
}

export default OverviewMedication;
